import * as L from "leaflet";
import { LIcon, LMap, LTooltip, LMarker, LTileLayer } from "@vue-leaflet/vue-leaflet";
import "leaflet.markercluster";

import "leaflet/dist/leaflet.css";
// import "leaflet-gesture-handling/dist/leaflet-gesture-handling.min.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component("LMap", LMap);
  nuxtApp.vueApp.component("LTileLayer", LTileLayer);
  nuxtApp.vueApp.component("LMarker", LMarker);
  nuxtApp.vueApp.component("LIcon", LIcon);
  nuxtApp.vueApp.component("LTooltip", LTooltip);

  return {
    provide: {
      L,
    },
  };
});
