import type { BackgroundColor } from "~/types/shared/background-color";

const getBackgroundColorClassName = (
  backgroundColor: BackgroundColor | "transparent" | undefined,
): string => {
  if (!backgroundColor) return "";

  let className = `background-${backgroundColor}`;

  if (backgroundColor === "teal") {
    className += " enforce-light-mode";
  }

  if (backgroundColor === "red") {
    className += " enforce-dark-mode";
  }

  return className;
};

export default getBackgroundColorClassName;
