export enum Problem {
  general = "general",
  network = "network",
}

// /errors/resource_not_found/
export enum ResourceNotFoundProblem {
  // This list is not complete. Add more cases from backend if needed.
  document = "document",
  customer = "customer",
  contract = "contract",
  labels = "labels",
  measuringPoint = "measuring_point",
  tariff = "tariff",
  partialContract = "partial_contract",
  meter = "meter",
}

// /errors/request_validation_failed/
export enum FailedValidationProblem {
  invalidRequestedBeginAt = "invalid_requested_begin_at",
  invalidForecastBasis = "invalid_forecast_basis",
  invalidSupplyZipCode = "invalid_supply_zip_code",
  invalidContractType = "invalid_contract_type",
  invalidAnnualConsumption = "invalid_annual_consumption",
  invalidTariff = "invalid_tariff",
  iban = "iban",
  tarrifId = "tarrifId",
  accountHolder = "accountHolder",
  hasAcceptedGtc = "hasAcceptedGtc",
  password = "password",
  emailAddress = "emailAddress",
  serialNumber = "serialNumber",
  firstName = "firstName",
}

// /errors/resource_conflict/
export enum ResourceConflictProblem {
  meterReserved = "meter_reserved",
  customerAlreadyExists = "customer_already_exists",
  contractTransfer = "contract_transfer",
  contractTermination = "contract_termination",
  emailAlreadyVerified = "email_already_verified",
  newsletterAlreadySubscribed = "newsletter_already_subscribed",
  measuringPointOccupied = "measuring_point_occupied",
  projectAlreadyExists = "project_already_exists",
}

export type HTTPProblemType =
  | Problem
  | ResourceConflictProblem
  | ResourceNotFoundProblem
  | FailedValidationProblem;
