<template>
  <div class="tariff-detail-calculations--advance-payment">
    <div>
      <div v-if="advancePaymentWithoutPriceCap > advancePayment">
        <span class="h1 text-decoration-line-through fw-normal">
          {{ formatPrice(priceOverride || advancePaymentWithoutPriceCap, true) }} €
        </span>
        <span>{{ isBusinessCalculation ? "(netto)" : "" }}</span>
        <p>Abschlag pro Monat <b>ohne</b> Preisbremse</p>
      </div>

      <span class="h1">{{ formatPrice(priceOverride || advancePayment, true) }} €</span>
      <span>{{ isBusinessCalculation ? "(netto)" : "" }}</span>
      <p>
        Abschlag pro Monat<span v-if="advancePaymentWithoutPriceCap > advancePayment">
          {{ " " }} <b>mit</b> Preisbremse
        </span>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  calculateAdvancePayment,
  formatPrice,
} from "~/utils/tariff-calculator/priceCalculation";
import type { PublicTariffWithSummarizedPriceRanges as PublicTariff } from "~/src/generated-sources/public";

const annualHighConsumption = computed(() =>
  props.tariff.type === "power"
    ? props.targetPowerHighConsumption
    : props.targetGasConsumption,
);

const props = defineProps<{
  isBusinessCalculation?: boolean;
  isPriceCapCalculated?: boolean;
  tariff: PublicTariff;
  targetGasConsumption: number;
  targetPowerHighConsumption: number;
  targetPowerLowConsumption: number | null;
  priceOverride?: number;
}>();

const advancePayment = computed(() =>
  calculateAdvancePayment(
    annualHighConsumption.value,
    props.tariff,
    !props.isPriceCapCalculated,
    !props.isBusinessCalculation,
    props.targetPowerLowConsumption || undefined,
  ),
);

const advancePaymentWithoutPriceCap = computed(() =>
  calculateAdvancePayment(
    annualHighConsumption.value,
    props.tariff,
    true,
    !props.isBusinessCalculation,
    props.targetPowerLowConsumption || undefined,
  ),
);
</script>

<style lang="scss">
.tariff-detail-calculations--advance-payment {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}
</style>
