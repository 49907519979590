import type { ButtonLikeProps } from "~/types/shared/button-like";

export default function getButtonStyles({
  appearance = "inline",
  color = undefined,
  chevron = undefined,
  position = "inline",
  fontWeight = undefined,
  disabled = false,
}: ButtonLikeProps) {
  return [
    "btn",
    `btn-${position}`,
    {
      [`btn-chevron-${chevron}`]: Boolean(chevron),
      [`btn-font-${fontWeight}`]: Boolean(fontWeight),
      [`${appearance}-${color}`]: Boolean(appearance) && Boolean(color),
      [`disabled`]: disabled,
    },
  ];
}
