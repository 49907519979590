<template>
  <div class="d-flex justify-content-end">
    <aside class="promo-bubble">
      <span class="promo-bubble__value">20 €</span>
      <span class="promo-bubble__text">Gutschrift für jeden weiteren Vertrag.</span>
    </aside>
  </div>
</template>

<style lang="scss">
.promo-bubble {
  z-index: 10;
  margin-right: -1.5rem;
  margin-top: -3.3rem;
  padding: 0 var(--gap-l);
  display: inline-flex;
  align-items: center;
  background-color: var(--background-teal--contrast);
  color: var(--background-teal);
  border-radius: 50px 0 0 50px;
  max-width: 265px;
  gap: var(--gap-s);

  @media (max-width: 767px) {
    margin-top: 0;
  }

  &__value {
    font-size: var(--h2);
    font-weight: var(--font-weight-bold);
    white-space: nowrap;
  }

  &__text {
    line-height: 1.3;
  }
}
</style>
