import type { Calculator, CalculatorTariffType } from "~/types/shared/calculator";

/**
 * Our most generic config for our classic power tariffs.
 */
export const classicPowerTariffs: CalculatorTariffType[] = [
  {
    tariffType: "power",
    tariffSubType: "classicFlex",
    runTimeType: "standard",
    description: "",
    uniqueSellingPoints: [],
    tariffSwitch: {
      showAsCheckbox: true,
      tariff: {
        tariffType: "power",
        tariffSubType: "classicFlexPlus",
        runTimeType: "standard",
        description: "",
        uniqueSellingPoints: [],
      },
    },
  },
  {
    tariffType: "power",
    tariffSubType: "classicFlexPlus",
    runTimeType: "standard",
    description: "",
    uniqueSellingPoints: [],
  },
  {
    tariffType: "power",
    tariffSubType: "classic",
    runTimeType: "comfort",
    description: "",
    uniqueSellingPoints: [],
  },
  {
    tariffType: "power",
    tariffSubType: "classic",
    runTimeType: "standard",
    description: "",
    uniqueSellingPoints: [],
  },
];

/**
 * Our most generic config for our classic gas tariffs.
 */
export const classicGasTariffs: CalculatorTariffType[] = [
  {
    tariffType: "gas",
    tariffSubType: "classicFlex",
    runTimeType: "standard",
    description: "",
    uniqueSellingPoints: [],
  },
  {
    tariffType: "gas",
    tariffSubType: "classic",
    runTimeType: "comfort",
    description: "",
    uniqueSellingPoints: [],
  },
  {
    tariffType: "gas",
    tariffSubType: "classic",
    runTimeType: "standard",
    description: "",
    uniqueSellingPoints: [],
    tariffSwitch: {
      showAsCheckbox: false,
      tariff: {
        tariffType: "gas",
        tariffSubType: "vegan",
        runTimeType: "standard",
        description: "",
        uniqueSellingPoints: [],
      },
    },
  },
  {
    tariffType: "gas",
    tariffSubType: "vegan",
    runTimeType: "standard",
    description: "",
    uniqueSellingPoints: [],
  },
  {
    tariffType: "gas",
    tariffSubType: "geg",
    runTimeType: "standard",
    description: "",
    uniqueSellingPoints: [],
  },
];

/**
 * The default calculator config that is valid for the base
 * tariff calculators that we use in admin tool and
 * customer area.
 */
export const defaultCalculatorConfig: Calculator = {
  businessCustomerPreSelected: false,
  canSwitchBusinessCustomer: true,
  tabs: [
    {
      title: "Klassiker",
      icon: "classic",
      tariffs: [...classicPowerTariffs, ...classicGasTariffs],
    },
    {
      title: "Heizstrom",
      icon: "heating",
      tariffs: [
        {
          tariffType: "power",
          tariffSubType: "heatPump",
          runTimeType: "standard",
          description: "",
          uniqueSellingPoints: [],
        },
        {
          tariffType: "power",
          tariffSubType: "nightStorageHeating",
          runTimeType: "standard",
          description: "",
          uniqueSellingPoints: [],
        },
        {
          tariffType: "power",
          tariffSubType: "double",
          runTimeType: "standard",
          description: "",
          uniqueSellingPoints: [],
        },
      ],
    },
    {
      title: "Autostrom",
      icon: "emobility",
      tariffs: [
        {
          tariffType: "power",
          tariffSubType: "carPower",
          runTimeType: "standard",
          description: "",
          uniqueSellingPoints: [],
        },
        {
          tariffType: "power",
          tariffSubType: "carPowerPlus",
          runTimeType: "standard",
          description: "",
          uniqueSellingPoints: [],
        },
      ],
    },
    // TODO: Turn into "Spezialtarife" tab if we get more options
    {
      title: "Mieterstrom",
      icon: "house-m",
      isAdminOnly: true,
      tariffs: [
        {
          tariffType: "power",
          tariffSubType: "tenantPower",
          runTimeType: "standard",
          description: "",
          uniqueSellingPoints: [],
        },
        {
          tariffType: "power",
          tariffSubType: "tenantPowerShadow",
          runTimeType: "standard",
          description: "",
          uniqueSellingPoints: [],
        },
      ],
    },
    {
      title: "Eigenstrom",
      icon: "ownpower",
      isAdminOnly: true,
      tariffs: [
        {
          tariffType: "power",
          tariffSubType: "own",
          runTimeType: "standard",
          description: "",
          uniqueSellingPoints: [],
        },
      ],
    },
  ],
};
