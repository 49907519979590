import { isFlexPlusPowerTariff } from "./customer-area/getTariffForContract";
import type { PublicTariffWithSummarizedPriceRanges as PublicTariff } from "~/src/generated-sources/public/api";
import type { CalculatorTariffType } from "~/types/shared/calculator";
import { mapTariffToTariffProduct } from "~/utils/getTariffProduct";

/** Map<InitialTariff, TariffToSwitchTo> */
export type TariffSwitchesMap = Map<CalculatorTariffType, CalculatorTariffType>;

/** A switch config for a tariff that the user can toggle to in the UI, if present. */
export interface TariffSwitchConfig {
  /** The tariff that the user can toggle the UI to. */
  tariff: PublicTariff;
  /** Whether the toggle shall be shown as a checkbox. */
  showAsCheckbox: boolean;
}

/** Represents a map with tariffs that allow to switch the tariff to another tariff.
 *
 * @param selectableTariffs A list of tariffs the user can choose from
 * @example Ökogas Klassik -> Ökogas Vegan
 */
export const getTariffSwitchesMap = (
  selectableTariffs: CalculatorTariffType[],
): TariffSwitchesMap => {
  const tariffSwitches = selectableTariffs.filter((t) => t.tariffSwitch?.tariff);
  return new Map(tariffSwitches.map((t) => [t, t.tariffSwitch!.tariff]));
};

/**
 * Excludes tariffs that can be switched to so they don't take up an extra TariffListItem space.
 * Also excludes some tariffs in special cases like:
 * - If customer has a flex plus tariff, he/she cannot switch to flex tariff.
 */
export const getDisplayTariffsForTariffSwitches = (
  tariffs: PublicTariff[],
  tariffSwitches: TariffSwitchesMap,
  currentTariff?: PublicTariff,
  isAdmin = false,
) => {
  const MAX_TARIFFS_TO_DISPLAY_FOR_NON_ADMINS = 3;

  const excludeTariffSwitchesFromDisplayTariffsFilter = (tariff: PublicTariff) => {
    if (currentTariff) {
      // This ensures that the current tariff is never excluded from the display tariffs.
      if (tariff === currentTariff) return true;

      if (tariffIsTariffSwitch(currentTariff, tariffSwitches)) {
        const tariffSwitch = getTariffSwitch(
          currentTariff,
          tariffs,
          tariffSwitches,
        )?.tariff;
        /** This ensures that only one instance of a tariff switch is displayed.
         *  @example Do not show Ökogas Klassik -> Ökogas Vegan if we already display Ökogas Vegan -> Klassik.
         * */
        if (tariffSwitch && tariffSwitch === tariff) return false;
      }
    }
    return !tariffIsTariffSwitch(tariff, tariffSwitches);
  };

  let displayTariffs = tariffs.filter(excludeTariffSwitchesFromDisplayTariffsFilter);

  // TODO: Redesign the logic for excluding tariffs once we have the meter type in the contract object.
  // If the customer has a flex plus power tariff, he/she cannot switch to a flex power tariff.
  if (currentTariff && isFlexPlusPowerTariff(currentTariff)) {
    displayTariffs = displayTariffs.filter(
      (t) => !(t.type === "power" && t.subType === "classicFlex"),
    );
  }

  if (!isAdmin && displayTariffs.length > MAX_TARIFFS_TO_DISPLAY_FOR_NON_ADMINS) {
    displayTariffs = displayTariffs.slice(0, MAX_TARIFFS_TO_DISPLAY_FOR_NON_ADMINS);
  }
  return displayTariffs;
};

export const isDisplayTariffSelected = (
  selectedTariff: PublicTariff | undefined,
  displayTariff: PublicTariff,
  tariffs: PublicTariff[],
  tariffSwitches: TariffSwitchesMap,
) => {
  if (!selectedTariff) return false;

  const tariffIsSelected = selectedTariff.id === displayTariff.id;

  const tariffSwitch = getTariffSwitch(displayTariff, tariffs, tariffSwitches);
  const tariffSwitchIsSelected = selectedTariff.id === tariffSwitch?.tariff.id;

  return tariffIsSelected || tariffSwitchIsSelected;
};

/** Wether or not a tariff is part of a tariff switch
 * and is therefore not a standalone tariff. */
const tariffIsTariffSwitch = (
  tariff: PublicTariff,
  tariffSwitches: TariffSwitchesMap,
) => {
  const tariffSwitchesValues = Array.from(tariffSwitches.values());
  return tariffSwitchesValues.find((t) => mapTariffToTariffProduct(tariff, t));
};

/**
 * Get the tariff switch for a tariff if exists.
 * Returns tariff switches just in the configured direction, not the other way around.
 *
 *  @param tariff The tariff to find the tariff switch for.
 *  @param tariffs The tariffs list to find the tariff switch in.
 *  @returns The tariff of the tariff switch or undefined if it does not exist.
 *  */
export const getTariffSwitch = (
  tariff: PublicTariff,
  tariffs: PublicTariff[],
  tariffSwitches: TariffSwitchesMap,
): TariffSwitchConfig | undefined => {
  const tariffSwitchesKeys = Array.from(tariffSwitches.keys());
  const tariffSwitch = tariffSwitchesKeys.find((t) =>
    mapTariffToTariffProduct(tariff, t),
  );

  const tariffToSwitchTo = tariffSwitch?.tariffSwitch?.tariff;

  if (tariffToSwitchTo) {
    const alternativeTariff = tariffs.find((t) =>
      mapTariffToTariffProduct(t, tariffToSwitchTo),
    );
    if (alternativeTariff) {
      return {
        tariff: alternativeTariff,
        showAsCheckbox: tariffSwitch?.tariffSwitch?.showAsCheckbox ?? false,
      };
    }
  }
};
