import config from "~/config";
import { type EntityType, type PublicTariff } from "~/src/generated-sources/public";
import type {
  BookingOrderReason,
  BookingType,
  CompletedContract,
  NewContract,
  ProductGroupValue,
  TrackConsumption,
} from "~/types/shared/calculator";
import { calculateAdvancePayment } from "~/utils/tariff-calculator/priceCalculation";
import hashString from "~/utils/tracking/hashString";
import sanitizeTariffId from "~/utils/tracking/sanitizeTariffId";
import { useTrackCheckoutEvent } from "~/composables/useEmitGtmEvent";

// We incrementally extend the tracking information based on the progress in the checkout process
interface TrackingInfoBase {
  bookingType: BookingType;
  consumption?: TrackConsumption;
  coupon?: string;
  customerType: EntityType;
  postalCode: string;
  previousProvider?: string;
  productGroup?: ProductGroupValue;
  tabTitle?: string;
  userLoggedIn: boolean;
}

interface TrackingInfoViewItem extends TrackingInfoBase {
  tariffs: PublicTariff[];
}

interface TrackingInfoTariffSelection extends TrackingInfoBase {
  newContracts: NewContract[];
}

interface TrackingInfoDataEntry extends TrackingInfoTariffSelection {
  paymentType: "Selbstzahler" | "SEPA";
  orderReason: BookingOrderReason;
}

interface TrackingInfoPurchase extends Omit<TrackingInfoDataEntry, "newContracts"> {
  customerId: string;
  newContracts: CompletedContract[];
}

const calculateItemBookingValue = (contract: NewContract, customerType: EntityType) => {
  return calculateAdvancePayment(
    Number(contract.annualConsumptionValueHigh),
    contract.tariff,
    true,
    customerType === "private",
    Number(contract.annualConsumptionValueLow),
  );
};

const calculateTotalBookingValue = (
  newContracts: NewContract[],
  customerType: EntityType,
) => {
  return newContracts
    .map((contract) => calculateItemBookingValue(contract, customerType))
    .reduce((a, b) => a + b, 0);
};

const anonymizedCode = (trackingCode: string | undefined) => {
  if (!trackingCode) {
    return "none";
  }
  // If the code contains a space we can be sure that it is not a valid tracking code, but the customer entered a referal person's name
  // that will be manually resolved by the customer service team upon successful booking yet must not be tracked with Google Analytics
  if (trackingCode.includes(" ")) {
    return "anonymized";
  }
  return trackingCode;
};

export const useTrackViewItem = ({
  bookingType,
  customerType,
  consumption,
  coupon,
  postalCode,
  productGroup,
  tabTitle,
  tariffs,
  userLoggedIn,
}: TrackingInfoViewItem) => {
  useTrackCheckoutEvent({
    event: "view_item",
    ecommerce: {
      consumption_kwh: consumption,
      coupon: anonymizedCode(coupon),
      currency: "EUR",
      customer_type: customerType,
      login_status: userLoggedIn ? "logged_in" : "logged_out",
      // the first or "browse" step, so no value to be tracked
      value: 0,
      items: tariffs.map((tariff, index) => ({
        index,
        item_id: sanitizeTariffId(tariff.id),
        item_name: tariff.name,
        item_brand: "polarstern",
        item_category: tabTitle,
        item_category2: tariff.type,
        item_category3: productGroup,
        item_category4: bookingType,
        item_variant: `${tariff.type}+${tariff.subType}+${tariff.runtimeType}`,
        location_id: postalCode,
        quantity: 1,
      })),
    },
  });
};

export const useTrackTariffSelect = ({
  bookingType,
  consumption,
  coupon,
  customerType,
  newContracts,
  postalCode,
  productGroup,
  tabTitle,
  userLoggedIn,
}: TrackingInfoTariffSelection) => {
  useTrackCheckoutEvent({
    event: "select_item",
    ecommerce: {
      consumption_kwh: consumption,
      coupon: anonymizedCode(coupon),
      currency: "EUR",
      customer_type: customerType,
      login_status: userLoggedIn ? "logged_in" : "logged_out",
      value: calculateTotalBookingValue(newContracts, customerType),
      items: newContracts.map((contract, index) => ({
        index,
        discount: coupon && config.recommendationValue,
        coupon: anonymizedCode(coupon),
        item_id: sanitizeTariffId(contract.tariff.id),
        item_name: contract.tariff.name,
        item_brand: "polarstern",
        item_category: tabTitle,
        item_category2: contract.tariff.type,
        item_category3: productGroup,
        item_category4: bookingType,
        item_variant: `${contract.tariff.type}+${contract.tariff.subType}+${contract.tariff.runtimeType}`,
        location_id: postalCode,
        price: calculateItemBookingValue(contract, customerType),
        quantity: 1,
      })),
    },
  });
};

export const useTrackChooseAndAdvance = ({
  bookingType,
  customerType,
  consumption,
  coupon,
  newContracts,
  postalCode,
  productGroup,
  tabTitle,
  userLoggedIn,
}: TrackingInfoTariffSelection) => {
  useTrackCheckoutEvent({
    event: "add_to_cart",
    ecommerce: {
      consumption_kwh: consumption,
      coupon: anonymizedCode(coupon),
      currency: "EUR",
      customer_type: customerType,
      login_status: userLoggedIn ? "logged_in" : "logged_out",
      value: calculateTotalBookingValue(newContracts, customerType),
      items: newContracts.map((contract, index) => ({
        index,
        item_id: sanitizeTariffId(contract.tariff.id),
        item_name: contract.tariff.name,
        item_brand: "polarstern",
        item_category: tabTitle,
        item_category2: contract.tariff.type,
        item_category3: productGroup,
        item_category4: bookingType,
        item_variant: `${contract.tariff.type}+${contract.tariff.subType}+${contract.tariff.runtimeType}`,
        location_id: postalCode,
        price: calculateItemBookingValue(contract, customerType),
        quantity: 1,
      })),
    },
  });
};

export const useTrackAddPaymentInfo = ({
  bookingType,
  orderReason,
  customerType,
  consumption,
  coupon,
  newContracts,
  paymentType,
  postalCode,
  previousProvider,
  productGroup,
  tabTitle,
  userLoggedIn,
}: TrackingInfoDataEntry) => {
  useTrackCheckoutEvent({
    event: "add_payment_info",
    ecommerce: {
      consumption_kwh: consumption,
      customer_type: customerType,
      currency: "EUR",
      login_status: userLoggedIn ? "logged_in" : "logged_out",
      payment_type: paymentType,
      previous_provider: previousProvider,
      value: calculateTotalBookingValue(newContracts, customerType),
      items: newContracts.map((contract, index) => ({
        index,
        discount: coupon && config.recommendationValue,
        coupon: anonymizedCode(coupon),
        item_id: sanitizeTariffId(contract.tariff.id),
        item_name: contract.tariff.name,
        item_brand: "polarstern",
        item_category: tabTitle,
        item_category2: contract.tariff.type,
        item_category3: productGroup,
        item_category4: bookingType,
        item_category5: orderReason,
        item_variant: `${contract.tariff.type}+${contract.tariff.subType}+${contract.tariff.runtimeType}`,
        location_id: postalCode,
        price: calculateItemBookingValue(contract, customerType),
        quantity: 1,
      })),
    },
  });
};

export const useTrackPurchase = ({
  bookingType,
  orderReason,
  customerId,
  customerType,
  consumption,
  coupon,
  newContracts,
  paymentType,
  postalCode,
  previousProvider,
  productGroup,
  tabTitle,
  userLoggedIn,
}: TrackingInfoPurchase) => {
  useTrackCheckoutEvent({
    event: "purchase",
    ecommerce: {
      consumption_kwh: consumption,
      currency: "EUR",
      customer_id: hashString(customerId),
      customer_type: customerType,
      login_status: userLoggedIn ? "logged_in" : "logged_out",
      payment_type: paymentType,
      previous_provider: previousProvider,
      transaction_id: "T_" + Date.now(),
      value: calculateTotalBookingValue(newContracts, customerType),
      items: newContracts.map((contract, index) => ({
        index,
        discount: coupon && config.recommendationValue,
        coupon: anonymizedCode(coupon),
        item_id: sanitizeTariffId(contract.tariff.id),
        item_contract_id: hashString(contract.id),
        item_name: contract.tariff.name,
        item_brand: "polarstern",
        item_category: tabTitle,
        item_category2: contract.tariff.type,
        item_category3: productGroup,
        item_category4: bookingType,
        item_category5: orderReason,
        item_variant: `${contract.type}+${contract.tariff.subType}+${contract.tariff.runtimeType}`,
        location_id: postalCode,
        price: calculateItemBookingValue(contract, customerType),
        quantity: 1,
      })),
    },
  });
};
