// Links on error pages are broken, so use this (slightly modified) workaround
// More info: https://github.com/nuxt/nuxt/issues/22683#issuecomment-1782717008
export default defineNuxtRouteMiddleware((to, from) => {
  const error = useError();

  if (
    process.client &&
    error.value &&
    to &&
    from &&
    to.path !== from.path &&
    document.querySelector("#errorPage")
  ) {
    window.location.href = to.fullPath;
  }
});
