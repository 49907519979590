<!--
  A component that allows the user to enter a power consumption in kWh or in a different unit.
  The both inputs are bidirectional connected, so that the user can enter the value in both ways.
  The factor is used to convert the value from the other input to kWh.
  The component emits an event when the power consumption is updated.
 -->

<template>
  <div class="pb-2 mt-3 mb-3">
    <div
      class="consumption--inputs-wrapper"
      :class="[{ vertical: showVertically }]"
      :aria-label="labelAria"
    >
      <div class="consumption--first-input">
        <input
          :id="otherOption.id"
          type="number"
          step="1"
          min="1"
          class="number-input-without-buttons"
          :value="otherValue"
          @input="
            (event) => {
              if ((event?.target as any).value) {
                setOtherValue((event.target as any).value);
              }
            }
          "
        />
        <label :for="otherOption.id">{{ otherOption.label }}</label>
      </div>
      <div class="col-sm-1 pt-2 d-flex justify-content-center">
        <p>oder</p>
      </div>
      <div class="consumption--second-input">
        <input
          :id="optionInKWh.id"
          type="number"
          step="1"
          min="1"
          class="number-input-without-buttons"
          :value="targetConsumption"
          @input="
            (event) => {
              if ((event?.target as any).value) {
                setConsumption((event.target as any).value);
              }
            }
          "
        />
        <label :for="optionInKWh.id">{{ optionInKWh.label }}</label>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  (eventName: "updatePowerConsumption", powerConsumption: number): void;
}>();

const props = defineProps<{
  // Vue props are not allowed to start with "aria"...
  labelAria: string;
  optionInKWh: {
    label: string;
    id: string;
  };
  otherOption: {
    label: string;
    id: string;
  };
  initialPowerConsumption: number;
  showVertically?: boolean;
  factor: number;
}>();

const targetConsumption = ref(props.initialPowerConsumption);
const otherValue = ref(Math.round(props.initialPowerConsumption / props.factor));

const setConsumption = (value: string) => {
  otherValue.value = Math.round(parseInt(value) / props.factor);
  targetConsumption.value = parseInt(value);
  emit("updatePowerConsumption", targetConsumption.value);
};

const setOtherValue = (value: string) => {
  targetConsumption.value = Math.round(parseInt(value) * props.factor);
  otherValue.value = parseInt(value);
  emit("updatePowerConsumption", targetConsumption.value);
};
</script>

<style lang="scss" scoped>
.consumption--inputs-wrapper {
  display: flex;
  gap: 0.5rem;

  &.vertical {
    flex-direction: column-reverse;
  }
}

@container (max-width: #{$screen-xs}) {
  .consumption--inputs-wrapper {
    flex-direction: column;
    gap: 0;
  }
}
</style>
