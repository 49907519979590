<template>
  <BlockTemplate :info-text="text" :link-subject="linkSubject" :link-body="linkBody" />
</template>

<script lang="ts" setup>
import BlockTemplate from "./BlockTemplate.vue";

const text =
  "Durch ein IT-Update ist eine Newsletteranmeldung gerade nicht möglich. Schau später einfach vorbei oder schreib uns ne kurze Mail, wenn wir dich erinnern sollen.";

const linkSubject = "Update Newsletteranmeldung";
const linkBody =
  "Liebes Polarstern-Team,\nbitte informiert mich, wenn ich mich wieder bei eurem Newsletter anmelden kann.\nVielen lieben Dank";
</script>
