import type {
  PublicTariffWithSummarizedPriceRanges as PublicTariff,
  TariffPriceRange,
  TariffType,
} from "~/src/generated-sources/public";

import type { PublicTariffWithSummarizedPriceRanges as AdminTariff } from "~/src/generated-sources/admin";

/**
 * The capped price per unit for power tariffs without taxes.
 * Preisbremse Strom: 40 Cent
 */
export const CAPPED_POWER_PRICE_PER_UNIT_NETTO = 0.4 / 1.19;
/**
 * The capped price per unit for gas tariffs without taxes.
 * Preisbremse Gas: 20 Cent
 * Hint: Currently only 7 percent tax!
 */
export const CAPPED_GAS_PRICE_PER_UNIT_NETTO = 0.12 / 1.07;

/**
 * The amount of consumption that is capped at the capped price.
 * Preisbremse: bis 80% der Verbrauchsmenge
 */
export const PRICE_CAPPED_CONSUMPTION_AMOUNT = 0.8;

/**
 * Small helper that rounds a number to two decimal places.
 * @param value The value to round.
 * @returns The rounded value.
 */
export const roundByTwo = (value: number): number => {
  return Math.round(value * 100) / 100;
};

/**
 * Small helper that rounds a number to two decimal places.
 * @param value The value to round.
 * @returns The rounded value.
 */
const roundByZero = (value: number): number => {
  return Math.round(value);
};

export const formatPrice = (
  value: number,
  hasDecimals = true,
  inCents = false,
): string => {
  const factor = inCents ? 100 : 1;
  if (hasDecimals) {
    return roundByTwo(value * factor).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
    });
  }
  return roundByZero(value * factor).toLocaleString("de-DE");
};

/**
 * Returns the price range for a given consumption.
 *
 * @param ranges The price ranges of the tariff.
 * @param annualConsumption The consumption in kWh.
 * @returns The price range for the given consumption.
 */
export const getPriceRange = (
  ranges: TariffPriceRange[] | undefined,
  annualConsumption: number,
): TariffPriceRange | undefined => {
  if (!ranges) return undefined;
  return ranges.find(
    (range) =>
      annualConsumption >= range.from.value && annualConsumption <= range.to.value,
  );
};

/**
 * Helper that calculates the price for a tariff that hits the price cap.
 *
 * @param pricePerUnit The price per unit in
 * @param type The tariff type ("power" or "gas").
 * @param annualConsumption The annual consumption in kWh.
 * @returns The capped price for the given tariff and annual consumption.
 */
const calculateCappedPrice = (
  pricePerUnit: number,
  type: TariffType,
  annualConsumption: number,
) => {
  const cappedPricePerUnit =
    type === "gas" ? CAPPED_GAS_PRICE_PER_UNIT_NETTO : CAPPED_POWER_PRICE_PER_UNIT_NETTO;

  const reducedConsumption = annualConsumption * PRICE_CAPPED_CONSUMPTION_AMOUNT;
  const nonReducedConsumption = annualConsumption * (1 - PRICE_CAPPED_CONSUMPTION_AMOUNT);

  const reducedPrice = reducedConsumption * cappedPricePerUnit;
  const nonReducedPrice = nonReducedConsumption * pricePerUnit;

  return roundByTwo(reducedPrice + nonReducedPrice);
};

/**
 * Calculates the advance payment per month for a given tariff and annual consumption.
 * If the tariff hits the price cap, the price will be capped at the capped price.
 * Optionally, the price cap can be "disabled" by setting ignorePriceCap to true.
 *
 * @param annualConsumption The annual consumption in kWh.
 * @param tariff The tariff to calculate the advance payment for.
 * @param ignorePriceCap Whether to ignore the price cap.
 * @param isPrivateCustomer Whether the customer is a private customer.
 * @param annualLowTariffConsumption The annual consumption for the low tariff (optional).
 * @returns The advance payment per month for the given tariff and annual consumption.
 */
export const calculateAdvancePayment = (
  annualConsumption: number,
  tariff: PublicTariff | AdminTariff,
  ignorePriceCap = false,
  isPrivateCustomer = true,
  annualLowTariffConsumption?: number,
): number => {
  const range = getPriceRange(tariff.priceRanges, annualConsumption);

  if (!range) {
    return 0;
    // throw new Error("Could not find price range");
  }

  const pricePerUnit = parseFloat(range.pricePerUnit.value);
  const cappedPricePerUnit =
    tariff.type === "gas"
      ? CAPPED_GAS_PRICE_PER_UNIT_NETTO
      : CAPPED_POWER_PRICE_PER_UNIT_NETTO;

  const isPowerPriceCapLimitHit =
    tariff.type === "power" ? annualConsumption > 30000 : false;
  const isGasPriceCapLimitHit =
    tariff.type === "gas" ? annualConsumption > 1500000 : false;

  const isPriceCapActive =
    // If the tariff price is below the price cap, we don't need to calculate the capped price.
    pricePerUnit > cappedPricePerUnit &&
    // We don't want to show the price cap for business customers for now.
    isPrivateCustomer &&
    // Don't show the price cap if the power price cap limit (30.000 kWh) is hit.
    !isPowerPriceCapLimitHit &&
    // Don't show the price cap if the gas price cap limit (1.500.000 kWh) is hit.
    !isGasPriceCapLimitHit &&
    // Don't show the price cap if a HTNT tariff is selected.
    !annualLowTariffConsumption &&
    // Overrule all of the above.
    !ignorePriceCap;

  let consumptionPrice = isPriceCapActive
    ? calculateCappedPrice(pricePerUnit, tariff.type, annualConsumption)
    : pricePerUnit * annualConsumption;

  // Add low tariff consumption if available
  if (annualLowTariffConsumption && tariff.lowTariffPriceRanges) {
    const lowTariffRange = getPriceRange(
      tariff.lowTariffPriceRanges,
      annualLowTariffConsumption,
    );

    if (lowTariffRange) {
      const pricePerUnit = parseFloat(lowTariffRange.pricePerUnit.value);

      consumptionPrice = consumptionPrice + pricePerUnit * annualLowTariffConsumption;
    }
  }

  const basePriceBrutto = parseFloat(range.basePrice.value);
  const pricePerYear = basePriceBrutto + consumptionPrice;

  const taxFactor = isPrivateCustomer ? (tariff.valueAddedTax || 0) + 1 : 1;

  return roundByTwo((pricePerYear / 12) * taxFactor);
};
