import type { FormKitNode } from "@formkit/core";

export default () => {
  const preventSpecialCharactersRule = (node: FormKitNode): boolean => {
    const value = node.value as string;
    return /^[^\s&;,+]*$/.test(value);
  };

  const preventSpecialCharactersRuleMessage =
    "Die Zählernummer darf keine Leerzeichen und keine Sonderzeichen wie &,+; enthalten.";

  return { preventSpecialCharactersRule, preventSpecialCharactersRuleMessage };
};
