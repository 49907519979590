import { TariffRuntimeTypeEnum, TariffSubTypeEnum } from "~/src/generated-sources/admin";
import {
  TariffType,
  type PublicTariffWithSummarizedPriceRanges,
} from "~/src/generated-sources/public";

export default (tariff: PublicTariffWithSummarizedPriceRanges): number => {
  const isClassicFlexGas =
    tariff.type === TariffType.Gas && tariff.subType === TariffSubTypeEnum.ClassicFlex;

  const hasMinimalContractTerm = tariff.runtimeType === TariffRuntimeTypeEnum.Comfort;

  if (isClassicFlexGas || !hasMinimalContractTerm) {
    return 1;
  } else {
    return 12;
  }
};
