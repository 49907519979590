export const consentToMarketingCookies = () => {
  const { preferenceCookiesApproved, statisticCookiesApproved } = useCookiesStore();
  if (isCookieBotActive()) {
    Cookiebot.submitCustomConsent?.(
      preferenceCookiesApproved,
      statisticCookiesApproved,
      true,
    );
  }
};

export const updateCookieConsentStates = () => {
  if (isCookieBotActive() && Cookiebot.consent) {
    const areFunctionalCookiesAllowed = Cookiebot.consent.necessary;
    const arePreferenceCookiesAllowed = Cookiebot.consent.preferences;
    const areStatisticCookiesAllowed = Cookiebot.consent.statistics;
    const areMarketingCookiesAllowed = Cookiebot.consent.marketing;

    useCookiesStore().setFunctionalCookiesApproved(areFunctionalCookiesAllowed);
    useCookiesStore().setPreferenceCookiesApproved(arePreferenceCookiesAllowed);
    useCookiesStore().setStatisticCookiesApproved(areStatisticCookiesAllowed);
    useCookiesStore().setMarketingCookiesApproved(areMarketingCookiesAllowed);
  }
};

/** Due to compliance, we are required to show the user the consent id and time.
 *  Therefore, we programatically inject these values into the consent dialog window.
 *  */
const addConsentInformationToDialog = () => {
  const consentId = Cookiebot.consentID;
  const consentDatetime = Cookiebot.consentUTC;

  const customConsentDetailsElementId = "ps-cookiebot-consent-details";

  if (!consentId || consentId === "0" || !consentDatetime) {
    return;
  }

  if (document.getElementById(customConsentDetailsElementId)) {
    return;
  }

  const element = document.getElementById("CybotCookiebotDialogBodyContentText");

  const newElement = document.createElement("div");
  newElement.setAttribute("id", customConsentDetailsElementId);
  newElement.style.setProperty("margin-top", "10px");
  newElement.style.setProperty("padding", "16px");
  newElement.style.setProperty("background-color", "#F2F2F2");
  newElement.style.setProperty("border-radius", "8px");

  const consentIdTitleElement = document.createElement("strong");
  consentIdTitleElement.textContent = "Deine Einwilligungs-ID:";

  const consentIdElement = document.createElement("div");
  consentIdElement.textContent = consentId;
  consentIdElement.style.setProperty("word-break", "break-all");

  const consentDatetimeTitleElement = document.createElement("strong");
  consentDatetimeTitleElement.textContent = "Einwilligungsdatum:";

  const consentDatetimeElement = document.createElement("div");
  consentDatetimeElement.textContent = consentDatetime.toLocaleString();

  newElement.appendChild(consentIdTitleElement);
  newElement.appendChild(consentIdElement);
  newElement.appendChild(consentDatetimeTitleElement);
  newElement.appendChild(consentDatetimeElement);

  element?.appendChild(newElement);
};

export default function initializeConsentEventListener() {
  window.addEventListener("CookiebotOnAccept", updateCookieConsentStates);
  window.addEventListener("CookiebotOnDecline", updateCookieConsentStates);
  window.addEventListener("CookiebotOnDialogDisplay", addConsentInformationToDialog);
}
