import revive_payload_client_4sVQNw7RlN from "/home/runner/work/polarstern-website/polarstern-website/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/polarstern-website/polarstern-website/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/polarstern-website/polarstern-website/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/home/runner/work/polarstern-website/polarstern-website/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/runner/work/polarstern-website/polarstern-website/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/polarstern-website/polarstern-website/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/polarstern-website/polarstern-website/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/polarstern-website/polarstern-website/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/runner/work/polarstern-website/polarstern-website/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/polarstern-website/polarstern-website/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/polarstern-website/polarstern-website/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/home/runner/work/polarstern-website/polarstern-website/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/home/runner/work/polarstern-website/polarstern-website/.nuxt/sentry-client-config.mjs";
import plugin_YksOOA8Nkt from "/home/runner/work/polarstern-website/polarstern-website/node_modules/nuxt-snackbar/dist/runtime/plugin.js";
import formkitPlugin_pZqjah0RUG from "/home/runner/work/polarstern-website/polarstern-website/.nuxt/formkitPlugin.mjs";
import plugin_AUP22rrBAc from "/home/runner/work/polarstern-website/polarstern-website/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import adminAuth_client_MyAxbDga3l from "/home/runner/work/polarstern-website/polarstern-website/plugins/adminAuth.client.ts";
import auth_client_4lHQFpoKNF from "/home/runner/work/polarstern-website/polarstern-website/plugins/auth.client.ts";
import brainAdapter_XZiw66sM35 from "/home/runner/work/polarstern-website/polarstern-website/plugins/brainAdapter.ts";
import chartjs_1wC9lAM0Tp from "/home/runner/work/polarstern-website/polarstern-website/plugins/chartjs.ts";
import cmsFileTools_QZmAKiCnXx from "/home/runner/work/polarstern-website/polarstern-website/plugins/cmsFileTools.ts";
import cmsImageTools_kZD7ql9aTd from "/home/runner/work/polarstern-website/polarstern-website/plugins/cmsImageTools.ts";
import leaflet_client_7dEOJA4hbk from "/home/runner/work/polarstern-website/polarstern-website/plugins/leaflet.client.ts";
import useBootstrap_client_ouDJvcVteA from "/home/runner/work/polarstern-website/polarstern-website/plugins/useBootstrap.client.ts";
import vue_gtm_client_stBjLl0OeM from "/home/runner/work/polarstern-website/polarstern-website/plugins/vue-gtm.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_YksOOA8Nkt,
  formkitPlugin_pZqjah0RUG,
  plugin_AUP22rrBAc,
  adminAuth_client_MyAxbDga3l,
  auth_client_4lHQFpoKNF,
  brainAdapter_XZiw66sM35,
  chartjs_1wC9lAM0Tp,
  cmsFileTools_QZmAKiCnXx,
  cmsImageTools_kZD7ql9aTd,
  leaflet_client_7dEOJA4hbk,
  useBootstrap_client_ouDJvcVteA,
  vue_gtm_client_stBjLl0OeM
]