<template>
  <div
    v-if="props.isVisible"
    :class="[
      { 'loading-spinner--fullscreen': props.isFullscreen },
      { 'loading-spinner--boxed': props.isBoxed },
    ]"
  >
    <div role="status" :class="['spinner-border', props.spinnerClasses]">
      <span class="sr-only">Bitte warten</span>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  isVisible?: boolean;
  isFullscreen?: boolean;
  /** Centers the spinner in a 200px high, full width box. */
  isBoxed?: boolean;
  spinnerClasses?: string;
}>();
</script>

<style lang="scss">
.loading-spinner {
  &--fullscreen {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(255 255 255 / 50%);
    z-index: 1000;
  }
  &--boxed {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
