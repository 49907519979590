import type { DeprecatedMeterReading } from "~/src/generated-sources/public";

export const sortMeterReadings = (meterReadings: DeprecatedMeterReading[]) => {
  return meterReadings.sort((a, b) => {
    return new Date(b.readAt).getTime() - new Date(a.readAt).getTime();
  });
};

export const getLatestMeterReading = (meterReadings: DeprecatedMeterReading[]) => {
  return sortMeterReadings(meterReadings)[0];
};
