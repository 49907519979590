import { differenceInDays } from "date-fns";

export const useNewsletterOverlayStore = defineStore(
  "NewsletterOverlayStore",
  () => {
    const pageViewCount = ref(0);
    const lastMagazineOverlayDate = ref<Date>();

    const isCustomerLoggedIn = () => {
      return useAuthStore().customer.loggedIn;
    };

    const isItTime = () => {
      if (!lastMagazineOverlayDate.value) return true;

      return differenceInDays(new Date(), new Date(lastMagazineOverlayDate.value)) >= 30;
    };

    const isOverlayAllowed = (): boolean => {
      return !isCustomerLoggedIn() && isItTime() && pageViewCount.value >= 3;
    };

    const resetOverlay = () => {
      pageViewCount.value = 0;
      lastMagazineOverlayDate.value = new Date();
    };

    const incrementPageViewCount = () => {
      if (!isCustomerLoggedIn() && isItTime()) {
        pageViewCount.value++;
      }
    };

    const resetStoreHelper = () => {
      pageViewCount.value = 0;
      lastMagazineOverlayDate.value = undefined;
    };

    return {
      pageViewCount,
      lastMagazineOverlayDate,
      isOverlayAllowed,
      incrementPageViewCount,
      resetOverlay,
      resetStoreHelper,
    };
  },

  {
    persist: { storage: persistedState.localStorage },
  },
);
