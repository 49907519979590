import type { PublicTariff } from "~/src/generated-sources/public";
import { PublicTariffSubTypeEnum } from "~/src/generated-sources/public";

export default (tariff: PublicTariff | null) => {
  if (tariff === null) return false;

  const tenantPowerTariffs: PublicTariffSubTypeEnum[] = [
    PublicTariffSubTypeEnum.TenantPower,
    PublicTariffSubTypeEnum.TenantPowerShadow,
  ];

  return tenantPowerTariffs.includes(tariff.subType);
};
