import type { PublicTariff } from "~/src/generated-sources/public";
import type { CalculatorTariffType } from "~/types/shared/calculator";

export const mapTariffToTariffProduct = (
  tariff: PublicTariff,
  tariffProduct: CalculatorTariffType,
) => {
  return (
    tariff.type === tariffProduct.tariffType &&
    tariff.subType === tariffProduct.tariffSubType &&
    tariff.runtimeType === tariffProduct.runTimeType
  );
};

/**
 * Returns a list of USPs.
 *
 * @param type
 * @param subType
 * @param runTimeType
 * @returns
 */
export const getTariffUsps = (tariff: PublicTariff): string[] => {
  const tariffProducts = useRuntimeConfig().public
    .tariffProducts as CalculatorTariffType[];

  const tariffProduct = tariffProducts.find((tariffProduct) =>
    mapTariffToTariffProduct(tariff, tariffProduct),
  );

  if (tariffProduct) {
    return tariffProduct.uniqueSellingPoints;
  } else {
    return [];
  }
};

export const getTariffDescription = (tariff: PublicTariff) => {
  const tariffProducts = useRuntimeConfig().public
    .tariffProducts as CalculatorTariffType[];

  const tariffProduct = tariffProducts.find((tariffProduct) =>
    mapTariffToTariffProduct(tariff, tariffProduct),
  );

  if (tariffProduct) {
    return tariffProduct.description;
  } else {
    return "";
  }
};
