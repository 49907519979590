/** This middleware is responsible for the visibility of the Cookiebot banner.
 *  It hides the banner on certain pages and reenables it, once the user navigates to another page.
 *
 *  This logic is related to server/plugins/noscript.ts.
 */
export default defineNuxtRouteMiddleware((to) => {
  if (process.client) {
    if (!Cookiebot?.hasResponse) {
      const hideCookieBannerOnPages = [
        "/impressum",
        "/impressum/",
        "/service/datenschutz",
        "/service/datenschutz/",
      ];

      const currentPage = to.path;
      if (hideCookieBannerOnPages.includes(currentPage)) {
        // AdBlockers might block the Cookiebot script, so we need to check if the Cookiebot func is available
        Cookiebot.hide?.();
      } else {
        Cookiebot.show?.();
      }
    }
  }
});
