<template>
  <div class="d-flex align-items-baseline gap-2 business-toggle">
    <p
      :class="['mb-0 text-end', { 'fw-bold': customerType == CustomerType.Private }]"
      style="flex: 1"
    >
      Privatkund:in
    </p>
    <FormKit
      v-model="customerType"
      outer-class="my-0 e2e--toggle-business-customer"
      type="toggle"
      name="toggle"
      :on-value="CustomerType.Company"
      :off-value="CustomerType.Private"
      :disabled="props.disabled"
      aria-label="Auswahl Kundentyp Geschäftskund:in"
    />
    <p
      :class="['mb-0', { 'fw-bold': customerType == CustomerType.Company }]"
      style="flex: 1"
    >
      Geschäftskund:in
    </p>
  </div>
</template>

<script setup lang="ts">
import { CustomerType } from "~/src/generated-sources/public";

const emits = defineEmits<{ (e: "update", type: CustomerType): void }>();
const props = defineProps<{ value?: CustomerType; disabled?: boolean }>();

// prettier-ignore
watch(() => props.value, (type) => {
  if (type) customerType.value = type;
});

const customerType = ref<CustomerType>(props.value ?? "private");

watch(customerType, (newValue) => {
  emits("update", newValue);
});
</script>
