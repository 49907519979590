import type { DownloadAsset } from "~/types/cms/link-target";

export default defineNuxtPlugin((nuxtApp) => {
  const assetMirrorConfig = nuxtApp.$config.public.assetMirror;
  /**
   * Turns an asset object into an URL
   *
   * Will return Asset Mirror URL or Sanity URL depending on
   * runtime config's `assetMirror.enabled`
   *
   * Be sure to pass in asset object that include
   * .url and .originalFilename, no ._ref objects
   *
   * @param source Asset source object (not ref!)
   * @returns URL of asset
   */

  const cmsFileUrl = (source: DownloadAsset) => {
    return assetMirrorConfig.enabled ? assetMirrorFileUrl(source) : sanityFileUrl(source);
  };

  /**
   * Generate file URLs (Sanity)
   *
   * @param source Sanity source object (including .url property)
   * @returns URL of asset
   */
  const sanityFileUrl = (source: DownloadAsset) => {
    return source.url;
  };

  /**
   * Generate file URLs (Asset Mirror)
   *
   * @param source Asset source object (including .originalFilename property)
   * @returns URL of asset
   */
  const assetMirrorFileUrl = (source: DownloadAsset) => {
    const filename = source.originalFilename;
    const encodedFilename = encodeURI(filename);

    return `${assetMirrorConfig.assetHost}${assetMirrorConfig.downloadsFolder}/${encodedFilename}`;
  };

  return {
    provide: {
      cmsFileUrl,
    },
  };
});
