import type { FormKitNode } from "@formkit/core";

export const convertEmptyStringToUndefinedPlugin = (node: FormKitNode) => {
  if (node.type !== "input") return;

  node.addProps(["convertEmptyStringToUndefined"]);

  node.on("created", () => {
    if (
      node.props.convertEmptyStringToUndefined &&
      typeof node.value === "string" &&
      !node.value?.trim().length
    )
      node.input(undefined, false);

    node.hook.commit((payload, next) => {
      if (
        node.props.convertEmptyStringToUndefined &&
        typeof payload === "string" &&
        !payload.trim().length
      )
        payload = undefined;

      return next(payload);
    });
  });
};

export default convertEmptyStringToUndefinedPlugin;
