// TODO: change this once we changed the data model for tariffs
export default (tariffId: string) => {
  const parts = tariffId.split("_");
  let id;

  parts.forEach((part, index) => {
    const isZipCode = /^\d{5}$/.test(part);
    if (isZipCode) {
      id = parts.slice(index + 1).join("_");
    }
  });
  return id ?? tariffId;
};
