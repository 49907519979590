<template>
  <div class="tariff-list--seperator"></div>
</template>

<style lang="scss" scoped>
.tariff-list--seperator {
  height: 10px;
  background: var(--background);

  &:after,
  &:before {
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-top-color: var(--ps-teal-light);
    border-width: 17px;
    margin-left: -17px;
  }
  &:before {
    border-top-color: var(--background);
    border-width: 30px;
    margin-left: -30px;
  }
}
</style>
