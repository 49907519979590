import { HTTPErrorHandlerBase, type BaseErrorPayload } from "./httpErrorHandlerBase";
import { Problem, ResourceConflictProblem } from "./httpProblemType";

/** Class handling 409 error response. */
export class ResourceConflictHandler extends HTTPErrorHandlerBase<ResourceConflictProblem> {
  public static readonly resourceConflictErrors: {
    [key in ResourceConflictProblem]?: string;
  } = {
    [ResourceConflictProblem.meterReserved]:
      "Dieser Zähler ist für einen Mieterstrom-Vertrag reserviert und kann deshalb nicht ausgewählt werden. Bitte kontaktiere den Kundenservice.",
    [ResourceConflictProblem.customerAlreadyExists]:
      "Diese E-Mail Adresse ist bereits vergeben.",
    [ResourceConflictProblem.contractTransfer]:
      "Dieser Vertrag kann aktuell nicht übertragen werden. Bitte versuche es später erneut oder kontaktiere den Kundenservice.",
    [ResourceConflictProblem.contractTermination]:
      "Dieser Vertrag kann aktuell nicht gekündigt werden. Bitte versuche es später erneut oder kontaktiere den Kundenservice.",
    [ResourceConflictProblem.emailAlreadyVerified]:
      "Diese E-Mail Adresse wurde bereits verifiziert.",
    [ResourceConflictProblem.newsletterAlreadySubscribed]:
      "Diese E-Mail-Adresse befindet sich bereits in unserem Verteiler oder kann nicht ausgewählt werden. Falls du ein Kundenkonto hast, kannst du deine Einstellungen im Kundenbereich ändern.",
    [ResourceConflictProblem.measuringPointOccupied]:
      // TODO introduce a router-link towards the measuring-point-overview page as soon as
      //  it is available and the backend is also returning the measuring point id for the admins
      "Die Messstelle für diese Zählernummer ist für den gewünschten Belieferungsbeginn bereits belegt. Bitte kontaktiere den Kundenservice.",
    [ResourceConflictProblem.projectAlreadyExists]:
      "Es existiert bereits ein Projekt mit diesem Namen oder ID.",
  };

  public constructor(public override readonly payload: BaseErrorPayload) {
    super(payload, ResourceConflictHandler.resourceConflictErrors);
  }

  // Most 409 errors are sent with generic type /errors/resource_conflict
  // but "details" differ for each case
  public mapError() {
    const details = this.details?.toLocaleLowerCase();

    if (this.type.includes(ResourceConflictProblem.meterReserved))
      return {
        [ResourceConflictProblem.meterReserved]:
          this.messages[ResourceConflictProblem.meterReserved],
      };
    else if (this.type.includes(ResourceConflictProblem.measuringPointOccupied))
      return {
        [ResourceConflictProblem.measuringPointOccupied]:
          this.messages[ResourceConflictProblem.measuringPointOccupied],
      };
    else if (this.type.includes(ResourceConflictProblem.newsletterAlreadySubscribed))
      return {
        [ResourceConflictProblem.newsletterAlreadySubscribed]:
          this.messages[ResourceConflictProblem.newsletterAlreadySubscribed],
      };
    else if (this.type.includes(ResourceConflictProblem.projectAlreadyExists))
      return {
        [ResourceConflictProblem.projectAlreadyExists]:
          this.messages[ResourceConflictProblem.projectAlreadyExists],
      };
    else if (["contract", "terminated"].every((word) => details?.includes(word)))
      return {
        [ResourceConflictProblem.contractTermination]:
          this.messages[ResourceConflictProblem.contractTermination],
      };
    else if (
      ["customer", "exists"].every((word) => details?.includes(word)) ||
      ["email", "another", "customer"].every((word) => details?.includes(word))
    ) {
      return {
        [ResourceConflictProblem.customerAlreadyExists]:
          this.messages[ResourceConflictProblem.customerAlreadyExists],
      };
    } else if (["contract", "transferred"].every((word) => details?.includes(word)))
      return {
        [ResourceConflictProblem.contractTransfer]:
          this.messages[ResourceConflictProblem.contractTransfer],
      };
    else if (["token", "not", "found"].every((word) => details?.includes(word)))
      return {
        [ResourceConflictProblem.emailAlreadyVerified]:
          this.messages[ResourceConflictProblem.emailAlreadyVerified],
      };
    return HTTPErrorHandlerBase.mapGeneralErrors(Problem.general);
  }
}

export default ResourceConflictHandler;
