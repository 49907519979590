import type { AccountInfo } from "@azure/msal-browser";
import { defineStore } from "pinia";
interface AdminAuthStoreState {
  _name: string | null;
  _email: string | null;
  _roles: AdminToolRoles[] | null;
}

export const useAdminAuthStore = defineStore("admin-auth-store", {
  state: (): AdminAuthStoreState => {
    return {
      _name: null,
      _email: null,
      _roles: null,
    };
  },
  actions: {
    setUserAccount(user: AccountInfo) {
      this._name = user.name || null;
    },
    setEmail(user: AccountInfo) {
      this._email = user.username || null;
    },
    setRoles(roles: AdminToolRoles[]) {
      this._roles = roles;
    },
  },
  getters: {
    loggedIn: (state) => Boolean(state._name),
    name: (state) => state._name,
    email: (state) => state._email,
    roles: (state) => state._roles,
    hasRole: (state) => {
      return (role: AdminToolRoles) => state._roles?.includes(role) || false;
    },
  },
});
