<template>
  <p
    v-if="description && !isBusinessCalculation"
    class="tariff-description"
    v-text="description"
  ></p>
</template>

<script lang="ts" setup>
import { type PublicTariffWithSummarizedPriceRanges as PublicTariff } from "~/src/generated-sources/public";

const props = defineProps<{ tariff: PublicTariff; isBusinessCalculation: boolean }>();
const description = computed(() => getTariffDescription(props.tariff));
</script>
