import hashString from "~/utils/tracking/hashString";

/** The event properties of a logged in customer. */
const getCustomerProperties = (id = "", emailAddress: string) => {
  const hashedId = id ? hashString(id) : "undefined";
  const hashedEmailAddress = hashString(emailAddress);

  return {
    user_id: hashedId,
    em_hash: hashedEmailAddress,
  };
};

export const useGetCurrentCustomerProperties = () => {
  const customer = useAuthStore().customer;
  if (!customer.loggedIn) return undefined;

  return getCustomerProperties(customer.id, customer.emailAddress);
};

export const trackCustomerLogin = (id: string, emailAddress: string) => {
  useTrackEvent({
    event: "login",
    ...getCustomerProperties(id, emailAddress),
  });
};

export const trackCustomerSignUp = (id: string, emailAddress: string) => {
  useTrackEvent({
    event: "sign_up",
    ...getCustomerProperties(id, emailAddress),
  });
};
