import {
  EventType,
  PublicClientApplication,
  type AuthenticationResult,
} from "@azure/msal-browser";
import AzureB2cClient from "~/auth/azure-b2c-client";
import { trackCustomerLogin } from "~/composables/useCustomerTracking";
import { mapAccountInfoToLoggedInCustomer } from "~/utils/customer-area/clientAuthentication";

/**
 * Our plugin for taking care of customer logins:
 * - Create AzureB2cClient with proper credentials
 * - Request user from client
 * - Save user to store
 */
export default defineNuxtPlugin(async (nuxtApp) => {
  const {
    baseUrl,
    auth: { clientId, authority, authorityDomain },
  } = nuxtApp.$config.public;
  const msalInstance = await PublicClientApplication.createPublicClientApplication({
    auth: {
      clientId,
      authority,
      knownAuthorities: [authorityDomain],
      navigateToLoginRequestUrl: false,
      redirectUri: baseUrl,
      postLogoutRedirectUri: "/",
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
  });
  const router = useRouter();
  const authClient = new AzureB2cClient("user", msalInstance, clientId);

  const removeHook = router.beforeEach(async () => {
    removeHook();
    await authClient.handleRedirect();
  });

  msalInstance.addEventCallback((message) => {
    if (message.eventType === EventType.LOGIN_SUCCESS) {
      const account = (message.payload as AuthenticationResult).account;
      const customer = mapAccountInfoToLoggedInCustomer(account);

      if (customer.id) {
        trackCustomerLogin(customer.id, customer.emailAddress);
      }
    }
  });

  return {
    provide: {
      auth: authClient,
    },
  };
});
