import validate from "/home/runner/work/polarstern-website/polarstern-website/node_modules/nuxt/dist/pages/runtime/validate.js";
import cookie_45banner_45global from "/home/runner/work/polarstern-website/polarstern-website/middleware/cookieBanner.global.ts";
import error_45page_45router_45fix_45global from "/home/runner/work/polarstern-website/polarstern-website/middleware/errorPageRouterFix.global.ts";
import newsletter_45overlay_45global from "/home/runner/work/polarstern-website/polarstern-website/middleware/newsletterOverlay.global.ts";
import manifest_45route_45rule from "/home/runner/work/polarstern-website/polarstern-website/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  cookie_45banner_45global,
  error_45page_45router_45fix_45global,
  newsletter_45overlay_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "admin-auth": () => import("/home/runner/work/polarstern-website/polarstern-website/middleware/adminAuth.ts"),
  "admin-user-data": () => import("/home/runner/work/polarstern-website/polarstern-website/middleware/adminUserData.ts"),
  auth: () => import("/home/runner/work/polarstern-website/polarstern-website/middleware/auth.ts"),
  "recommendation-route-guard": () => import("/home/runner/work/polarstern-website/polarstern-website/middleware/recommendationRouteGuard.ts"),
  "user-data": () => import("/home/runner/work/polarstern-website/polarstern-website/middleware/userData.ts")
}