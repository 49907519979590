/* tslint:disable */
/* eslint-disable */
/**
 * Public API
 * This definition contains the endpoints of the Polarstern Public API.
 *
 * The version of the OpenAPI document: 2.25.0
 * Contact: devs@polarstern-energie.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "./configuration";
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
import type { RequestArgs } from "./base";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  BaseAPI,
  RequiredError,
  operationServerMap,
} from "./base";

/**
 *
 * @export
 * @interface Address
 */
export interface Address {
  /**
   * This covers all potential additions for addresses like a sub compartment type.
   * @type {string}
   * @memberof Address
   */
  additionalInformation?: string;
  /**
   * Unique identifier of the market location within the regulated energy market.
   * @type {string}
   * @memberof Address
   */
  maLo?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  street: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  houseNumber: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  zipCode: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  city: string;
  /**
   *
   * @type {EntityType}
   * @memberof Address
   */
  type: EntityType;
  /**
   * Required if `type=private`.
   * @type {string}
   * @memberof Address
   */
  firstName?: string;
  /**
   * Required if `type=private`.
   * @type {string}
   * @memberof Address
   */
  lastName?: string;
  /**
   * Required if `type=company|ngo`.
   * @type {string}
   * @memberof Address
   */
  companyName?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  careOf?: string;
  /**
   * The two letter country code following ISO 3166-1 alpha-2 standard.
   * @type {string}
   * @memberof Address
   */
  country: string;
}

/**
 *
 * @export
 * @interface AddressWithAdditionalInformation
 */
export interface AddressWithAdditionalInformation {
  /**
   * This covers all potential additions for addresses like a sub compartment type.
   * @type {string}
   * @memberof AddressWithAdditionalInformation
   */
  additionalInformation?: string;
  /**
   * Unique identifier of the market location within the regulated energy market.
   * @type {string}
   * @memberof AddressWithAdditionalInformation
   */
  maLo?: string;
  /**
   *
   * @type {string}
   * @memberof AddressWithAdditionalInformation
   */
  street: string;
  /**
   *
   * @type {string}
   * @memberof AddressWithAdditionalInformation
   */
  houseNumber: string;
  /**
   *
   * @type {string}
   * @memberof AddressWithAdditionalInformation
   */
  zipCode: string;
  /**
   *
   * @type {string}
   * @memberof AddressWithAdditionalInformation
   */
  city: string;
}
/**
 *
 * @export
 * @interface Bank
 */
export interface Bank {
  /**
   * The name of the bank.
   * @type {string}
   * @memberof Bank
   */
  name: string;
  /**
   * The Bank Identifier Code (BIC), also known as Swift-Code, of the bank.
   * @type {string}
   * @memberof Bank
   */
  bic: string;
}
/**
 *
 * @export
 * @interface BankAccount
 */
export interface BankAccount {
  /**
   *
   * @type {Bank}
   * @memberof BankAccount
   */
  bank?: Bank;
  /**
   * Display name of the bank account, may be set by the customer.
   * @type {string}
   * @memberof BankAccount
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof BankAccount
   */
  iban: string;
  /**
   * The name of the account holder.
   * @type {string}
   * @memberof BankAccount
   */
  accountHolder: string;
}
/**
 *
 * @export
 * @interface BaseAddress
 */
export interface BaseAddress {
  /**
   *
   * @type {string}
   * @memberof BaseAddress
   */
  street: string;
  /**
   *
   * @type {string}
   * @memberof BaseAddress
   */
  houseNumber: string;
  /**
   *
   * @type {string}
   * @memberof BaseAddress
   */
  zipCode: string;
  /**
   *
   * @type {string}
   * @memberof BaseAddress
   */
  city: string;
}
/**
 *
 * @export
 * @interface BaseAddressWithMaLo
 */
export interface BaseAddressWithMaLo {
  /**
   * Unique identifier of the market location within the regulated energy market.
   * @type {string}
   * @memberof BaseAddressWithMaLo
   */
  maLo?: string;
  /**
   *
   * @type {string}
   * @memberof BaseAddressWithMaLo
   */
  street: string;
  /**
   *
   * @type {string}
   * @memberof BaseAddressWithMaLo
   */
  houseNumber: string;
  /**
   *
   * @type {string}
   * @memberof BaseAddressWithMaLo
   */
  zipCode: string;
  /**
   *
   * @type {string}
   * @memberof BaseAddressWithMaLo
   */
  city: string;
}
/**
 *
 * @export
 * @interface BaseContract
 */
export interface BaseContract {
  /**
   * Unique ID of the contract.
   * @type {string}
   * @memberof BaseContract
   */
  id: string;
  /**
   *
   * @type {ContractType}
   * @memberof BaseContract
   */
  type: ContractType;
  /**
   * The customer\'s estimate on their annual energy consumption.
   * @type {Energy}
   * @memberof BaseContract
   */
  annualConsumption: Energy;
  /**
   * The customer\'s estimate on their annual low tariff energy consumption. Can only be set for HT/NT-tariffs.
   * @type {Energy}
   * @memberof BaseContract
   */
  annualConsumptionLowTariff?: Energy;
  /**
   * A list of tariffs that are, were or will be used by this contract ordered by its validity starting with the oldest tariff.
   * @type {Array<TariffHistoryInner>}
   * @memberof BaseContract
   */
  tariffHistory: Array<TariffHistoryInner>;
  /**
   * This value describes if a comfort tariff expires in the next two months. Thus, it will be set to true, unless the customer already requested a tariff switch.
   * @type {boolean}
   * @memberof BaseContract
   */
  isTariffSwitchOutstanding?: boolean;
  /**
   * The monthly gross advance payment amount.
   * @type {Price}
   * @memberof BaseContract
   */
  advancePayment: Price;
  /**
   *
   * @type {OrderReason}
   * @memberof BaseContract
   */
  orderReason?: OrderReason;
  /**
   *
   * @type {ContractLifecycle}
   * @memberof BaseContract
   */
  lifecycle: ContractLifecycle;
  /**
   * This will be set if and only if the customer terminated this contract.
   * @type {Termination}
   * @memberof BaseContract
   */
  termination?: Termination;
  /**
   *
   * @type {PreviousContract}
   * @memberof BaseContract
   */
  previousContract?: PreviousContract;
  /**
   * The name of the contract. Can be used to differentiate between contracts in a human readable form. Not enforced to be unique.
   * @type {string}
   * @memberof BaseContract
   */
  name?: string;
  /**
   *
   * @type {BaseSepaMandate}
   * @memberof BaseContract
   */
  sepaMandate?: BaseSepaMandate;
  /**
   *
   * @type {DeprecatedMeter}
   * @memberof BaseContract
   */
  meter?: DeprecatedMeter;
  /**
   * Meter readings associated with this contract.
   * @type {Array<DeprecatedBaseMeterReading>}
   * @memberof BaseContract
   */
  meterReadings?: Array<DeprecatedBaseMeterReading>;
  /**
   *
   * @type {ContractState}
   * @memberof BaseContract
   */
  state: ContractState;
  /**
   *
   * @type {BankAccount}
   * @memberof BaseContract
   */
  bankAccount?: BankAccount;
  /**
   *
   * @type {Address}
   * @memberof BaseContract
   */
  billingAddress?: Address;
  /**
   *
   * @type {ContractPartner}
   * @memberof BaseContract
   */
  contractPartner?: ContractPartner;
  /**
   *
   * @type {BaseContractTransferredFrom}
   * @memberof BaseContract
   */
  transferredFrom?: BaseContractTransferredFrom;
  /**
   * Terms & Conditions may be accepted at a later time if a contract was added by customer support.
   * @type {boolean}
   * @memberof BaseContract
   */
  hasAcceptedGtc: boolean;
}

/**
 * This will be set if and only if this contract was transferred from another customer.
 * @export
 * @interface BaseContractTransferredFrom
 */
export interface BaseContractTransferredFrom {
  /**
   * The ID of the transfer that was used to transfer this contract.
   * @type {string}
   * @memberof BaseContractTransferredFrom
   */
  transferId: string;
  /**
   * The ID of the original contract this contract is based on.
   * @type {string}
   * @memberof BaseContractTransferredFrom
   */
  contractId: string;
}
/**
 *
 * @export
 * @interface BaseCustomer
 */
export interface BaseCustomer {
  /**
   * The unique ID of the customer.
   * @type {string}
   * @memberof BaseCustomer
   */
  id?: string;
  /**
   *
   * @type {EntityType}
   * @memberof BaseCustomer
   */
  customerType: EntityType;
  /**
   *
   * @type {PersonalData}
   * @memberof BaseCustomer
   */
  personalData: PersonalData;
  /**
   *
   * @type {EmailAddress}
   * @memberof BaseCustomer
   */
  emailAddress: EmailAddress;
  /**
   *
   * @type {PhoneNumber}
   * @memberof BaseCustomer
   */
  phoneNumber: PhoneNumber;
}

/**
 *
 * @export
 * @interface BaseMessage
 */
export interface BaseMessage {
  /**
   *
   * @type {string}
   * @memberof BaseMessage
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof BaseMessage
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof BaseMessage
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof BaseMessage
   */
  emailAddress: string;
  /**
   *
   * @type {string}
   * @memberof BaseMessage
   */
  content: string;
}
/**
 * If a previous contract needs to be terminated before this contract can become effective, it can be specified here.
 * @export
 * @interface BasePreviousContract
 */
export interface BasePreviousContract {
  /**
   * Date at which the previous contract was or will be terminated. If this is not provided on contract creation, Polarstern needs to terminate the contract on behalf of the customer.
   * @type {string}
   * @memberof BasePreviousContract
   */
  terminatedAt?: string;
}
/**
 *
 * @export
 * @interface BaseSepaMandate
 */
export interface BaseSepaMandate {
  /**
   * The date and time at which the customer accepted the SEPA mandate.
   * @type {string}
   * @memberof BaseSepaMandate
   */
  acceptedAt?: string;
  /**
   * The reference number of this SEPA mandate.
   * @type {string}
   * @memberof BaseSepaMandate
   */
  reference?: string;
}
/**
 *
 * @export
 * @interface BaseSupply
 */
export interface BaseSupply {
  /**
   * Date of requested supply of a customer. The value is required for the creation of a tenant power contract.
   * @type {string}
   * @memberof BaseSupply
   */
  requestedBeginAt?: string;
}
/**
 *
 * @export
 * @interface BaseTermination
 */
export interface BaseTermination {
  /**
   * Unique ID of the terminated contract.
   * @type {string}
   * @memberof BaseTermination
   */
  contractId?: string;
  /**
   * Date at which the contract was or will be terminated.
   * @type {string}
   * @memberof BaseTermination
   */
  terminatedAt: string;
  /**
   * If the customer moves to a new address and wants to stay with Polarstern. This is required to decide which document to send to the customer (Kündigungsbestätigung oder Abmeldebestätigung).
   * @type {boolean}
   * @memberof BaseTermination
   */
  movedToNewAddress?: boolean;
  /**
   *
   * @type {BaseTerminationReason}
   * @memberof BaseTermination
   */
  reason: BaseTerminationReason;
  /**
   *
   * @type {string}
   * @memberof BaseTermination
   */
  note?: string;
  /**
   * Date at which the termination was confirmed by the network provider.
   * @type {string}
   * @memberof BaseTermination
   */
  confirmedAt?: string;
  /**
   * Date and time at which the termination was created.
   * @type {string}
   * @memberof BaseTermination
   */
  createdAt: string;
  /**
   *
   * @type {Role}
   * @memberof BaseTermination
   */
  terminatedBy?: Role;
}

/**
 * @type BaseTerminationReason
 * The reason for the termination.
 * @export
 */
export type BaseTerminationReason = TerminationReason | string;

/**
 *
 * @export
 * @interface ChargingCardsOrder
 */
export interface ChargingCardsOrder {
  /**
   *
   * @type {EntityType}
   * @memberof ChargingCardsOrder
   */
  customerType: EntityType;
  /**
   *
   * @type {PersonalData}
   * @memberof ChargingCardsOrder
   */
  personalData: PersonalData;
  /**
   *
   * @type {string}
   * @memberof ChargingCardsOrder
   */
  customerId?: string;
  /**
   *
   * @type {string}
   * @memberof ChargingCardsOrder
   */
  emailAddress: string;
  /**
   *
   * @type {string}
   * @memberof ChargingCardsOrder
   */
  billingEmailAddress?: string;
  /**
   *
   * @type {string}
   * @memberof ChargingCardsOrder
   */
  phoneNumber: string;
  /**
   *
   * @type {ChargingCardsOrderChargingLocation}
   * @memberof ChargingCardsOrder
   */
  chargingLocation: ChargingCardsOrderChargingLocation;
  /**
   *
   * @type {string}
   * @memberof ChargingCardsOrder
   */
  requestedBeginAt: string;
  /**
   *
   * @type {Address}
   * @memberof ChargingCardsOrder
   */
  supplyAddress: Address;
  /**
   *
   * @type {Address}
   * @memberof ChargingCardsOrder
   */
  billingAddress?: Address;
  /**
   *
   * @type {number}
   * @memberof ChargingCardsOrder
   */
  chargingCardsCount: number;
  /**
   *
   * @type {ChargingCardsOrderBankAccount}
   * @memberof ChargingCardsOrder
   */
  bankAccount: ChargingCardsOrderBankAccount;
}

/**
 *
 * @export
 * @interface ChargingCardsOrderBankAccount
 */
export interface ChargingCardsOrderBankAccount {
  /**
   * The name of the account holder.
   * @type {string}
   * @memberof ChargingCardsOrderBankAccount
   */
  accountHolder: string;
  /**
   *
   * @type {string}
   * @memberof ChargingCardsOrderBankAccount
   */
  iban: string;
  /**
   * The Bank Identifier Code (BIC), also known as Swift-Code, of the bank.
   * @type {string}
   * @memberof ChargingCardsOrderBankAccount
   */
  bic: string;
}
/**
 *
 * @export
 * @interface ChargingCardsOrderChargingLocation
 */
export interface ChargingCardsOrderChargingLocation {
  /**
   *
   * @type {string}
   * @memberof ChargingCardsOrderChargingLocation
   */
  street: string;
  /**
   *
   * @type {string}
   * @memberof ChargingCardsOrderChargingLocation
   */
  houseNumber: string;
  /**
   *
   * @type {string}
   * @memberof ChargingCardsOrderChargingLocation
   */
  zipCode: string;
  /**
   *
   * @type {string}
   * @memberof ChargingCardsOrderChargingLocation
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof ChargingCardsOrderChargingLocation
   */
  comment?: string;
}
/**
 *
 * @export
 * @interface ChargingNetworkMessage
 */
export interface ChargingNetworkMessage {
  /**
   *
   * @type {string}
   * @memberof ChargingNetworkMessage
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof ChargingNetworkMessage
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof ChargingNetworkMessage
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof ChargingNetworkMessage
   */
  emailAddress: string;
  /**
   *
   * @type {string}
   * @memberof ChargingNetworkMessage
   */
  content: string;
  /**
   *
   * @type {string}
   * @memberof ChargingNetworkMessage
   */
  companyName?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ChargingNetworkMessage
   */
  contactReasons?: Array<ChargingNetworkMessageContactReasonsEnum>;
}

export const ChargingNetworkMessageContactReasonsEnum = {
  SpecialTariffs: "specialTariffs",
  ChargingNetwork: "chargingNetwork",
  Other: "other",
} as const;

export type ChargingNetworkMessageContactReasonsEnum =
  (typeof ChargingNetworkMessageContactReasonsEnum)[keyof typeof ChargingNetworkMessageContactReasonsEnum];

/**
 *
 * @export
 * @interface CommunicationPackageOrder
 */
export interface CommunicationPackageOrder {
  /**
   *
   * @type {string}
   * @memberof CommunicationPackageOrder
   */
  customerId?: string;
  /**
   *
   * @type {string}
   * @memberof CommunicationPackageOrder
   */
  emailAddress: string;
  /**
   *
   * @type {BaseAddress}
   * @memberof CommunicationPackageOrder
   */
  address?: BaseAddress;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof CommunicationPackageOrder
   */
  products: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof CommunicationPackageOrder
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof CommunicationPackageOrder
   */
  type: CommunicationPackageOrderTypeEnum;
}

export const CommunicationPackageOrderTypeEnum = {
  BusinessCustomer: "businessCustomer",
  TenantPower: "tenantPower",
} as const;

export type CommunicationPackageOrderTypeEnum =
  (typeof CommunicationPackageOrderTypeEnum)[keyof typeof CommunicationPackageOrderTypeEnum];

/**
 *
 * @export
 * @interface CompanyPersonalData
 */
export interface CompanyPersonalData {
  /**
   *
   * @type {string}
   * @memberof CompanyPersonalData
   */
  companyName: string;
}
/**
 *
 * @export
 * @interface ConfirmedSupply
 */
export interface ConfirmedSupply {
  /**
   * Date of requested supply of a customer. The value is required for the creation of a tenant power contract.
   * @type {string}
   * @memberof ConfirmedSupply
   */
  requestedBeginAt?: string;
  /**
   * Supply address. With exception of MaLo, it is also used as billing address, as long as no separate billing address is provided.
   * @type {Address}
   * @memberof ConfirmedSupply
   */
  address?: Address;
  /**
   * Date at which network operator confirmed to begin to supply this contract.
   * @type {string}
   * @memberof ConfirmedSupply
   */
  confirmedBeginAt?: string;
  /**
   * Date and time at which supply date was confirmed by a network provider.
   * @type {string}
   * @memberof ConfirmedSupply
   */
  confirmedAt?: string;
}
/**
 *
 * @export
 * @interface Contact
 */
export interface Contact {
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  id: string;
  /**
   *
   * @type {EmailAddress}
   * @memberof Contact
   */
  emailAddress: EmailAddress;
  /**
   *
   * @type {EntityType}
   * @memberof Contact
   */
  type?: EntityType;
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  companyName?: string;
}

/**
 *
 * @export
 * @interface ContactMessage
 */
export interface ContactMessage {
  /**
   *
   * @type {ContactMessageSender}
   * @memberof ContactMessage
   */
  sender: ContactMessageSender;
  /**
   *
   * @type {string}
   * @memberof ContactMessage
   */
  content: string;
  /**
   * The type of the contact message.
   * @type {string}
   * @memberof ContactMessage
   */
  type: ContactMessageTypeEnum;
}

export const ContactMessageTypeEnum = {
  General: "general",
  Sparda: "sparda",
} as const;

export type ContactMessageTypeEnum =
  (typeof ContactMessageTypeEnum)[keyof typeof ContactMessageTypeEnum];

/**
 *
 * @export
 * @interface ContactMessageSender
 */
export interface ContactMessageSender {
  /**
   *
   * @type {string}
   * @memberof ContactMessageSender
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof ContactMessageSender
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof ContactMessageSender
   */
  customerId?: string;
  /**
   *
   * @type {string}
   * @memberof ContactMessageSender
   */
  emailAddress: string;
  /**
   *
   * @type {string}
   * @memberof ContactMessageSender
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof ContactMessageSender
   */
  phoneAvailability: string;
}
/**
 *
 * @export
 * @interface Contract
 */
export interface Contract {
  /**
   * Unique ID of the contract.
   * @type {string}
   * @memberof Contract
   */
  id: string;
  /**
   *
   * @type {ContractType}
   * @memberof Contract
   */
  type: ContractType;
  /**
   * The customer\'s estimate on their annual energy consumption.
   * @type {Energy}
   * @memberof Contract
   */
  annualConsumption: Energy;
  /**
   * The customer\'s estimate on their annual low tariff energy consumption. Can only be set for HT/NT-tariffs.
   * @type {Energy}
   * @memberof Contract
   */
  annualConsumptionLowTariff?: Energy;
  /**
   * A list of tariffs that are, were or will be used by this contract ordered by its validity starting with the oldest tariff.
   * @type {Array<TariffHistoryInner>}
   * @memberof Contract
   */
  tariffHistory: Array<TariffHistoryInner>;
  /**
   * This value describes if a comfort tariff expires in the next two months. Thus, it will be set to true, unless the customer already requested a tariff switch.
   * @type {boolean}
   * @memberof Contract
   */
  isTariffSwitchOutstanding?: boolean;
  /**
   * The monthly gross advance payment amount.
   * @type {Price}
   * @memberof Contract
   */
  advancePayment: Price;
  /**
   *
   * @type {OrderReason}
   * @memberof Contract
   */
  orderReason?: OrderReason;
  /**
   *
   * @type {ContractLifecycle}
   * @memberof Contract
   */
  lifecycle: ContractLifecycle;
  /**
   * This will be set if and only if the customer terminated this contract.
   * @type {Termination}
   * @memberof Contract
   */
  termination?: Termination;
  /**
   *
   * @type {PreviousContract}
   * @memberof Contract
   */
  previousContract?: PreviousContract;
  /**
   * The name of the contract. Can be used to differentiate between contracts in a human readable form. Not enforced to be unique.
   * @type {string}
   * @memberof Contract
   */
  name?: string;
  /**
   *
   * @type {SepaMandate}
   * @memberof Contract
   */
  sepaMandate?: SepaMandate;
  /**
   *
   * @type {DeprecatedMeter}
   * @memberof Contract
   */
  meter?: DeprecatedMeter;
  /**
   *
   * @type {Array<DeprecatedMeterReading>}
   * @memberof Contract
   */
  meterReadings?: Array<DeprecatedMeterReading>;
  /**
   *
   * @type {ContractState}
   * @memberof Contract
   */
  state: ContractState;
  /**
   *
   * @type {BankAccount}
   * @memberof Contract
   */
  bankAccount?: BankAccount;
  /**
   *
   * @type {Address}
   * @memberof Contract
   */
  billingAddress?: Address;
  /**
   *
   * @type {ContractPartner}
   * @memberof Contract
   */
  contractPartner?: ContractPartner;
  /**
   *
   * @type {BaseContractTransferredFrom}
   * @memberof Contract
   */
  transferredFrom?: BaseContractTransferredFrom;
  /**
   * Terms & Conditions may be accepted at a later time if a contract was added by customer support.
   * @type {boolean}
   * @memberof Contract
   */
  hasAcceptedGtc: boolean;
  /**
   *
   * @type {ContractAllOfSupply}
   * @memberof Contract
   */
  supply: ContractAllOfSupply;
}

/**
 *
 * @export
 * @interface ContractAllOfSupply
 */
export interface ContractAllOfSupply {
  /**
   * Date of requested supply of a customer. The value is required for the creation of a tenant power contract.
   * @type {string}
   * @memberof ContractAllOfSupply
   */
  requestedBeginAt?: string;
  /**
   * Supply address. With exception of MaLo, it is also used as billing address, as long as no separate billing address is provided.
   * @type {Address}
   * @memberof ContractAllOfSupply
   */
  address: Address;
  /**
   *
   * @type {any}
   * @memberof ContractAllOfSupply
   */
  confirmedBeginAt?: any;
  /**
   *
   * @type {any}
   * @memberof ContractAllOfSupply
   */
  confirmedAt?: any;
}
/**
 *
 * @export
 * @interface ContractAsCustomer
 */
export interface ContractAsCustomer {
  /**
   * Unique ID of the contract.
   * @type {string}
   * @memberof ContractAsCustomer
   */
  id: string;
  /**
   *
   * @type {ContractType}
   * @memberof ContractAsCustomer
   */
  type: ContractType;
  /**
   * The customer\'s estimate on their annual energy consumption.
   * @type {Energy}
   * @memberof ContractAsCustomer
   */
  annualConsumption: Energy;
  /**
   * The customer\'s estimate on their annual low tariff energy consumption. Can only be set for HT/NT-tariffs.
   * @type {Energy}
   * @memberof ContractAsCustomer
   */
  annualConsumptionLowTariff?: Energy;
  /**
   * A list of tariffs that are, were or will be used by this contract ordered by its validity starting with the oldest tariff.
   * @type {Array<TariffHistoryInner>}
   * @memberof ContractAsCustomer
   */
  tariffHistory: Array<TariffHistoryInner>;
  /**
   * This value describes if a comfort tariff expires in the next two months. Thus, it will be set to true, unless the customer already requested a tariff switch.
   * @type {boolean}
   * @memberof ContractAsCustomer
   */
  isTariffSwitchOutstanding?: boolean;
  /**
   * The monthly gross advance payment amount.
   * @type {Price}
   * @memberof ContractAsCustomer
   */
  advancePayment: Price;
  /**
   *
   * @type {OrderReason}
   * @memberof ContractAsCustomer
   */
  orderReason?: OrderReason;
  /**
   *
   * @type {ContractLifecycle}
   * @memberof ContractAsCustomer
   */
  lifecycle: ContractLifecycle;
  /**
   * This will be set if and only if the customer terminated this contract.
   * @type {Termination}
   * @memberof ContractAsCustomer
   */
  termination?: Termination;
  /**
   *
   * @type {PreviousContract}
   * @memberof ContractAsCustomer
   */
  previousContract?: PreviousContract;
  /**
   * The name of the contract. Can be used to differentiate between contracts in a human readable form. Not enforced to be unique.
   * @type {string}
   * @memberof ContractAsCustomer
   */
  name?: string;
  /**
   *
   * @type {SepaMandate}
   * @memberof ContractAsCustomer
   */
  sepaMandate?: SepaMandate;
  /**
   *
   * @type {DeprecatedMeter}
   * @memberof ContractAsCustomer
   */
  meter?: DeprecatedMeter;
  /**
   *
   * @type {Array<DeprecatedMeterReading>}
   * @memberof ContractAsCustomer
   */
  meterReadings?: Array<DeprecatedMeterReading>;
  /**
   *
   * @type {ContractState}
   * @memberof ContractAsCustomer
   */
  state: ContractState;
  /**
   *
   * @type {BankAccount}
   * @memberof ContractAsCustomer
   */
  bankAccount?: BankAccount;
  /**
   *
   * @type {Address}
   * @memberof ContractAsCustomer
   */
  billingAddress?: Address;
  /**
   *
   * @type {ContractPartner}
   * @memberof ContractAsCustomer
   */
  contractPartner?: ContractPartner;
  /**
   *
   * @type {BaseContractTransferredFrom}
   * @memberof ContractAsCustomer
   */
  transferredFrom?: BaseContractTransferredFrom;
  /**
   * Terms & Conditions may be accepted at a later time if a contract was added by customer support.
   * @type {boolean}
   * @memberof ContractAsCustomer
   */
  hasAcceptedGtc: boolean;
  /**
   *
   * @type {ContractAllOfSupply}
   * @memberof ContractAsCustomer
   */
  supply: ContractAllOfSupply;
  /**
   * Whether this contract is self payed by the customer without a sepa mandate.
   * @type {boolean}
   * @memberof ContractAsCustomer
   */
  selfPayer: boolean;
  /**
   *
   * @type {ForecastBasis}
   * @memberof ContractAsCustomer
   */
  forecastBasis: ForecastBasis;
}

/**
 *
 * @export
 * @interface ContractLifecycle
 */
export interface ContractLifecycle {
  /**
   * The date and time at which the contract was created initially.
   * @type {string}
   * @memberof ContractLifecycle
   */
  createdAt: string;
  /**
   * The date and time at which the contract was moved (i.e. replaced by another contract). This means that a new address is supplied starting at this time, while this contract can still be active for the previous address.
   * @type {string}
   * @memberof ContractLifecycle
   */
  customerMovedAt?: string;
  /**
   * The date and time at which a transfer for this contract was initiated.
   * @type {string}
   * @memberof ContractLifecycle
   */
  transferInitiatedAt?: string;
  /**
   * The date and time at which the transfer for this contract was accepted by the receiving party.
   * @type {string}
   * @memberof ContractLifecycle
   */
  transferAcceptedAt?: string;
}
/**
 *
 * @export
 * @interface ContractPartner
 */
export interface ContractPartner {
  /**
   *
   * @type {EmailAddress}
   * @memberof ContractPartner
   */
  emailAddress?: EmailAddress;
  /**
   *
   * @type {string}
   * @memberof ContractPartner
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof ContractPartner
   */
  lastName: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ContractState = {
  Incomplete: "Incomplete",
  Buffered: "Buffered",
  Pending: "Pending",
  Active: "Active",
  Rejected: "Rejected",
  Terminated: "Terminated",
} as const;

export type ContractState = (typeof ContractState)[keyof typeof ContractState];

/**
 *
 * @export
 * @interface ContractTodo
 */
export interface ContractTodo {
  /**
   *
   * @type {string}
   * @memberof ContractTodo
   */
  resource: ContractTodoResourceEnum;
  /**
   * The type of the information that is missing.
   * @type {string}
   * @memberof ContractTodo
   */
  type: ContractTodoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ContractTodo
   */
  contractId: string;
}

export const ContractTodoResourceEnum = {
  Contract: "contract",
} as const;

export type ContractTodoResourceEnum =
  (typeof ContractTodoResourceEnum)[keyof typeof ContractTodoResourceEnum];
export const ContractTodoTypeEnum = {
  TermsAndConditions: "termsAndConditions",
  OrderReason: "orderReason",
  Meter: "meter",
  BankAccount: "bankAccount",
} as const;

export type ContractTodoTypeEnum =
  (typeof ContractTodoTypeEnum)[keyof typeof ContractTodoTypeEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const ContractType = {
  Gas: "gas",
  Power: "power",
  EMobility: "eMobility",
} as const;

export type ContractType = (typeof ContractType)[keyof typeof ContractType];

/**
 *
 * @export
 * @interface CreateCustomer400Response
 */
export interface CreateCustomer400Response {
  /**
   * The type of the problem as a (relative) URI.
   * @type {string}
   * @memberof CreateCustomer400Response
   */
  type: string;
  /**
   * A summary of the problem. This should be the same for problems of the same type.
   * @type {string}
   * @memberof CreateCustomer400Response
   */
  title: string;
  /**
   * The code of the error that caused the request to fail.
   * @type {number}
   * @memberof CreateCustomer400Response
   */
  status: number;
  /**
   * A detailed, human-readable description of the problem. This may differ between problems of the same type.
   * @type {string}
   * @memberof CreateCustomer400Response
   */
  details?: string;
  /**
   * Dictionary containing validation errors.
   * @type {{ [key: string]: string; }}
   * @memberof CreateCustomer400Response
   */
  validationErrors?: { [key: string]: string };
  /**
   *
   * @type {InvalidZipCodeValidationProblemAllOfInvalidPayload}
   * @memberof CreateCustomer400Response
   */
  invalidPayload?: InvalidZipCodeValidationProblemAllOfInvalidPayload;
}
/**
 *
 * @export
 * @interface Customer
 */
export interface Customer {
  /**
   *
   * @type {any}
   * @memberof Customer
   */
  id: any;
  /**
   *
   * @type {EntityType}
   * @memberof Customer
   */
  customerType: EntityType;
  /**
   *
   * @type {PersonalData}
   * @memberof Customer
   */
  personalData: PersonalData;
  /**
   *
   * @type {EmailAddress}
   * @memberof Customer
   */
  emailAddress: EmailAddress;
  /**
   *
   * @type {PhoneNumber}
   * @memberof Customer
   */
  phoneNumber: PhoneNumber;
  /**
   *
   * @type {Array<Todo>}
   * @memberof Customer
   */
  todos: Array<Todo>;
  /**
   * The promotion code redeemed by this customer.
   * @type {string}
   * @memberof Customer
   */
  promotionCode?: string;
  /**
   * The universal code to share for recommendations by this customer.
   * @type {string}
   * @memberof Customer
   */
  universalCode: string;
  /**
   * The password to set for the customer\'s user account.
   * @type {string}
   * @memberof Customer
   */
  password: string;
}

/**
 *
 * @export
 * @interface CustomerTodo
 */
export interface CustomerTodo {
  /**
   *
   * @type {string}
   * @memberof CustomerTodo
   */
  resource: CustomerTodoResourceEnum;
  /**
   * The type of the information that is missing.
   * @type {string}
   * @memberof CustomerTodo
   */
  type: CustomerTodoTypeEnum;
}

export const CustomerTodoResourceEnum = {
  Customer: "customer",
} as const;

export type CustomerTodoResourceEnum =
  (typeof CustomerTodoResourceEnum)[keyof typeof CustomerTodoResourceEnum];
export const CustomerTodoTypeEnum = {
  EmailAddressVerification: "emailAddressVerification",
} as const;

export type CustomerTodoTypeEnum =
  (typeof CustomerTodoTypeEnum)[keyof typeof CustomerTodoTypeEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const CustomerType = {
  Private: "private",
  Company: "company",
} as const;

export type CustomerType = (typeof CustomerType)[keyof typeof CustomerType];

/**
 *
 * @export
 * @interface CustomerWithContracts
 */
export interface CustomerWithContracts {
  /**
   *
   * @type {any}
   * @memberof CustomerWithContracts
   */
  id: any;
  /**
   *
   * @type {EntityType}
   * @memberof CustomerWithContracts
   */
  customerType: EntityType;
  /**
   *
   * @type {PersonalData}
   * @memberof CustomerWithContracts
   */
  personalData: PersonalData;
  /**
   *
   * @type {EmailAddress}
   * @memberof CustomerWithContracts
   */
  emailAddress: EmailAddress;
  /**
   *
   * @type {PhoneNumber}
   * @memberof CustomerWithContracts
   */
  phoneNumber: PhoneNumber;
  /**
   *
   * @type {Array<Todo>}
   * @memberof CustomerWithContracts
   */
  todos: Array<Todo>;
  /**
   * The promotion code redeemed by this customer.
   * @type {string}
   * @memberof CustomerWithContracts
   */
  promotionCode?: string;
  /**
   * The universal code to share for recommendations by this customer.
   * @type {string}
   * @memberof CustomerWithContracts
   */
  universalCode: string;
  /**
   * The password to set for the customer\'s user account.
   * @type {string}
   * @memberof CustomerWithContracts
   */
  password: string;
  /**
   *
   * @type {Array<CustomerWithContractsAllOfContracts>}
   * @memberof CustomerWithContracts
   */
  contracts: Array<CustomerWithContractsAllOfContracts>;
}

/**
 *
 * @export
 * @interface CustomerWithContractsAllOfContracts
 */
export interface CustomerWithContractsAllOfContracts {
  /**
   * Unique ID of the contract.
   * @type {string}
   * @memberof CustomerWithContractsAllOfContracts
   */
  id: string;
  /**
   *
   * @type {ContractType}
   * @memberof CustomerWithContractsAllOfContracts
   */
  type: ContractType;
  /**
   * The customer\'s estimate on their annual energy consumption.
   * @type {Energy}
   * @memberof CustomerWithContractsAllOfContracts
   */
  annualConsumption: Energy;
  /**
   * The customer\'s estimate on their annual low tariff energy consumption. Can only be set for HT/NT-tariffs.
   * @type {Energy}
   * @memberof CustomerWithContractsAllOfContracts
   */
  annualConsumptionLowTariff?: Energy;
  /**
   * A list of tariffs that are, were or will be used by this contract ordered by its validity starting with the oldest tariff.
   * @type {Array<TariffHistoryInner>}
   * @memberof CustomerWithContractsAllOfContracts
   */
  tariffHistory: Array<TariffHistoryInner>;
  /**
   * This value describes if a comfort tariff expires in the next two months. Thus, it will be set to true, unless the customer already requested a tariff switch.
   * @type {boolean}
   * @memberof CustomerWithContractsAllOfContracts
   */
  isTariffSwitchOutstanding?: boolean;
  /**
   * The monthly gross advance payment amount.
   * @type {Price}
   * @memberof CustomerWithContractsAllOfContracts
   */
  advancePayment: Price;
  /**
   *
   * @type {OrderReason}
   * @memberof CustomerWithContractsAllOfContracts
   */
  orderReason?: OrderReason;
  /**
   *
   * @type {ContractLifecycle}
   * @memberof CustomerWithContractsAllOfContracts
   */
  lifecycle: ContractLifecycle;
  /**
   * This will be set if and only if the customer terminated this contract.
   * @type {Termination}
   * @memberof CustomerWithContractsAllOfContracts
   */
  termination?: Termination;
  /**
   *
   * @type {PreviousContract}
   * @memberof CustomerWithContractsAllOfContracts
   */
  previousContract?: PreviousContract;
  /**
   * The name of the contract. Can be used to differentiate between contracts in a human readable form. Not enforced to be unique.
   * @type {string}
   * @memberof CustomerWithContractsAllOfContracts
   */
  name?: string;
  /**
   *
   * @type {SepaMandate}
   * @memberof CustomerWithContractsAllOfContracts
   */
  sepaMandate: SepaMandate;
  /**
   *
   * @type {DeprecatedMeter}
   * @memberof CustomerWithContractsAllOfContracts
   */
  meter?: DeprecatedMeter;
  /**
   *
   * @type {Array<DeprecatedMeterReading>}
   * @memberof CustomerWithContractsAllOfContracts
   */
  meterReadings?: Array<DeprecatedMeterReading>;
  /**
   *
   * @type {ContractState}
   * @memberof CustomerWithContractsAllOfContracts
   */
  state: ContractState;
  /**
   *
   * @type {BankAccount}
   * @memberof CustomerWithContractsAllOfContracts
   */
  bankAccount: BankAccount;
  /**
   *
   * @type {Address}
   * @memberof CustomerWithContractsAllOfContracts
   */
  billingAddress?: Address;
  /**
   *
   * @type {ContractPartner}
   * @memberof CustomerWithContractsAllOfContracts
   */
  contractPartner?: ContractPartner;
  /**
   *
   * @type {BaseContractTransferredFrom}
   * @memberof CustomerWithContractsAllOfContracts
   */
  transferredFrom?: BaseContractTransferredFrom;
  /**
   * Terms & Conditions may be accepted at a later time if a contract was added by customer support.
   * @type {boolean}
   * @memberof CustomerWithContractsAllOfContracts
   */
  hasAcceptedGtc: boolean;
  /**
   *
   * @type {ContractAllOfSupply}
   * @memberof CustomerWithContractsAllOfContracts
   */
  supply: ContractAllOfSupply;
  /**
   * Whether this contract is self payed by the customer without a sepa mandate.
   * @type {boolean}
   * @memberof CustomerWithContractsAllOfContracts
   */
  selfPayer: boolean;
  /**
   *
   * @type {ForecastBasis}
   * @memberof CustomerWithContractsAllOfContracts
   */
  forecastBasis: ForecastBasis;
}

/**
 *
 * @export
 * @interface CustomerWithoutPassword
 */
export interface CustomerWithoutPassword {
  /**
   * The unique ID of the customer.
   * @type {string}
   * @memberof CustomerWithoutPassword
   */
  id?: string;
  /**
   *
   * @type {EntityType}
   * @memberof CustomerWithoutPassword
   */
  customerType: EntityType;
  /**
   *
   * @type {PersonalData}
   * @memberof CustomerWithoutPassword
   */
  personalData: PersonalData;
  /**
   *
   * @type {EmailAddress}
   * @memberof CustomerWithoutPassword
   */
  emailAddress: EmailAddress;
  /**
   *
   * @type {PhoneNumber}
   * @memberof CustomerWithoutPassword
   */
  phoneNumber: PhoneNumber;
  /**
   *
   * @type {Array<Todo>}
   * @memberof CustomerWithoutPassword
   */
  todos: Array<Todo>;
  /**
   * The promotion code redeemed by this customer.
   * @type {string}
   * @memberof CustomerWithoutPassword
   */
  promotionCode?: string;
  /**
   * The universal code to share for recommendations by this customer.
   * @type {string}
   * @memberof CustomerWithoutPassword
   */
  universalCode: string;
}

/**
 *
 * @export
 * @interface DeprecatedBaseMeterReading
 */
export interface DeprecatedBaseMeterReading {
  /**
   *
   * @type {number}
   * @memberof DeprecatedBaseMeterReading
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof DeprecatedBaseMeterReading
   */
  unit: DeprecatedBaseMeterReadingUnitEnum;
  /**
   * The ID of this meter reading.
   * @type {string}
   * @memberof DeprecatedBaseMeterReading
   */
  id: string;
  /**
   * The serial number of the meter. A serial number is NOT unique but duplicates are very rare.
   * @type {string}
   * @memberof DeprecatedBaseMeterReading
   */
  meterSerialNumber?: string;
  /**
   * The date of the reading.
   * @type {string}
   * @memberof DeprecatedBaseMeterReading
   */
  readAt: string;
  /**
   * If the reading is for low tariff (true) or high tariff (false).
   * @type {boolean}
   * @memberof DeprecatedBaseMeterReading
   */
  isLowTariff?: boolean;
  /**
   *
   * @type {MeterReadingType}
   * @memberof DeprecatedBaseMeterReading
   */
  type: MeterReadingType;
}

export const DeprecatedBaseMeterReadingUnitEnum = {
  KWh: "kWh",
  M3: "m3",
} as const;

export type DeprecatedBaseMeterReadingUnitEnum =
  (typeof DeprecatedBaseMeterReadingUnitEnum)[keyof typeof DeprecatedBaseMeterReadingUnitEnum];

/**
 *
 * @export
 * @interface DeprecatedMeter
 */
export interface DeprecatedMeter {
  /**
   * The serial number of the meter. A serial number is NOT unique but duplicates are very rare.
   * @type {string}
   * @memberof DeprecatedMeter
   */
  serialNumber: string;
  /**
   * The date at which the meter was or will be installed.
   * @type {string}
   * @memberof DeprecatedMeter
   */
  installedAt?: string;
  /**
   * A remote controllable meter can be remotely disconnected from the power grid by the network operator.
   * @type {boolean}
   * @memberof DeprecatedMeter
   */
  isRemoteControllable?: boolean;
}
/**
 *
 * @export
 * @interface DeprecatedMeterReading
 */
export interface DeprecatedMeterReading {
  /**
   *
   * @type {number}
   * @memberof DeprecatedMeterReading
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof DeprecatedMeterReading
   */
  unit: DeprecatedMeterReadingUnitEnum;
  /**
   * The ID of this meter reading.
   * @type {string}
   * @memberof DeprecatedMeterReading
   */
  id: string;
  /**
   *
   * @type {any}
   * @memberof DeprecatedMeterReading
   */
  meterSerialNumber?: any;
  /**
   * The date of the reading.
   * @type {string}
   * @memberof DeprecatedMeterReading
   */
  readAt: string;
  /**
   * If the reading is for low tariff (true) or high tariff (false).
   * @type {boolean}
   * @memberof DeprecatedMeterReading
   */
  isLowTariff?: boolean;
  /**
   *
   * @type {any}
   * @memberof DeprecatedMeterReading
   */
  type: any;
}

export const DeprecatedMeterReadingUnitEnum = {
  KWh: "kWh",
  M3: "m3",
} as const;

export type DeprecatedMeterReadingUnitEnum =
  (typeof DeprecatedMeterReadingUnitEnum)[keyof typeof DeprecatedMeterReadingUnitEnum];

/**
 *
 * @export
 * @interface ElectricVehicleFleetMessage
 */
export interface ElectricVehicleFleetMessage {
  /**
   *
   * @type {string}
   * @memberof ElectricVehicleFleetMessage
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof ElectricVehicleFleetMessage
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof ElectricVehicleFleetMessage
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof ElectricVehicleFleetMessage
   */
  emailAddress: string;
  /**
   *
   * @type {string}
   * @memberof ElectricVehicleFleetMessage
   */
  content: string;
  /**
   *
   * @type {PhoneAvailability}
   * @memberof ElectricVehicleFleetMessage
   */
  phoneAvailability?: PhoneAvailability;
}
/**
 *
 * @export
 * @interface EmailAddress
 */
export interface EmailAddress {
  /**
   *
   * @type {string}
   * @memberof EmailAddress
   */
  value: string;
  /**
   *
   * @type {Array<string>}
   * @memberof EmailAddress
   */
  subscriptions?: Array<EmailAddressSubscriptionsEnum>;
  /**
   * Date and time at which the email address was verified. Will be set by the server if and only if the email address has been verified.
   * @type {string}
   * @memberof EmailAddress
   */
  verifiedAt?: string;
}

export const EmailAddressSubscriptionsEnum = {
  GeneralNewsletter: "generalNewsletter",
  InterestedNewsletter: "interestedNewsletter",
  TenantPowerNewsletter: "tenantPowerNewsletter",
  HeatPumpNewsletter: "heatPumpNewsletter",
  EmobilityNewsletter: "emobilityNewsletter",
  CommercialSolarNewsletter: "commercialSolarNewsletter",
  JobNewsletter: "jobNewsletter",
} as const;

export type EmailAddressSubscriptionsEnum =
  (typeof EmailAddressSubscriptionsEnum)[keyof typeof EmailAddressSubscriptionsEnum];

/**
 *
 * @export
 * @interface Energy
 */
export interface Energy {
  /**
   *
   * @type {number}
   * @memberof Energy
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof Energy
   */
  unit: EnergyUnitEnum;
}

export const EnergyUnitEnum = {
  KWh: "kWh",
  M3: "m3",
} as const;

export type EnergyUnitEnum = (typeof EnergyUnitEnum)[keyof typeof EnergyUnitEnum];

/**
 *
 * @export
 * @interface EnergyPrice
 */
export interface EnergyPrice {
  /**
   *
   * @type {Price}
   * @memberof EnergyPrice
   */
  basePrice: Price;
  /**
   *
   * @type {Price}
   * @memberof EnergyPrice
   */
  pricePerUnit: Price;
}
/**
 *
 * @export
 * @enum {string}
 */

export const EntityType = {
  Private: "private",
  Company: "company",
  Ngo: "ngo",
} as const;

export type EntityType = (typeof EntityType)[keyof typeof EntityType];

/**
 *
 * @export
 * @interface FixPrice
 */
export interface FixPrice {
  /**
   * The net base price to be paid per year within this range.
   * @type {Price}
   * @memberof FixPrice
   */
  basePrice: Price;
  /**
   * The net price to pay per unit on top of the base price.
   * @type {Price}
   * @memberof FixPrice
   */
  pricePerUnit: Price;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ForecastBasis = {
  Slp: "slp",
  Rlm: "rlm",
} as const;

export type ForecastBasis = (typeof ForecastBasis)[keyof typeof ForecastBasis];

/**
 * Content of an error response due to failed request validation.
 * @export
 * @interface GeneralValidationFailedProblem
 */
export interface GeneralValidationFailedProblem {
  /**
   * The type of the problem as a (relative) URI.
   * @type {string}
   * @memberof GeneralValidationFailedProblem
   */
  type: string;
  /**
   * A summary of the problem. This should be the same for problems of the same type.
   * @type {string}
   * @memberof GeneralValidationFailedProblem
   */
  title: string;
  /**
   * The code of the error that caused the request to fail.
   * @type {number}
   * @memberof GeneralValidationFailedProblem
   */
  status: number;
  /**
   * A detailed, human-readable description of the problem. This may differ between problems of the same type.
   * @type {string}
   * @memberof GeneralValidationFailedProblem
   */
  details?: string;
  /**
   * Dictionary containing validation errors.
   * @type {{ [key: string]: string; }}
   * @memberof GeneralValidationFailedProblem
   */
  validationErrors?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface GetCities200Response
 */
export interface GetCities200Response {
  /**
   *
   * @type {Array<string>}
   * @memberof GetCities200Response
   */
  items: Array<string>;
}
/**
 *
 * @export
 * @interface GetSuppliers200Response
 */
export interface GetSuppliers200Response {
  /**
   *
   * @type {Array<Supplier>}
   * @memberof GetSuppliers200Response
   */
  items: Array<Supplier>;
}
/**
 *
 * @export
 * @interface GetTariffs200Response
 */
export interface GetTariffs200Response {
  /**
   *
   * @type {Array<TariffForConsumption>}
   * @memberof GetTariffs200Response
   */
  items: Array<TariffForConsumption>;
}
/**
 *
 * @export
 * @interface GetTariffsById200Response
 */
export interface GetTariffsById200Response {
  /**
   *
   * @type {Array<PublicTariffWithSummarizedPriceRanges>}
   * @memberof GetTariffsById200Response
   */
  items: Array<PublicTariffWithSummarizedPriceRanges>;
}
/**
 * Content of a failed tariff validation error response due invalidRequestedBeginAt for the tariff.
 * @export
 * @interface InvalidRequestedBeginValidationProblem
 */
export interface InvalidRequestedBeginValidationProblem {
  /**
   * The type of the problem as a (relative) URI.
   * @type {string}
   * @memberof InvalidRequestedBeginValidationProblem
   */
  type: string;
  /**
   * A summary of the problem. This should be the same for problems of the same type.
   * @type {string}
   * @memberof InvalidRequestedBeginValidationProblem
   */
  title: string;
  /**
   * The code of the error that caused the request to fail.
   * @type {number}
   * @memberof InvalidRequestedBeginValidationProblem
   */
  status: number;
  /**
   * A detailed, human-readable description of the problem. This may differ between problems of the same type.
   * @type {string}
   * @memberof InvalidRequestedBeginValidationProblem
   */
  details?: string;
  /**
   * Dictionary containing validation errors.
   * @type {{ [key: string]: string; }}
   * @memberof InvalidRequestedBeginValidationProblem
   */
  validationErrors?: { [key: string]: string };
  /**
   *
   * @type {InvalidRequestedBeginValidationProblemAllOfInvalidPayload}
   * @memberof InvalidRequestedBeginValidationProblem
   */
  invalidPayload?: InvalidRequestedBeginValidationProblemAllOfInvalidPayload;
}
/**
 * Additional information about the tariff validation error.
 * @export
 * @interface InvalidRequestedBeginValidationProblemAllOfInvalidPayload
 */
export interface InvalidRequestedBeginValidationProblemAllOfInvalidPayload {
  /**
   *
   * @type {string}
   * @memberof InvalidRequestedBeginValidationProblemAllOfInvalidPayload
   */
  requestedBeginAt: string;
  /**
   *
   * @type {string}
   * @memberof InvalidRequestedBeginValidationProblemAllOfInvalidPayload
   */
  tariffIsValidFrom: string;
  /**
   *
   * @type {string}
   * @memberof InvalidRequestedBeginValidationProblemAllOfInvalidPayload
   */
  tariffIsValidTo?: string;
}
/**
 * Content of a failed tariff validation error response due to provided invalid zipcode.
 * @export
 * @interface InvalidZipCodeValidationProblem
 */
export interface InvalidZipCodeValidationProblem {
  /**
   * The type of the problem as a (relative) URI.
   * @type {string}
   * @memberof InvalidZipCodeValidationProblem
   */
  type: string;
  /**
   * A summary of the problem. This should be the same for problems of the same type.
   * @type {string}
   * @memberof InvalidZipCodeValidationProblem
   */
  title: string;
  /**
   * The code of the error that caused the request to fail.
   * @type {number}
   * @memberof InvalidZipCodeValidationProblem
   */
  status: number;
  /**
   * A detailed, human-readable description of the problem. This may differ between problems of the same type.
   * @type {string}
   * @memberof InvalidZipCodeValidationProblem
   */
  details?: string;
  /**
   * Dictionary containing validation errors.
   * @type {{ [key: string]: string; }}
   * @memberof InvalidZipCodeValidationProblem
   */
  validationErrors?: { [key: string]: string };
  /**
   *
   * @type {InvalidZipCodeValidationProblemAllOfInvalidPayload}
   * @memberof InvalidZipCodeValidationProblem
   */
  invalidPayload?: InvalidZipCodeValidationProblemAllOfInvalidPayload;
}
/**
 * Additional information about the tariff validation error.
 * @export
 * @interface InvalidZipCodeValidationProblemAllOfInvalidPayload
 */
export interface InvalidZipCodeValidationProblemAllOfInvalidPayload {
  /**
   *
   * @type {string}
   * @memberof InvalidZipCodeValidationProblemAllOfInvalidPayload
   */
  zipCode: string;
}
/**
 *
 * @export
 * @interface LetterBoxMessage
 */
export interface LetterBoxMessage {
  /**
   *
   * @type {string}
   * @memberof LetterBoxMessage
   */
  content: string;
}
/**
 *  networkOperator = power grid operator  supplier = Polarstern
 * @export
 * @enum {string}
 */

export const MeterReadingType = {
  Customer: "customer",
  NetworkOperator: "networkOperator",
  NetworkOperatorEstimate: "networkOperatorEstimate",
  Forecast: "forecast",
  Supplier: "supplier",
} as const;

export type MeterReadingType = (typeof MeterReadingType)[keyof typeof MeterReadingType];

/**
 *
 * @export
 * @interface NetGrossPrice
 */
export interface NetGrossPrice {
  /**
   * The gross price to be paid.
   * @type {EnergyPrice}
   * @memberof NetGrossPrice
   */
  grossPrice: EnergyPrice;
  /**
   * The net price to be paid.
   * @type {EnergyPrice}
   * @memberof NetGrossPrice
   */
  netPrice: EnergyPrice;
}
/**
 *
 * @export
 * @enum {string}
 */

export const OrderReason = {
  NewMeter: "newMeter",
  Move: "move",
  SupplierChange: "supplierChange",
  FromDefaultService: "fromDefaultService",
} as const;

export type OrderReason = (typeof OrderReason)[keyof typeof OrderReason];

/**
 * @type PersonalData
 * @export
 */
export type PersonalData = PersonalDataOneOf | PersonalDataOneOf1;

/**
 *
 * @export
 * @interface PersonalDataOneOf
 */
export interface PersonalDataOneOf {
  /**
   *
   * @type {string}
   * @memberof PersonalDataOneOf
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataOneOf
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataOneOf
   */
  dateOfBirth: string;
}
/**
 *
 * @export
 * @interface PersonalDataOneOf1
 */
export interface PersonalDataOneOf1 {
  /**
   *
   * @type {string}
   * @memberof PersonalDataOneOf1
   */
  companyName: string;
  /**
   *
   * @type {string}
   * @memberof PersonalDataOneOf1
   */
  careOf?: string;
}
/**
 * @type PersonalDataWithMinimalValues
 * @export
 */
export type PersonalDataWithMinimalValues = CompanyPersonalData | PrivatePersonalData;

/**
 *
 * @export
 * @interface PhoneAvailability
 */
export interface PhoneAvailability {
  /**
   *
   * @type {string}
   * @memberof PhoneAvailability
   */
  phoneAvailabilityDate: string;
  /**
   *
   * @type {string}
   * @memberof PhoneAvailability
   */
  phoneAvailabilityTime: string;
}
/**
 *
 * @export
 * @interface PhoneNumber
 */
export interface PhoneNumber {
  /**
   *
   * @type {string}
   * @memberof PhoneNumber
   */
  value: string;
  /**
   * This flag indicates if the person also wants to be contacted via SMS.
   * @type {boolean}
   * @memberof PhoneNumber
   */
  isSmsAllowed?: boolean;
}
/**
 *
 * @export
 * @interface PlainEmailAddress
 */
export interface PlainEmailAddress {
  /**
   *
   * @type {string}
   * @memberof PlainEmailAddress
   */
  value: string;
}
/**
 *
 * @export
 * @interface PreviousContract
 */
export interface PreviousContract {
  /**
   * Date at which the previous contract was or will be terminated. If this is not provided on contract creation, Polarstern needs to terminate the contract on behalf of the customer.
   * @type {string}
   * @memberof PreviousContract
   */
  terminatedAt?: string;
  /**
   * If the previous contract was a Polarstern contract, its ID can be specified here to replace it with the new contract.
   * @type {string}
   * @memberof PreviousContract
   */
  id?: string;
  /**
   * Name of the supplier with whom the previous contract was concluded.
   * @type {string}
   * @memberof PreviousContract
   */
  previousSupplierName: string;
}
/**
 *
 * @export
 * @interface Price
 */
export interface Price {
  /**
   * The price\'s currency as a ISO 4217 alphabetic code.
   * @type {string}
   * @memberof Price
   */
  currencyCode: string;
  /**
   * The actual price value.
   * @type {string}
   * @memberof Price
   */
  value: string;
}
/**
 *
 * @export
 * @interface PrivatePersonalData
 */
export interface PrivatePersonalData {
  /**
   *
   * @type {string}
   * @memberof PrivatePersonalData
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof PrivatePersonalData
   */
  lastName: string;
}
/**
 * Content of an error response, based on https://datatracker.ietf.org/doc/html/rfc7807.
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
  /**
   * The type of the problem as a (relative) URI.
   * @type {string}
   * @memberof ProblemDetails
   */
  type: string;
  /**
   * A summary of the problem. This should be the same for problems of the same type.
   * @type {string}
   * @memberof ProblemDetails
   */
  title: string;
  /**
   * The code of the error that caused the request to fail.
   * @type {number}
   * @memberof ProblemDetails
   */
  status: number;
  /**
   * A detailed, human-readable description of the problem. This may differ between problems of the same type.
   * @type {string}
   * @memberof ProblemDetails
   */
  details?: string;
}
/**
 *
 * @export
 * @interface PublicCode
 */
export interface PublicCode {
  /**
   *
   * @type {string}
   * @memberof PublicCode
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PublicCode
   */
  name: string;
  /**
   * The amount of money the customer gets for using this code.
   * @type {Price}
   * @memberof PublicCode
   */
  payout?: Price;
  /**
   * The amount of money that\'s donated for using this code.
   * @type {Price}
   * @memberof PublicCode
   */
  donation?: Price;
}
/**
 *
 * @export
 * @interface PublicTariff
 */
export interface PublicTariff {
  /**
   * The tariff\'s ID.
   * @type {string}
   * @memberof PublicTariff
   */
  id: string;
  /**
   *
   * @type {TariffType}
   * @memberof PublicTariff
   */
  type: TariffType;
  /**
   * The tariff\'s sub type, used to narrowing down tariffs.
   * @type {string}
   * @memberof PublicTariff
   */
  subType: PublicTariffSubTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PublicTariff
   */
  runtimeType: PublicTariffRuntimeTypeEnum;
  /**
   * Whether this is a key account tariff.
   * @type {boolean}
   * @memberof PublicTariff
   */
  isKeyAccountTariff: boolean;
  /**
   * The human-readable name of the tariff.
   * @type {string}
   * @memberof PublicTariff
   */
  name: string;
  /**
   * The ZIP codes of the areas the tariff is valid in.
   * @type {Array<string>}
   * @memberof PublicTariff
   */
  zipCodes: Array<string>;
  /**
   * If provided specifies the date until which the price guarantee is valid.
   * @type {string}
   * @memberof PublicTariff
   */
  priceGuaranteedUntil?: string;
  /**
   * If provided specifies the duration for which the price guarantee is valid, currently only applicable for tariffs with the comfort term.
   * @type {number}
   * @memberof PublicTariff
   */
  priceGuaranteeDurationInMonths?: number;
  /**
   * The [value-added tax](https://en.wikipedia.org/wiki/Value-added_tax) (VAT) to apply for this tariff.
   * @type {number}
   * @memberof PublicTariff
   */
  valueAddedTax?: number;
  /**
   * The id of the partner which is only set if this is a partner tariff.
   * @type {string}
   * @memberof PublicTariff
   */
  partnerId?: string;
}

export const PublicTariffSubTypeEnum = {
  Classic: "classic",
  ClassicFlex: "classicFlex",
  Own: "own",
  Double: "double",
  HeatPump: "heatPump",
  NightStorageHeating: "nightStorageHeating",
  TenantPower: "tenantPower",
  TenantPowerShadow: "tenantPowerShadow",
  CarPower: "carPower",
  CarPowerPlus: "carPowerPlus",
  IndustrySolar: "industrySolar",
  ClassicFlexPlus: "classicFlexPlus",
  Geg: "geg",
  Vegan: "vegan",
} as const;

export type PublicTariffSubTypeEnum =
  (typeof PublicTariffSubTypeEnum)[keyof typeof PublicTariffSubTypeEnum];
export const PublicTariffRuntimeTypeEnum = {
  Standard: "standard",
  Comfort: "comfort",
} as const;

export type PublicTariffRuntimeTypeEnum =
  (typeof PublicTariffRuntimeTypeEnum)[keyof typeof PublicTariffRuntimeTypeEnum];

/**
 *
 * @export
 * @interface PublicTariffWithSummarizedPriceRanges
 */
export interface PublicTariffWithSummarizedPriceRanges {
  /**
   * The prices to apply based on the customer\'s annual consumption.
   * @type {Array<TariffPriceRange>}
   * @memberof PublicTariffWithSummarizedPriceRanges
   */
  priceRanges: Array<TariffPriceRange>;
  /**
   * The prices to apply for low tariffs based on the customer\'s annual consumption.
   * @type {Array<TariffPriceRange>}
   * @memberof PublicTariffWithSummarizedPriceRanges
   */
  lowTariffPriceRanges?: Array<TariffPriceRange>;
  /**
   * The tariff\'s ID.
   * @type {string}
   * @memberof PublicTariffWithSummarizedPriceRanges
   */
  id: string;
  /**
   *
   * @type {TariffType}
   * @memberof PublicTariffWithSummarizedPriceRanges
   */
  type: TariffType;
  /**
   * The tariff\'s sub type, used to narrowing down tariffs.
   * @type {string}
   * @memberof PublicTariffWithSummarizedPriceRanges
   */
  subType: PublicTariffWithSummarizedPriceRangesSubTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PublicTariffWithSummarizedPriceRanges
   */
  runtimeType: PublicTariffWithSummarizedPriceRangesRuntimeTypeEnum;
  /**
   * Whether this is a key account tariff.
   * @type {boolean}
   * @memberof PublicTariffWithSummarizedPriceRanges
   */
  isKeyAccountTariff: boolean;
  /**
   * The human-readable name of the tariff.
   * @type {string}
   * @memberof PublicTariffWithSummarizedPriceRanges
   */
  name: string;
  /**
   * The ZIP codes of the areas the tariff is valid in.
   * @type {Array<string>}
   * @memberof PublicTariffWithSummarizedPriceRanges
   */
  zipCodes: Array<string>;
  /**
   * If provided specifies the date until which the price guarantee is valid.
   * @type {string}
   * @memberof PublicTariffWithSummarizedPriceRanges
   */
  priceGuaranteedUntil?: string;
  /**
   * If provided specifies the duration for which the price guarantee is valid, currently only applicable for tariffs with the comfort term.
   * @type {number}
   * @memberof PublicTariffWithSummarizedPriceRanges
   */
  priceGuaranteeDurationInMonths?: number;
  /**
   * The [value-added tax](https://en.wikipedia.org/wiki/Value-added_tax) (VAT) to apply for this tariff.
   * @type {number}
   * @memberof PublicTariffWithSummarizedPriceRanges
   */
  valueAddedTax?: number;
  /**
   * The id of the partner which is only set if this is a partner tariff.
   * @type {string}
   * @memberof PublicTariffWithSummarizedPriceRanges
   */
  partnerId?: string;
}

export const PublicTariffWithSummarizedPriceRangesSubTypeEnum = {
  Classic: "classic",
  ClassicFlex: "classicFlex",
  Own: "own",
  Double: "double",
  HeatPump: "heatPump",
  NightStorageHeating: "nightStorageHeating",
  TenantPower: "tenantPower",
  TenantPowerShadow: "tenantPowerShadow",
  CarPower: "carPower",
  CarPowerPlus: "carPowerPlus",
  IndustrySolar: "industrySolar",
  ClassicFlexPlus: "classicFlexPlus",
  Geg: "geg",
  Vegan: "vegan",
} as const;

export type PublicTariffWithSummarizedPriceRangesSubTypeEnum =
  (typeof PublicTariffWithSummarizedPriceRangesSubTypeEnum)[keyof typeof PublicTariffWithSummarizedPriceRangesSubTypeEnum];
export const PublicTariffWithSummarizedPriceRangesRuntimeTypeEnum = {
  Standard: "standard",
  Comfort: "comfort",
} as const;

export type PublicTariffWithSummarizedPriceRangesRuntimeTypeEnum =
  (typeof PublicTariffWithSummarizedPriceRangesRuntimeTypeEnum)[keyof typeof PublicTariffWithSummarizedPriceRangesRuntimeTypeEnum];

/**
 *
 * @export
 * @interface ReadonlyConfirmedSupply
 */
export interface ReadonlyConfirmedSupply {
  /**
   * Date of requested supply of a customer. The value is required for the creation of a tenant power contract.
   * @type {string}
   * @memberof ReadonlyConfirmedSupply
   */
  requestedBeginAt?: string;
  /**
   * Supply address. With exception of MaLo, it is also used as billing address, as long as no separate billing address is provided.
   * @type {Address}
   * @memberof ReadonlyConfirmedSupply
   */
  address?: Address;
  /**
   *
   * @type {any}
   * @memberof ReadonlyConfirmedSupply
   */
  confirmedBeginAt?: any;
  /**
   *
   * @type {any}
   * @memberof ReadonlyConfirmedSupply
   */
  confirmedAt?: any;
}
/**
 * A recommendation from one user to another.
 * @export
 * @interface Recommendation
 */
export interface Recommendation {
  /**
   *
   * @type {string}
   * @memberof Recommendation
   */
  channel: RecommendationChannelEnum;
  /**
   * The recommendation code the receiver should use when signing up at Polarstern.
   * @type {string}
   * @memberof Recommendation
   */
  code: string;
  /**
   * The referrer\'s first name. Must be given together with last name.
   * @type {string}
   * @memberof Recommendation
   */
  firstName?: string;
  /**
   * The referrer\'s last name. Must be given together with first name.
   * @type {string}
   * @memberof Recommendation
   */
  lastName?: string;
  /**
   * The referrer\'s email address. If a customer with this email address exists, the recommendation will be associated with it.
   * @type {string}
   * @memberof Recommendation
   */
  emailAddress: string;
}

export const RecommendationChannelEnum = {
  Email: "email",
  Link: "link",
  WhatsApp: "whatsApp",
  Sms: "sms",
} as const;

export type RecommendationChannelEnum =
  (typeof RecommendationChannelEnum)[keyof typeof RecommendationChannelEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const Role = {
  Customer: "customer",
  Admin: "admin",
  External: "external",
} as const;

export type Role = (typeof Role)[keyof typeof Role];

/**
 *
 * @export
 * @interface SelfConsumptionMessage
 */
export interface SelfConsumptionMessage {
  /**
   *
   * @type {string}
   * @memberof SelfConsumptionMessage
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof SelfConsumptionMessage
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof SelfConsumptionMessage
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof SelfConsumptionMessage
   */
  emailAddress: string;
  /**
   *
   * @type {string}
   * @memberof SelfConsumptionMessage
   */
  content: string;
  /**
   *
   * @type {string}
   * @memberof SelfConsumptionMessage
   */
  zipCode: string;
  /**
   *
   * @type {string}
   * @memberof SelfConsumptionMessage
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof SelfConsumptionMessage
   */
  remainingPowerConsumption: string;
}
/**
 *
 * @export
 * @interface SepaMandate
 */
export interface SepaMandate {
  /**
   * The date and time at which the customer accepted the SEPA mandate.
   * @type {string}
   * @memberof SepaMandate
   */
  acceptedAt: string;
  /**
   *
   * @type {any}
   * @memberof SepaMandate
   */
  reference?: any;
}
/**
 *
 * @export
 * @interface ShirtOrder
 */
export interface ShirtOrder {
  /**
   *
   * @type {string}
   * @memberof ShirtOrder
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof ShirtOrder
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof ShirtOrder
   */
  customerId?: string;
  /**
   *
   * @type {string}
   * @memberof ShirtOrder
   */
  emailAddress: string;
  /**
   *
   * @type {string}
   * @memberof ShirtOrder
   */
  companyName?: string;
  /**
   *
   * @type {BaseAddress}
   * @memberof ShirtOrder
   */
  address: BaseAddress;
  /**
   *
   * @type {string}
   * @memberof ShirtOrder
   */
  size: ShirtOrderSizeEnum;
  /**
   *
   * @type {string}
   * @memberof ShirtOrder
   */
  shirt: ShirtOrderShirtEnum;
  /**
   *
   * @type {string}
   * @memberof ShirtOrder
   */
  message?: string;
}

export const ShirtOrderSizeEnum = {
  Xxs: "XXS",
  Xs: "XS",
  S: "S",
  M: "M",
  L: "L",
  Xl: "XL",
  Xxl: "XXL",
} as const;

export type ShirtOrderSizeEnum =
  (typeof ShirtOrderSizeEnum)[keyof typeof ShirtOrderSizeEnum];
export const ShirtOrderShirtEnum = {
  Sweatshirt: "sweatshirt",
  Tshirt: "tshirt",
} as const;

export type ShirtOrderShirtEnum =
  (typeof ShirtOrderShirtEnum)[keyof typeof ShirtOrderShirtEnum];

/**
 *
 * @export
 * @interface SolarForBusinessMessage
 */
export interface SolarForBusinessMessage {
  /**
   *
   * @type {string}
   * @memberof SolarForBusinessMessage
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof SolarForBusinessMessage
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof SolarForBusinessMessage
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof SolarForBusinessMessage
   */
  emailAddress: string;
  /**
   *
   * @type {string}
   * @memberof SolarForBusinessMessage
   */
  content: string;
  /**
   *
   * @type {string}
   * @memberof SolarForBusinessMessage
   */
  companyName?: string;
  /**
   *
   * @type {string}
   * @memberof SolarForBusinessMessage
   */
  roofSize: string;
  /**
   *
   * @type {string}
   * @memberof SolarForBusinessMessage
   */
  location: string;
  /**
   *
   * @type {string}
   * @memberof SolarForBusinessMessage
   */
  plannedConstructionYear: string;
  /**
   *
   * @type {string}
   * @memberof SolarForBusinessMessage
   */
  streetWithHouseNumber: string;
  /**
   *
   * @type {string}
   * @memberof SolarForBusinessMessage
   */
  numberOfTenants: string;
}
/**
 *
 * @export
 * @interface Supplier
 */
export interface Supplier {
  /**
   *
   * @type {SupplierType}
   * @memberof Supplier
   */
  type: SupplierType;
  /**
   * The name of the supplier.
   * @type {string}
   * @memberof Supplier
   */
  name: string;
}

/**
 * Supplier energy type.
 * @export
 * @enum {string}
 */

export const SupplierType = {
  Power: "power",
  Gas: "gas",
} as const;

export type SupplierType = (typeof SupplierType)[keyof typeof SupplierType];

/**
 *
 * @export
 * @interface TariffForConsumption
 */
export interface TariffForConsumption {
  /**
   * The prices to apply based on the customer\'s annual consumption.
   * @type {Array<TariffPriceRange>}
   * @memberof TariffForConsumption
   */
  priceRanges: Array<TariffPriceRange>;
  /**
   * The prices to apply for low tariffs based on the customer\'s annual consumption.
   * @type {Array<TariffPriceRange>}
   * @memberof TariffForConsumption
   */
  lowTariffPriceRanges?: Array<TariffPriceRange>;
  /**
   * The tariff\'s ID.
   * @type {string}
   * @memberof TariffForConsumption
   */
  id: string;
  /**
   *
   * @type {TariffType}
   * @memberof TariffForConsumption
   */
  type: TariffType;
  /**
   * The tariff\'s sub type, used to narrowing down tariffs.
   * @type {string}
   * @memberof TariffForConsumption
   */
  subType: TariffForConsumptionSubTypeEnum;
  /**
   *
   * @type {string}
   * @memberof TariffForConsumption
   */
  runtimeType: TariffForConsumptionRuntimeTypeEnum;
  /**
   * Whether this is a key account tariff.
   * @type {boolean}
   * @memberof TariffForConsumption
   */
  isKeyAccountTariff: boolean;
  /**
   * The human-readable name of the tariff.
   * @type {string}
   * @memberof TariffForConsumption
   */
  name: string;
  /**
   * The ZIP codes of the areas the tariff is valid in.
   * @type {Array<string>}
   * @memberof TariffForConsumption
   */
  zipCodes: Array<string>;
  /**
   * If provided specifies the date until which the price guarantee is valid.
   * @type {string}
   * @memberof TariffForConsumption
   */
  priceGuaranteedUntil?: string;
  /**
   * If provided specifies the duration for which the price guarantee is valid, currently only applicable for tariffs with the comfort term.
   * @type {number}
   * @memberof TariffForConsumption
   */
  priceGuaranteeDurationInMonths?: number;
  /**
   * The [value-added tax](https://en.wikipedia.org/wiki/Value-added_tax) (VAT) to apply for this tariff.
   * @type {number}
   * @memberof TariffForConsumption
   */
  valueAddedTax?: number;
  /**
   * The id of the partner which is only set if this is a partner tariff.
   * @type {string}
   * @memberof TariffForConsumption
   */
  partnerId?: string;
  /**
   *
   * @type {TariffForConsumptionAllOfAdvancePayment}
   * @memberof TariffForConsumption
   */
  advancePayment: TariffForConsumptionAllOfAdvancePayment;
  /**
   *
   * @type {NetGrossPrice}
   * @memberof TariffForConsumption
   */
  prices: NetGrossPrice;
  /**
   *
   * @type {NetGrossPrice}
   * @memberof TariffForConsumption
   */
  lowTariffPrices?: NetGrossPrice;
}

export const TariffForConsumptionSubTypeEnum = {
  Classic: "classic",
  ClassicFlex: "classicFlex",
  Own: "own",
  Double: "double",
  HeatPump: "heatPump",
  NightStorageHeating: "nightStorageHeating",
  TenantPower: "tenantPower",
  TenantPowerShadow: "tenantPowerShadow",
  CarPower: "carPower",
  CarPowerPlus: "carPowerPlus",
  IndustrySolar: "industrySolar",
  ClassicFlexPlus: "classicFlexPlus",
  Geg: "geg",
  Vegan: "vegan",
} as const;

export type TariffForConsumptionSubTypeEnum =
  (typeof TariffForConsumptionSubTypeEnum)[keyof typeof TariffForConsumptionSubTypeEnum];
export const TariffForConsumptionRuntimeTypeEnum = {
  Standard: "standard",
  Comfort: "comfort",
} as const;

export type TariffForConsumptionRuntimeTypeEnum =
  (typeof TariffForConsumptionRuntimeTypeEnum)[keyof typeof TariffForConsumptionRuntimeTypeEnum];

/**
 * The advance payment to be paid per month.
 * @export
 * @interface TariffForConsumptionAllOfAdvancePayment
 */
export interface TariffForConsumptionAllOfAdvancePayment {
  /**
   *
   * @type {Price}
   * @memberof TariffForConsumptionAllOfAdvancePayment
   */
  grossPrice: Price;
  /**
   *
   * @type {Price}
   * @memberof TariffForConsumptionAllOfAdvancePayment
   */
  netPrice: Price;
}
/**
 *
 * @export
 * @interface TariffHistoryInner
 */
export interface TariffHistoryInner {
  /**
   * The ID of the history entry.
   * @type {string}
   * @memberof TariffHistoryInner
   */
  id?: string;
  /**
   * The ID of the tariff.
   * @type {string}
   * @memberof TariffHistoryInner
   */
  tariffId: string;
  /**
   * The confirmed date from which the contract was, is or will be supplied through this tariff.
   * @type {string}
   * @memberof TariffHistoryInner
   */
  validFrom?: string;
  /**
   * The earliest date at which this contract can be terminated, given this history entry is the current one. If not set, the contract can be terminated any time.
   * @type {string}
   * @memberof TariffHistoryInner
   */
  terminableFrom?: string;
}
/**
 *
 * @export
 * @interface TariffPriceRange
 */
export interface TariffPriceRange {
  /**
   * Lower (inclusive) bound of the range.
   * @type {Energy}
   * @memberof TariffPriceRange
   */
  from: Energy;
  /**
   * Upper (inclusive) bound of the range.
   * @type {Energy}
   * @memberof TariffPriceRange
   */
  to: Energy;
  /**
   * The net base price to be paid per year within this range.
   * @type {Price}
   * @memberof TariffPriceRange
   */
  basePrice: Price;
  /**
   * The net price to pay per unit on top of the base price.
   * @type {Price}
   * @memberof TariffPriceRange
   */
  pricePerUnit: Price;
}
/**
 * Tariff base type, further classified by its sub type.
 * @export
 * @enum {string}
 */

export const TariffType = {
  Power: "power",
  Gas: "gas",
} as const;

export type TariffType = (typeof TariffType)[keyof typeof TariffType];

/**
 *
 * @export
 * @enum {string}
 */

export const TenantPowerContractType = {
  TenantPower: "tenantPower",
  TenantPowerShadow: "tenantPowerShadow",
} as const;

export type TenantPowerContractType =
  (typeof TenantPowerContractType)[keyof typeof TenantPowerContractType];

/**
 *
 * @export
 * @interface TenantPowerOperatorMessage
 */
export interface TenantPowerOperatorMessage {
  /**
   *
   * @type {string}
   * @memberof TenantPowerOperatorMessage
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof TenantPowerOperatorMessage
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof TenantPowerOperatorMessage
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof TenantPowerOperatorMessage
   */
  emailAddress: string;
  /**
   *
   * @type {string}
   * @memberof TenantPowerOperatorMessage
   */
  content: string;
  /**
   *
   * @type {string}
   * @memberof TenantPowerOperatorMessage
   */
  background?: string;
  /**
   *
   * @type {PhoneAvailability}
   * @memberof TenantPowerOperatorMessage
   */
  phoneAvailability?: PhoneAvailability;
}
/**
 *
 * @export
 * @interface TenantPowerPartialContract
 */
export interface TenantPowerPartialContract {
  /**
   *
   * @type {TenantPowerPartialContractSupply}
   * @memberof TenantPowerPartialContract
   */
  supply: TenantPowerPartialContractSupply;
  /**
   * Meter readings associated with this contract.
   * @type {Array<DeprecatedMeterReading>}
   * @memberof TenantPowerPartialContract
   */
  meterReadings: Array<DeprecatedMeterReading>;
  /**
   * The tariff id of the contract.
   * @type {string}
   * @memberof TenantPowerPartialContract
   */
  tariffId: string;
  /**
   *
   * @type {TenantPowerContractType}
   * @memberof TenantPowerPartialContract
   */
  type: TenantPowerContractType;
  /**
   *
   * @type {OrderReason}
   * @memberof TenantPowerPartialContract
   */
  orderReason?: OrderReason;
  /**
   * The serial number of the meter. A serial number is NOT unique but duplicates are very rare.
   * @type {string}
   * @memberof TenantPowerPartialContract
   */
  meterSerialNumber: string;
  /**
   * The id of the measuring point found for the given serial number.
   * @type {string}
   * @memberof TenantPowerPartialContract
   */
  measuringPointId?: string;
}

/**
 *
 * @export
 * @interface TenantPowerPartialContractSupply
 */
export interface TenantPowerPartialContractSupply {
  /**
   * The date at which the customer requested to be supplied.
   * @type {string}
   * @memberof TenantPowerPartialContractSupply
   */
  requestedBeginAt?: string;
  /**
   *
   * @type {TenantPowerPartialContractSupplyAddress}
   * @memberof TenantPowerPartialContractSupply
   */
  address: TenantPowerPartialContractSupplyAddress;
}
/**
 *
 * @export
 * @interface TenantPowerPartialContractSupplyAddress
 */
export interface TenantPowerPartialContractSupplyAddress {
  /**
   *
   * @type {string}
   * @memberof TenantPowerPartialContractSupplyAddress
   */
  street: string;
  /**
   *
   * @type {string}
   * @memberof TenantPowerPartialContractSupplyAddress
   */
  houseNumber: string;
  /**
   *
   * @type {string}
   * @memberof TenantPowerPartialContractSupplyAddress
   */
  zipCode: string;
  /**
   *
   * @type {string}
   * @memberof TenantPowerPartialContractSupplyAddress
   */
  city: string;
  /**
   * The additional information for uniquely identifying the measuring location within the building.
   * @type {string}
   * @memberof TenantPowerPartialContractSupplyAddress
   */
  additionalInformation: string;
}
/**
 *
 * @export
 * @interface TenantPowerSignUpMessage
 */
export interface TenantPowerSignUpMessage {
  /**
   *
   * @type {TenantPowerSignUpMessagePersonalData}
   * @memberof TenantPowerSignUpMessage
   */
  personalData: TenantPowerSignUpMessagePersonalData;
  /**
   *
   * @type {string}
   * @memberof TenantPowerSignUpMessage
   */
  emailAddress?: string;
  /**
   *
   * @type {string}
   * @memberof TenantPowerSignUpMessage
   */
  phoneNumber: string;
  /**
   *
   * @type {TenantPowerSignUpMessageAddress}
   * @memberof TenantPowerSignUpMessage
   */
  address: TenantPowerSignUpMessageAddress;
  /**
   *
   * @type {TenantPowerSignUpMessageBillingAddress}
   * @memberof TenantPowerSignUpMessage
   */
  billingAddress?: TenantPowerSignUpMessageBillingAddress;
  /**
   * The serial number of the meter. A serial number is NOT unique but duplicates are very rare.
   * @type {string}
   * @memberof TenantPowerSignUpMessage
   */
  meterSerialNumber: string;
  /**
   *
   * @type {string}
   * @memberof TenantPowerSignUpMessage
   */
  apartmentNumber: string;
  /**
   *
   * @type {string}
   * @memberof TenantPowerSignUpMessage
   */
  previousSupplierName?: string;
  /**
   *
   * @type {TenantPowerSignUpMessageAnnualConsumption}
   * @memberof TenantPowerSignUpMessage
   */
  annualConsumption: TenantPowerSignUpMessageAnnualConsumption;
  /**
   * The date and time at which the customer moved into the flat.
   * @type {string}
   * @memberof TenantPowerSignUpMessage
   */
  movedAt: string;
  /**
   *
   * @type {Energy}
   * @memberof TenantPowerSignUpMessage
   */
  initialMeterReading: Energy;
  /**
   *
   * @type {TenantPowerSignUpMessageBankAccount}
   * @memberof TenantPowerSignUpMessage
   */
  bankAccount?: TenantPowerSignUpMessageBankAccount;
}
/**
 *
 * @export
 * @interface TenantPowerSignUpMessageAddress
 */
export interface TenantPowerSignUpMessageAddress {
  /**
   * This covers all potential additions for addresses like a sub compartment type.
   * @type {string}
   * @memberof TenantPowerSignUpMessageAddress
   */
  additionalInformation?: string;
  /**
   *
   * @type {string}
   * @memberof TenantPowerSignUpMessageAddress
   */
  street: string;
  /**
   *
   * @type {string}
   * @memberof TenantPowerSignUpMessageAddress
   */
  houseNumber: string;
  /**
   *
   * @type {string}
   * @memberof TenantPowerSignUpMessageAddress
   */
  zipCode: string;
  /**
   *
   * @type {string}
   * @memberof TenantPowerSignUpMessageAddress
   */
  city: string;
}
/**
 *
 * @export
 * @interface TenantPowerSignUpMessageAnnualConsumption
 */
export interface TenantPowerSignUpMessageAnnualConsumption {
  /**
   *
   * @type {number}
   * @memberof TenantPowerSignUpMessageAnnualConsumption
   */
  value: number;
  /**
   *
   * @type {string}
   * @memberof TenantPowerSignUpMessageAnnualConsumption
   */
  unit: TenantPowerSignUpMessageAnnualConsumptionUnitEnum;
}

export const TenantPowerSignUpMessageAnnualConsumptionUnitEnum = {
  KWh: "kWh",
  M3: "m3",
} as const;

export type TenantPowerSignUpMessageAnnualConsumptionUnitEnum =
  (typeof TenantPowerSignUpMessageAnnualConsumptionUnitEnum)[keyof typeof TenantPowerSignUpMessageAnnualConsumptionUnitEnum];

/**
 *
 * @export
 * @interface TenantPowerSignUpMessageBankAccount
 */
export interface TenantPowerSignUpMessageBankAccount {
  /**
   *
   * @type {string}
   * @memberof TenantPowerSignUpMessageBankAccount
   */
  iban: string;
  /**
   * The name of the account holder.
   * @type {string}
   * @memberof TenantPowerSignUpMessageBankAccount
   */
  accountHolder: string;
}
/**
 *
 * @export
 * @interface TenantPowerSignUpMessageBillingAddress
 */
export interface TenantPowerSignUpMessageBillingAddress {
  /**
   *
   * @type {string}
   * @memberof TenantPowerSignUpMessageBillingAddress
   */
  street: string;
  /**
   *
   * @type {string}
   * @memberof TenantPowerSignUpMessageBillingAddress
   */
  houseNumber: string;
  /**
   *
   * @type {string}
   * @memberof TenantPowerSignUpMessageBillingAddress
   */
  zipCode: string;
  /**
   *
   * @type {string}
   * @memberof TenantPowerSignUpMessageBillingAddress
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof TenantPowerSignUpMessageBillingAddress
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof TenantPowerSignUpMessageBillingAddress
   */
  lastName: string;
  /**
   * This covers all potential additions for addresses like a sub compartment type.
   * @type {string}
   * @memberof TenantPowerSignUpMessageBillingAddress
   */
  additionalInformation?: string;
}
/**
 *
 * @export
 * @interface TenantPowerSignUpMessagePersonalData
 */
export interface TenantPowerSignUpMessagePersonalData {
  /**
   *
   * @type {string}
   * @memberof TenantPowerSignUpMessagePersonalData
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof TenantPowerSignUpMessagePersonalData
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof TenantPowerSignUpMessagePersonalData
   */
  dateOfBirth: string;
}
/**
 *
 * @export
 * @interface Termination
 */
export interface Termination {
  /**
   * Unique ID of the terminated contract.
   * @type {string}
   * @memberof Termination
   */
  contractId?: string;
  /**
   * Date at which the contract was or will be terminated.
   * @type {string}
   * @memberof Termination
   */
  terminatedAt: string;
  /**
   * If the customer moves to a new address and wants to stay with Polarstern. This is required to decide which document to send to the customer (Kündigungsbestätigung oder Abmeldebestätigung).
   * @type {boolean}
   * @memberof Termination
   */
  movedToNewAddress?: boolean;
  /**
   *
   * @type {BaseTerminationReason}
   * @memberof Termination
   */
  reason: BaseTerminationReason;
  /**
   *
   * @type {string}
   * @memberof Termination
   */
  note?: string;
  /**
   * Date at which the termination was confirmed by the network provider.
   * @type {string}
   * @memberof Termination
   */
  confirmedAt?: string;
  /**
   * Date and time at which the termination was created.
   * @type {string}
   * @memberof Termination
   */
  createdAt: string;
  /**
   *
   * @type {Role}
   * @memberof Termination
   */
  terminatedBy?: Role;
  /**
   *
   * @type {TerminationType}
   * @memberof Termination
   */
  type: TerminationType;
}

/**
 *
 * @export
 * @enum {string}
 */

export const TerminationReason = {
  Price: "price",
  ProviderSwitch: "providerSwitch",
  MoveToExistingContract: "moveToExistingContract",
  MoveAbroad: "moveAbroad",
  Move: "move",
  NoNewApartment: "noNewApartment",
  ProviderRestriction: "providerRestriction",
  MeterRemoval: "meterRemoval",
  Death: "death",
  UnsatisfactoryService: "unsatisfactoryService",
  NoGasConnection: "noGasConnection",
} as const;

export type TerminationReason =
  (typeof TerminationReason)[keyof typeof TerminationReason];

/**
 *
 * @export
 * @interface TerminationRequest
 */
export interface TerminationRequest {
  /**
   * The unique ID of the customer.
   * @type {string}
   * @memberof TerminationRequest
   */
  customerId: string;
  /**
   *
   * @type {string}
   * @memberof TerminationRequest
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof TerminationRequest
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof TerminationRequest
   */
  contractId: string;
  /**
   *
   * @type {string}
   * @memberof TerminationRequest
   */
  emailAddress: string;
  /**
   *
   * @type {string}
   * @memberof TerminationRequest
   */
  terminatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof TerminationRequest
   */
  note?: string;
  /**
   *
   * @type {TerminationType}
   * @memberof TerminationRequest
   */
  type: TerminationType;
  /**
   *
   * @type {BaseTerminationReason}
   * @memberof TerminationRequest
   */
  reason: BaseTerminationReason;
}
/**
 * extraordinary = an extraordinary termination, e.g. due to increased prices  cancellation = a cancellation of a contract that was never active  nonPayment = a termination due to a non paying customer
 * @export
 * @interface TerminationType
 */
export interface TerminationType {}
/**
 *
 * @export
 * @enum {string}
 */

export const TerminationTypeAsAdmin = {
  Extraordinary: "extraordinary",
  Cancellation: "cancellation",
  NonPayment: "nonPayment",
} as const;

export type TerminationTypeAsAdmin =
  (typeof TerminationTypeAsAdmin)[keyof typeof TerminationTypeAsAdmin];

/**
 *
 * @export
 * @enum {string}
 */

export const TerminationTypeAsCustomer = {
  Regular: "regular",
  Move: "move",
  Transfer: "transfer",
} as const;

export type TerminationTypeAsCustomer =
  (typeof TerminationTypeAsCustomer)[keyof typeof TerminationTypeAsCustomer];

/**
 * @type Todo
 * @export
 */
export type Todo = ContractTodo | CustomerTodo;

/**
 *
 * @export
 * @interface Transfer
 */
export interface Transfer {
  /**
   *
   * @type {string}
   * @memberof Transfer
   */
  id: string;
  /**
   * The list of contracts that should be transferred.
   * @type {Array<TransferContract>}
   * @memberof Transfer
   */
  contracts?: Array<TransferContract>;
  /**
   * The date at which the contracts were or will be transferred. The termination date of the previous contract will be transferredAt - 1 day.
   * @type {string}
   * @memberof Transfer
   */
  transferredAt: string;
  /**
   * Name of the sender that originated the transfer. Might be the person\'s full name or a company name.
   * @type {string}
   * @memberof Transfer
   */
  sender: string;
  /**
   *
   * @type {TransferRecipient}
   * @memberof Transfer
   */
  recipient: TransferRecipient;
}
/**
 *
 * @export
 * @interface TransferContract
 */
export interface TransferContract {
  /**
   * ID of the original contract that is being transferred.
   * @type {string}
   * @memberof TransferContract
   */
  id: string;
  /**
   *
   * @type {ContractType}
   * @memberof TransferContract
   */
  type: ContractType;
  /**
   * The serial number of the meter. A serial number is NOT unique but duplicates are very rare.
   * @type {string}
   * @memberof TransferContract
   */
  meterSerialNumber: string;
  /**
   * Supply address of the original contract.
   * @type {Address}
   * @memberof TransferContract
   */
  supplyAddress: Address;
  /**
   * A list of tariffs that are, were or will be used by this contract ordered by its validity starting with the oldest tariff.
   * @type {Array<TariffHistoryInner>}
   * @memberof TransferContract
   */
  tariffHistory: Array<TariffHistoryInner>;
  /**
   * The (possibly estimated) annual consumption of the original contract.
   * @type {Energy}
   * @memberof TransferContract
   */
  annualConsumption?: Energy;
  /**
   * The (possibly estimated) low tariff annual consumption of the original contract. Can only be set for HT/NT-tariffs.
   * @type {Energy}
   * @memberof TransferContract
   */
  annualConsumptionLowTariff?: Energy;
}

/**
 * The recipient to receive the transferred contracts.
 * @export
 * @interface TransferRecipient
 */
export interface TransferRecipient {
  /**
   *
   * @type {EntityType}
   * @memberof TransferRecipient
   */
  type: EntityType;
  /**
   *
   * @type {string}
   * @memberof TransferRecipient
   */
  emailAddress: string;
  /**
   *
   * @type {PersonalDataWithMinimalValues}
   * @memberof TransferRecipient
   */
  personalData: PersonalDataWithMinimalValues;
}

/**
 *
 * @export
 * @interface Unsubscription
 */
export interface Unsubscription {
  /**
   *
   * @type {PlainEmailAddress}
   * @memberof Unsubscription
   */
  emailAddress: PlainEmailAddress;
}

/**
 * BanksApi - axios parameter creator
 * @export
 */
export const BanksApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Gets the bank associated with the specified IBAN.
     * @summary Get bank
     * @param {string} xPolarsternIban The IBAN to get the bank for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBank: async (
      xPolarsternIban: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xPolarsternIban' is not null or undefined
      assertParamExists("getBank", "xPolarsternIban", xPolarsternIban);
      const localVarPath = `/bank`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xPolarsternIban != null) {
        localVarHeaderParameter["X-Polarstern-Iban"] = String(xPolarsternIban);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BanksApi - functional programming interface
 * @export
 */
export const BanksApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BanksApiAxiosParamCreator(configuration);
  return {
    /**
     * Gets the bank associated with the specified IBAN.
     * @summary Get bank
     * @param {string} xPolarsternIban The IBAN to get the bank for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBank(
      xPolarsternIban: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Bank>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBank(
        xPolarsternIban,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["BanksApi.getBank"]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * BanksApi - factory interface
 * @export
 */
export const BanksApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = BanksApiFp(configuration);
  return {
    /**
     * Gets the bank associated with the specified IBAN.
     * @summary Get bank
     * @param {string} xPolarsternIban The IBAN to get the bank for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBank(
      xPolarsternIban: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Bank> {
      return localVarFp
        .getBank(xPolarsternIban, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * BanksApi - object-oriented interface
 * @export
 * @class BanksApi
 * @extends {BaseAPI}
 */
export class BanksApi extends BaseAPI {
  /**
   * Gets the bank associated with the specified IBAN.
   * @summary Get bank
   * @param {string} xPolarsternIban The IBAN to get the bank for.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BanksApi
   */
  public getBank(xPolarsternIban: string, options?: RawAxiosRequestConfig) {
    return BanksApiFp(this.configuration)
      .getBank(xPolarsternIban, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CodesApi - axios parameter creator
 * @export
 */
export const CodesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Gets validity information for a given code.
     * @summary Get Code Validity
     * @param {string} id ID of the code to get.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    codeValidity: async (
      id: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("codeValidity", "id", id);
      const localVarPath = `/codes/validity`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id !== undefined) {
        localVarQueryParameter["id"] = id;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CodesApi - functional programming interface
 * @export
 */
export const CodesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CodesApiAxiosParamCreator(configuration);
  return {
    /**
     * Gets validity information for a given code.
     * @summary Get Code Validity
     * @param {string} id ID of the code to get.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async codeValidity(
      id: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicCode>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.codeValidity(id, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["CodesApi.codeValidity"]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * CodesApi - factory interface
 * @export
 */
export const CodesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CodesApiFp(configuration);
  return {
    /**
     * Gets validity information for a given code.
     * @summary Get Code Validity
     * @param {string} id ID of the code to get.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    codeValidity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<PublicCode> {
      return localVarFp
        .codeValidity(id, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CodesApi - object-oriented interface
 * @export
 * @class CodesApi
 * @extends {BaseAPI}
 */
export class CodesApi extends BaseAPI {
  /**
   * Gets validity information for a given code.
   * @summary Get Code Validity
   * @param {string} id ID of the code to get.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CodesApi
   */
  public codeValidity(id: string, options?: RawAxiosRequestConfig) {
    return CodesApiFp(this.configuration)
      .codeValidity(id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ContactsApi - axios parameter creator
 * @export
 */
export const ContactsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Creates a new contact and returns it.
     * @summary Create contact
     * @param {Contact} [contact] Request containing a contact.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createContact: async (
      contact?: Contact,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/contacts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        contact,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets a contact by its ID.
     * @summary Get contact
     * @param {string} contactId ID of the contact.
     * @param {string} xPolarsternVerificationToken Verification Token used to verify access to a protected resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContact: async (
      contactId: string,
      xPolarsternVerificationToken: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'contactId' is not null or undefined
      assertParamExists("getContact", "contactId", contactId);
      // verify required parameter 'xPolarsternVerificationToken' is not null or undefined
      assertParamExists(
        "getContact",
        "xPolarsternVerificationToken",
        xPolarsternVerificationToken,
      );
      const localVarPath = `/contacts/{contactId}`.replace(
        `{${"contactId"}}`,
        encodeURIComponent(String(contactId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xPolarsternVerificationToken != null) {
        localVarHeaderParameter["X-Polarstern-Verification-Token"] = String(
          xPolarsternVerificationToken,
        );
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ContactsApi - functional programming interface
 * @export
 */
export const ContactsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ContactsApiAxiosParamCreator(configuration);
  return {
    /**
     * Creates a new contact and returns it.
     * @summary Create contact
     * @param {Contact} [contact] Request containing a contact.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createContact(
      contact?: Contact,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createContact(
        contact,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContactsApi.createContact"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Gets a contact by its ID.
     * @summary Get contact
     * @param {string} contactId ID of the contact.
     * @param {string} xPolarsternVerificationToken Verification Token used to verify access to a protected resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContact(
      contactId: string,
      xPolarsternVerificationToken: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getContact(
        contactId,
        xPolarsternVerificationToken,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ContactsApi.getContact"]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * ContactsApi - factory interface
 * @export
 */
export const ContactsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ContactsApiFp(configuration);
  return {
    /**
     * Creates a new contact and returns it.
     * @summary Create contact
     * @param {Contact} [contact] Request containing a contact.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createContact(
      contact?: Contact,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Contact> {
      return localVarFp
        .createContact(contact, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets a contact by its ID.
     * @summary Get contact
     * @param {string} contactId ID of the contact.
     * @param {string} xPolarsternVerificationToken Verification Token used to verify access to a protected resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContact(
      contactId: string,
      xPolarsternVerificationToken: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Contact> {
      return localVarFp
        .getContact(contactId, xPolarsternVerificationToken, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ContactsApi - object-oriented interface
 * @export
 * @class ContactsApi
 * @extends {BaseAPI}
 */
export class ContactsApi extends BaseAPI {
  /**
   * Creates a new contact and returns it.
   * @summary Create contact
   * @param {Contact} [contact] Request containing a contact.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactsApi
   */
  public createContact(contact?: Contact, options?: RawAxiosRequestConfig) {
    return ContactsApiFp(this.configuration)
      .createContact(contact, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets a contact by its ID.
   * @summary Get contact
   * @param {string} contactId ID of the contact.
   * @param {string} xPolarsternVerificationToken Verification Token used to verify access to a protected resource.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactsApi
   */
  public getContact(
    contactId: string,
    xPolarsternVerificationToken: string,
    options?: RawAxiosRequestConfig,
  ) {
    return ContactsApiFp(this.configuration)
      .getContact(contactId, xPolarsternVerificationToken, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CustomersApi - axios parameter creator
 * @export
 */
export const CustomersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Creates a new customer and returns it.
     * @summary Create customer
     * @param {CustomerWithContracts} [customerWithContracts] Request to create a customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCustomer: async (
      customerWithContracts?: CustomerWithContracts,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/customers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        customerWithContracts,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CustomersApi - functional programming interface
 * @export
 */
export const CustomersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CustomersApiAxiosParamCreator(configuration);
  return {
    /**
     * Creates a new customer and returns it.
     * @summary Create customer
     * @param {CustomerWithContracts} [customerWithContracts] Request to create a customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCustomer(
      customerWithContracts?: CustomerWithContracts,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerWithContracts>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomer(
        customerWithContracts,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["CustomersApi.createCustomer"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * CustomersApi - factory interface
 * @export
 */
export const CustomersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CustomersApiFp(configuration);
  return {
    /**
     * Creates a new customer and returns it.
     * @summary Create customer
     * @param {CustomerWithContracts} [customerWithContracts] Request to create a customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCustomer(
      customerWithContracts?: CustomerWithContracts,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<CustomerWithContracts> {
      return localVarFp
        .createCustomer(customerWithContracts, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CustomersApi - object-oriented interface
 * @export
 * @class CustomersApi
 * @extends {BaseAPI}
 */
export class CustomersApi extends BaseAPI {
  /**
   * Creates a new customer and returns it.
   * @summary Create customer
   * @param {CustomerWithContracts} [customerWithContracts] Request to create a customer.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomersApi
   */
  public createCustomer(
    customerWithContracts?: CustomerWithContracts,
    options?: RawAxiosRequestConfig,
  ) {
    return CustomersApiFp(this.configuration)
      .createCustomer(customerWithContracts, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * MessagesApi - axios parameter creator
 * @export
 */
export const MessagesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Sends a charging network message.
     * @summary Send charging network message
     * @param {ChargingNetworkMessage} [chargingNetworkMessage] Request to send a charging network message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postChargingNetworkMessage: async (
      chargingNetworkMessage?: ChargingNetworkMessage,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/messages/charging-network`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        chargingNetworkMessage,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Sends an electric vehicle fleet message.
     * @summary Send electric vehicle fleet message
     * @param {ElectricVehicleFleetMessage} [electricVehicleFleetMessage] Request to send an electric vehicle fleet message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postElectricalVehicleFleetMessage: async (
      electricVehicleFleetMessage?: ElectricVehicleFleetMessage,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/messages/electric-vehicle-fleet`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        electricVehicleFleetMessage,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Sends a letter box message.
     * @summary Send letter box message
     * @param {LetterBoxMessage} [letterBoxMessage] Request to send a letter box message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postLetterBoxMessage: async (
      letterBoxMessage?: LetterBoxMessage,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/messages/letter-box`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        letterBoxMessage,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Sends a contact message.
     * @summary Send message
     * @param {ContactMessage} [contactMessage] Request to send a contact form message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postMessage: async (
      contactMessage?: ContactMessage,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/messages/contact`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        contactMessage,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Sends a self consumption message.
     * @summary Send self consumption message
     * @param {SelfConsumptionMessage} [selfConsumptionMessage] Request to send a self consumption message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postSelfConsumptionMessage: async (
      selfConsumptionMessage?: SelfConsumptionMessage,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/messages/self-consumption`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        selfConsumptionMessage,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Sends a solar for business message.
     * @summary Send solar for business message
     * @param {SolarForBusinessMessage} [solarForBusinessMessage] Request to send a solar for business message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postSolarForBusinessMessage: async (
      solarForBusinessMessage?: SolarForBusinessMessage,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/messages/solar-for-business`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        solarForBusinessMessage,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Sends a termination request.
     * @summary Send termination request
     * @param {TerminationRequest} [terminationRequest] Request to send a termination request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTerminationRequest: async (
      terminationRequest?: TerminationRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/messages/terminations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        terminationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MessagesApi - functional programming interface
 * @export
 */
export const MessagesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MessagesApiAxiosParamCreator(configuration);
  return {
    /**
     * Sends a charging network message.
     * @summary Send charging network message
     * @param {ChargingNetworkMessage} [chargingNetworkMessage] Request to send a charging network message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postChargingNetworkMessage(
      chargingNetworkMessage?: ChargingNetworkMessage,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postChargingNetworkMessage(
          chargingNetworkMessage,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["MessagesApi.postChargingNetworkMessage"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Sends an electric vehicle fleet message.
     * @summary Send electric vehicle fleet message
     * @param {ElectricVehicleFleetMessage} [electricVehicleFleetMessage] Request to send an electric vehicle fleet message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postElectricalVehicleFleetMessage(
      electricVehicleFleetMessage?: ElectricVehicleFleetMessage,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postElectricalVehicleFleetMessage(
          electricVehicleFleetMessage,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["MessagesApi.postElectricalVehicleFleetMessage"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Sends a letter box message.
     * @summary Send letter box message
     * @param {LetterBoxMessage} [letterBoxMessage] Request to send a letter box message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postLetterBoxMessage(
      letterBoxMessage?: LetterBoxMessage,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postLetterBoxMessage(
        letterBoxMessage,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["MessagesApi.postLetterBoxMessage"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Sends a contact message.
     * @summary Send message
     * @param {ContactMessage} [contactMessage] Request to send a contact form message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postMessage(
      contactMessage?: ContactMessage,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postMessage(
        contactMessage,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["MessagesApi.postMessage"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Sends a self consumption message.
     * @summary Send self consumption message
     * @param {SelfConsumptionMessage} [selfConsumptionMessage] Request to send a self consumption message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postSelfConsumptionMessage(
      selfConsumptionMessage?: SelfConsumptionMessage,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postSelfConsumptionMessage(
          selfConsumptionMessage,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["MessagesApi.postSelfConsumptionMessage"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Sends a solar for business message.
     * @summary Send solar for business message
     * @param {SolarForBusinessMessage} [solarForBusinessMessage] Request to send a solar for business message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postSolarForBusinessMessage(
      solarForBusinessMessage?: SolarForBusinessMessage,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postSolarForBusinessMessage(
          solarForBusinessMessage,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["MessagesApi.postSolarForBusinessMessage"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Sends a termination request.
     * @summary Send termination request
     * @param {TerminationRequest} [terminationRequest] Request to send a termination request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postTerminationRequest(
      terminationRequest?: TerminationRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postTerminationRequest(
        terminationRequest,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["MessagesApi.postTerminationRequest"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * MessagesApi - factory interface
 * @export
 */
export const MessagesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = MessagesApiFp(configuration);
  return {
    /**
     * Sends a charging network message.
     * @summary Send charging network message
     * @param {ChargingNetworkMessage} [chargingNetworkMessage] Request to send a charging network message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postChargingNetworkMessage(
      chargingNetworkMessage?: ChargingNetworkMessage,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .postChargingNetworkMessage(chargingNetworkMessage, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Sends an electric vehicle fleet message.
     * @summary Send electric vehicle fleet message
     * @param {ElectricVehicleFleetMessage} [electricVehicleFleetMessage] Request to send an electric vehicle fleet message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postElectricalVehicleFleetMessage(
      electricVehicleFleetMessage?: ElectricVehicleFleetMessage,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .postElectricalVehicleFleetMessage(electricVehicleFleetMessage, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Sends a letter box message.
     * @summary Send letter box message
     * @param {LetterBoxMessage} [letterBoxMessage] Request to send a letter box message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postLetterBoxMessage(
      letterBoxMessage?: LetterBoxMessage,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .postLetterBoxMessage(letterBoxMessage, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Sends a contact message.
     * @summary Send message
     * @param {ContactMessage} [contactMessage] Request to send a contact form message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postMessage(
      contactMessage?: ContactMessage,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .postMessage(contactMessage, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Sends a self consumption message.
     * @summary Send self consumption message
     * @param {SelfConsumptionMessage} [selfConsumptionMessage] Request to send a self consumption message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postSelfConsumptionMessage(
      selfConsumptionMessage?: SelfConsumptionMessage,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .postSelfConsumptionMessage(selfConsumptionMessage, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Sends a solar for business message.
     * @summary Send solar for business message
     * @param {SolarForBusinessMessage} [solarForBusinessMessage] Request to send a solar for business message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postSolarForBusinessMessage(
      solarForBusinessMessage?: SolarForBusinessMessage,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .postSolarForBusinessMessage(solarForBusinessMessage, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Sends a termination request.
     * @summary Send termination request
     * @param {TerminationRequest} [terminationRequest] Request to send a termination request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTerminationRequest(
      terminationRequest?: TerminationRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .postTerminationRequest(terminationRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * MessagesApi - object-oriented interface
 * @export
 * @class MessagesApi
 * @extends {BaseAPI}
 */
export class MessagesApi extends BaseAPI {
  /**
   * Sends a charging network message.
   * @summary Send charging network message
   * @param {ChargingNetworkMessage} [chargingNetworkMessage] Request to send a charging network message.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessagesApi
   */
  public postChargingNetworkMessage(
    chargingNetworkMessage?: ChargingNetworkMessage,
    options?: RawAxiosRequestConfig,
  ) {
    return MessagesApiFp(this.configuration)
      .postChargingNetworkMessage(chargingNetworkMessage, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Sends an electric vehicle fleet message.
   * @summary Send electric vehicle fleet message
   * @param {ElectricVehicleFleetMessage} [electricVehicleFleetMessage] Request to send an electric vehicle fleet message.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessagesApi
   */
  public postElectricalVehicleFleetMessage(
    electricVehicleFleetMessage?: ElectricVehicleFleetMessage,
    options?: RawAxiosRequestConfig,
  ) {
    return MessagesApiFp(this.configuration)
      .postElectricalVehicleFleetMessage(electricVehicleFleetMessage, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Sends a letter box message.
   * @summary Send letter box message
   * @param {LetterBoxMessage} [letterBoxMessage] Request to send a letter box message.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessagesApi
   */
  public postLetterBoxMessage(
    letterBoxMessage?: LetterBoxMessage,
    options?: RawAxiosRequestConfig,
  ) {
    return MessagesApiFp(this.configuration)
      .postLetterBoxMessage(letterBoxMessage, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Sends a contact message.
   * @summary Send message
   * @param {ContactMessage} [contactMessage] Request to send a contact form message.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessagesApi
   */
  public postMessage(contactMessage?: ContactMessage, options?: RawAxiosRequestConfig) {
    return MessagesApiFp(this.configuration)
      .postMessage(contactMessage, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Sends a self consumption message.
   * @summary Send self consumption message
   * @param {SelfConsumptionMessage} [selfConsumptionMessage] Request to send a self consumption message.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessagesApi
   */
  public postSelfConsumptionMessage(
    selfConsumptionMessage?: SelfConsumptionMessage,
    options?: RawAxiosRequestConfig,
  ) {
    return MessagesApiFp(this.configuration)
      .postSelfConsumptionMessage(selfConsumptionMessage, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Sends a solar for business message.
   * @summary Send solar for business message
   * @param {SolarForBusinessMessage} [solarForBusinessMessage] Request to send a solar for business message.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessagesApi
   */
  public postSolarForBusinessMessage(
    solarForBusinessMessage?: SolarForBusinessMessage,
    options?: RawAxiosRequestConfig,
  ) {
    return MessagesApiFp(this.configuration)
      .postSolarForBusinessMessage(solarForBusinessMessage, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Sends a termination request.
   * @summary Send termination request
   * @param {TerminationRequest} [terminationRequest] Request to send a termination request.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessagesApi
   */
  public postTerminationRequest(
    terminationRequest?: TerminationRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return MessagesApiFp(this.configuration)
      .postTerminationRequest(terminationRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * NewsletterApi - axios parameter creator
 * @export
 */
export const NewsletterApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Unsubscribe from all newsletters.
     * @summary Unsubscribe from all newsletters
     * @param {Unsubscription} [unsubscription] Request to unsubscribe from all newsletters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unsubscribeFromAllNewsletters: async (
      unsubscription?: Unsubscription,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/newsletter/unsubscribe`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        unsubscription,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NewsletterApi - functional programming interface
 * @export
 */
export const NewsletterApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = NewsletterApiAxiosParamCreator(configuration);
  return {
    /**
     * Unsubscribe from all newsletters.
     * @summary Unsubscribe from all newsletters
     * @param {Unsubscription} [unsubscription] Request to unsubscribe from all newsletters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unsubscribeFromAllNewsletters(
      unsubscription?: Unsubscription,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.unsubscribeFromAllNewsletters(
          unsubscription,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["NewsletterApi.unsubscribeFromAllNewsletters"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * NewsletterApi - factory interface
 * @export
 */
export const NewsletterApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = NewsletterApiFp(configuration);
  return {
    /**
     * Unsubscribe from all newsletters.
     * @summary Unsubscribe from all newsletters
     * @param {Unsubscription} [unsubscription] Request to unsubscribe from all newsletters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unsubscribeFromAllNewsletters(
      unsubscription?: Unsubscription,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .unsubscribeFromAllNewsletters(unsubscription, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * NewsletterApi - object-oriented interface
 * @export
 * @class NewsletterApi
 * @extends {BaseAPI}
 */
export class NewsletterApi extends BaseAPI {
  /**
   * Unsubscribe from all newsletters.
   * @summary Unsubscribe from all newsletters
   * @param {Unsubscription} [unsubscription] Request to unsubscribe from all newsletters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsletterApi
   */
  public unsubscribeFromAllNewsletters(
    unsubscription?: Unsubscription,
    options?: RawAxiosRequestConfig,
  ) {
    return NewsletterApiFp(this.configuration)
      .unsubscribeFromAllNewsletters(unsubscription, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OrdersApi - axios parameter creator
 * @export
 */
export const OrdersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Sends a charging cards order request.
     * @summary Request charging cards
     * @param {ChargingCardsOrder} [chargingCardsOrder] Request to order charging cards.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postChargingCardsOrderRequest: async (
      chargingCardsOrder?: ChargingCardsOrder,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/orders/charging-cards`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        chargingCardsOrder,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Sends a communication package order request.
     * @summary Request communication package
     * @param {CommunicationPackageOrder} [communicationPackageOrder] Request to order a communication package.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postCommunicationPackageOrderRequest: async (
      communicationPackageOrder?: CommunicationPackageOrder,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/orders/communication-package`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        communicationPackageOrder,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Sends a shirt order request.
     * @summary Request shirt
     * @param {ShirtOrder} [shirtOrder] Request to order a shirt.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postShirtOrderRequest: async (
      shirtOrder?: ShirtOrder,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/orders/shirt`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        shirtOrder,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OrdersApi - functional programming interface
 * @export
 */
export const OrdersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OrdersApiAxiosParamCreator(configuration);
  return {
    /**
     * Sends a charging cards order request.
     * @summary Request charging cards
     * @param {ChargingCardsOrder} [chargingCardsOrder] Request to order charging cards.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postChargingCardsOrderRequest(
      chargingCardsOrder?: ChargingCardsOrder,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postChargingCardsOrderRequest(
          chargingCardsOrder,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrdersApi.postChargingCardsOrderRequest"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Sends a communication package order request.
     * @summary Request communication package
     * @param {CommunicationPackageOrder} [communicationPackageOrder] Request to order a communication package.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postCommunicationPackageOrderRequest(
      communicationPackageOrder?: CommunicationPackageOrder,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postCommunicationPackageOrderRequest(
          communicationPackageOrder,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrdersApi.postCommunicationPackageOrderRequest"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Sends a shirt order request.
     * @summary Request shirt
     * @param {ShirtOrder} [shirtOrder] Request to order a shirt.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postShirtOrderRequest(
      shirtOrder?: ShirtOrder,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postShirtOrderRequest(
        shirtOrder,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrdersApi.postShirtOrderRequest"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * OrdersApi - factory interface
 * @export
 */
export const OrdersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OrdersApiFp(configuration);
  return {
    /**
     * Sends a charging cards order request.
     * @summary Request charging cards
     * @param {ChargingCardsOrder} [chargingCardsOrder] Request to order charging cards.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postChargingCardsOrderRequest(
      chargingCardsOrder?: ChargingCardsOrder,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .postChargingCardsOrderRequest(chargingCardsOrder, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Sends a communication package order request.
     * @summary Request communication package
     * @param {CommunicationPackageOrder} [communicationPackageOrder] Request to order a communication package.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postCommunicationPackageOrderRequest(
      communicationPackageOrder?: CommunicationPackageOrder,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .postCommunicationPackageOrderRequest(communicationPackageOrder, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Sends a shirt order request.
     * @summary Request shirt
     * @param {ShirtOrder} [shirtOrder] Request to order a shirt.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postShirtOrderRequest(
      shirtOrder?: ShirtOrder,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .postShirtOrderRequest(shirtOrder, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
export class OrdersApi extends BaseAPI {
  /**
   * Sends a charging cards order request.
   * @summary Request charging cards
   * @param {ChargingCardsOrder} [chargingCardsOrder] Request to order charging cards.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public postChargingCardsOrderRequest(
    chargingCardsOrder?: ChargingCardsOrder,
    options?: RawAxiosRequestConfig,
  ) {
    return OrdersApiFp(this.configuration)
      .postChargingCardsOrderRequest(chargingCardsOrder, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Sends a communication package order request.
   * @summary Request communication package
   * @param {CommunicationPackageOrder} [communicationPackageOrder] Request to order a communication package.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public postCommunicationPackageOrderRequest(
    communicationPackageOrder?: CommunicationPackageOrder,
    options?: RawAxiosRequestConfig,
  ) {
    return OrdersApiFp(this.configuration)
      .postCommunicationPackageOrderRequest(communicationPackageOrder, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Sends a shirt order request.
   * @summary Request shirt
   * @param {ShirtOrder} [shirtOrder] Request to order a shirt.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public postShirtOrderRequest(shirtOrder?: ShirtOrder, options?: RawAxiosRequestConfig) {
    return OrdersApiFp(this.configuration)
      .postShirtOrderRequest(shirtOrder, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * RecommendationsApi - axios parameter creator
 * @export
 */
export const RecommendationsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Creates a new recommendation and returns it, along with the generated recommendation code.
     * @summary Create recommendation
     * @param {Recommendation} [recommendation] Request to create a recommendation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRecommendation: async (
      recommendation?: Recommendation,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/recommendations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        recommendation,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RecommendationsApi - functional programming interface
 * @export
 */
export const RecommendationsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = RecommendationsApiAxiosParamCreator(configuration);
  return {
    /**
     * Creates a new recommendation and returns it, along with the generated recommendation code.
     * @summary Create recommendation
     * @param {Recommendation} [recommendation] Request to create a recommendation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createRecommendation(
      recommendation?: Recommendation,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recommendation>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createRecommendation(
        recommendation,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["RecommendationsApi.createRecommendation"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * RecommendationsApi - factory interface
 * @export
 */
export const RecommendationsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = RecommendationsApiFp(configuration);
  return {
    /**
     * Creates a new recommendation and returns it, along with the generated recommendation code.
     * @summary Create recommendation
     * @param {Recommendation} [recommendation] Request to create a recommendation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRecommendation(
      recommendation?: Recommendation,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Recommendation> {
      return localVarFp
        .createRecommendation(recommendation, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * RecommendationsApi - object-oriented interface
 * @export
 * @class RecommendationsApi
 * @extends {BaseAPI}
 */
export class RecommendationsApi extends BaseAPI {
  /**
   * Creates a new recommendation and returns it, along with the generated recommendation code.
   * @summary Create recommendation
   * @param {Recommendation} [recommendation] Request to create a recommendation.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecommendationsApi
   */
  public createRecommendation(
    recommendation?: Recommendation,
    options?: RawAxiosRequestConfig,
  ) {
    return RecommendationsApiFp(this.configuration)
      .createRecommendation(recommendation, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SuppliersApi - axios parameter creator
 * @export
 */
export const SuppliersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Gets a list of suppliers based on the given type
     * @summary Get suppliers
     * @param {SupplierType} type The type of suppliers to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSuppliers: async (
      type: SupplierType,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'type' is not null or undefined
      assertParamExists("getSuppliers", "type", type);
      const localVarPath = `/suppliers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (type !== undefined) {
        localVarQueryParameter["type"] = type;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SuppliersApi - functional programming interface
 * @export
 */
export const SuppliersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SuppliersApiAxiosParamCreator(configuration);
  return {
    /**
     * Gets a list of suppliers based on the given type
     * @summary Get suppliers
     * @param {SupplierType} type The type of suppliers to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSuppliers(
      type: SupplierType,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSuppliers200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSuppliers(
        type,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["SuppliersApi.getSuppliers"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * SuppliersApi - factory interface
 * @export
 */
export const SuppliersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SuppliersApiFp(configuration);
  return {
    /**
     * Gets a list of suppliers based on the given type
     * @summary Get suppliers
     * @param {SupplierType} type The type of suppliers to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSuppliers(
      type: SupplierType,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetSuppliers200Response> {
      return localVarFp
        .getSuppliers(type, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SuppliersApi - object-oriented interface
 * @export
 * @class SuppliersApi
 * @extends {BaseAPI}
 */
export class SuppliersApi extends BaseAPI {
  /**
   * Gets a list of suppliers based on the given type
   * @summary Get suppliers
   * @param {SupplierType} type The type of suppliers to retrieve
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SuppliersApi
   */
  public getSuppliers(type: SupplierType, options?: RawAxiosRequestConfig) {
    return SuppliersApiFp(this.configuration)
      .getSuppliers(type, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TariffsApi - axios parameter creator
 * @export
 */
export const TariffsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Gets a list of cities based on the provided query parameters.
     * @summary Get cities
     * @param {string} zipCode The ZIP Code to retrieve cities for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCities: async (
      zipCode: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'zipCode' is not null or undefined
      assertParamExists("getCities", "zipCode", zipCode);
      const localVarPath = `/cities`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (zipCode !== undefined) {
        localVarQueryParameter["zipCode"] = zipCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets a list of tariffs based on the provided query parameters.
     * @summary Get tariffs
     * @param {TariffType} type The type of tariffs to retrieve.
     * @param {string} zipCode The ZIP Code to retrieve tariffs for.
     * @param {number} annualConsumption The annual consumption to retrieve tariffs for.
     * @param {string} [partnerId] The partner id to retrieve tariffs for. If not given, tariffs with partnerId are filtered out.
     * @param {number} [annualConsumptionLowTariff] The annual consumption to retrieve low tariffs for.
     * @param {CustomerType} [applicableForCustomerType] The customer type to retrieve tariffs for. If not set, tariffs applicable for all customer types are returned.
     * @param {string} [requestedBeginAt] The requested supply date to retrieve tariffs for.
     * @param {ForecastBasis} [forecastBasis] The forecast basis to retrieve tariffs for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTariffs: async (
      type: TariffType,
      zipCode: string,
      annualConsumption: number,
      partnerId?: string,
      annualConsumptionLowTariff?: number,
      applicableForCustomerType?: CustomerType,
      requestedBeginAt?: string,
      forecastBasis?: ForecastBasis,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'type' is not null or undefined
      assertParamExists("getTariffs", "type", type);
      // verify required parameter 'zipCode' is not null or undefined
      assertParamExists("getTariffs", "zipCode", zipCode);
      // verify required parameter 'annualConsumption' is not null or undefined
      assertParamExists("getTariffs", "annualConsumption", annualConsumption);
      const localVarPath = `/tariffs/public`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (type !== undefined) {
        localVarQueryParameter["type"] = type;
      }

      if (zipCode !== undefined) {
        localVarQueryParameter["zipCode"] = zipCode;
      }

      if (partnerId !== undefined) {
        localVarQueryParameter["partnerId"] = partnerId;
      }

      if (annualConsumption !== undefined) {
        localVarQueryParameter["annualConsumption"] = annualConsumption;
      }

      if (annualConsumptionLowTariff !== undefined) {
        localVarQueryParameter["annualConsumptionLowTariff"] = annualConsumptionLowTariff;
      }

      if (applicableForCustomerType !== undefined) {
        localVarQueryParameter["applicableForCustomerType"] = applicableForCustomerType;
      }

      if (requestedBeginAt !== undefined) {
        localVarQueryParameter["requestedBeginAt"] =
          (requestedBeginAt as any) instanceof Date
            ? (requestedBeginAt as any).toISOString().substring(0, 10)
            : requestedBeginAt;
      }

      if (forecastBasis !== undefined) {
        localVarQueryParameter["forecastBasis"] = forecastBasis;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets a list of tariffs based on the provided ids.
     * @summary Get tariffs by id
     * @param {Array<string>} ids The ids of the tariffs to retrieve. Not found tariffs are ignored.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTariffsById: async (
      ids: Array<string>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'ids' is not null or undefined
      assertParamExists("getTariffsById", "ids", ids);
      const localVarPath = `/tariffs/by-ids`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (ids) {
        localVarQueryParameter["ids"] = ids;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TariffsApi - functional programming interface
 * @export
 */
export const TariffsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TariffsApiAxiosParamCreator(configuration);
  return {
    /**
     * Gets a list of cities based on the provided query parameters.
     * @summary Get cities
     * @param {string} zipCode The ZIP Code to retrieve cities for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCities(
      zipCode: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCities200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCities(
        zipCode,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TariffsApi.getCities"]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Gets a list of tariffs based on the provided query parameters.
     * @summary Get tariffs
     * @param {TariffType} type The type of tariffs to retrieve.
     * @param {string} zipCode The ZIP Code to retrieve tariffs for.
     * @param {number} annualConsumption The annual consumption to retrieve tariffs for.
     * @param {string} [partnerId] The partner id to retrieve tariffs for. If not given, tariffs with partnerId are filtered out.
     * @param {number} [annualConsumptionLowTariff] The annual consumption to retrieve low tariffs for.
     * @param {CustomerType} [applicableForCustomerType] The customer type to retrieve tariffs for. If not set, tariffs applicable for all customer types are returned.
     * @param {string} [requestedBeginAt] The requested supply date to retrieve tariffs for.
     * @param {ForecastBasis} [forecastBasis] The forecast basis to retrieve tariffs for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTariffs(
      type: TariffType,
      zipCode: string,
      annualConsumption: number,
      partnerId?: string,
      annualConsumptionLowTariff?: number,
      applicableForCustomerType?: CustomerType,
      requestedBeginAt?: string,
      forecastBasis?: ForecastBasis,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTariffs200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTariffs(
        type,
        zipCode,
        annualConsumption,
        partnerId,
        annualConsumptionLowTariff,
        applicableForCustomerType,
        requestedBeginAt,
        forecastBasis,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TariffsApi.getTariffs"]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Gets a list of tariffs based on the provided ids.
     * @summary Get tariffs by id
     * @param {Array<string>} ids The ids of the tariffs to retrieve. Not found tariffs are ignored.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTariffsById(
      ids: Array<string>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetTariffsById200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTariffsById(
        ids,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TariffsApi.getTariffsById"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * TariffsApi - factory interface
 * @export
 */
export const TariffsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TariffsApiFp(configuration);
  return {
    /**
     * Gets a list of cities based on the provided query parameters.
     * @summary Get cities
     * @param {string} zipCode The ZIP Code to retrieve cities for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCities(
      zipCode: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetCities200Response> {
      return localVarFp
        .getCities(zipCode, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets a list of tariffs based on the provided query parameters.
     * @summary Get tariffs
     * @param {TariffType} type The type of tariffs to retrieve.
     * @param {string} zipCode The ZIP Code to retrieve tariffs for.
     * @param {number} annualConsumption The annual consumption to retrieve tariffs for.
     * @param {string} [partnerId] The partner id to retrieve tariffs for. If not given, tariffs with partnerId are filtered out.
     * @param {number} [annualConsumptionLowTariff] The annual consumption to retrieve low tariffs for.
     * @param {CustomerType} [applicableForCustomerType] The customer type to retrieve tariffs for. If not set, tariffs applicable for all customer types are returned.
     * @param {string} [requestedBeginAt] The requested supply date to retrieve tariffs for.
     * @param {ForecastBasis} [forecastBasis] The forecast basis to retrieve tariffs for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTariffs(
      type: TariffType,
      zipCode: string,
      annualConsumption: number,
      partnerId?: string,
      annualConsumptionLowTariff?: number,
      applicableForCustomerType?: CustomerType,
      requestedBeginAt?: string,
      forecastBasis?: ForecastBasis,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetTariffs200Response> {
      return localVarFp
        .getTariffs(
          type,
          zipCode,
          annualConsumption,
          partnerId,
          annualConsumptionLowTariff,
          applicableForCustomerType,
          requestedBeginAt,
          forecastBasis,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets a list of tariffs based on the provided ids.
     * @summary Get tariffs by id
     * @param {Array<string>} ids The ids of the tariffs to retrieve. Not found tariffs are ignored.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTariffsById(
      ids: Array<string>,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<GetTariffsById200Response> {
      return localVarFp
        .getTariffsById(ids, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TariffsApi - object-oriented interface
 * @export
 * @class TariffsApi
 * @extends {BaseAPI}
 */
export class TariffsApi extends BaseAPI {
  /**
   * Gets a list of cities based on the provided query parameters.
   * @summary Get cities
   * @param {string} zipCode The ZIP Code to retrieve cities for.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TariffsApi
   */
  public getCities(zipCode: string, options?: RawAxiosRequestConfig) {
    return TariffsApiFp(this.configuration)
      .getCities(zipCode, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets a list of tariffs based on the provided query parameters.
   * @summary Get tariffs
   * @param {TariffType} type The type of tariffs to retrieve.
   * @param {string} zipCode The ZIP Code to retrieve tariffs for.
   * @param {number} annualConsumption The annual consumption to retrieve tariffs for.
   * @param {string} [partnerId] The partner id to retrieve tariffs for. If not given, tariffs with partnerId are filtered out.
   * @param {number} [annualConsumptionLowTariff] The annual consumption to retrieve low tariffs for.
   * @param {CustomerType} [applicableForCustomerType] The customer type to retrieve tariffs for. If not set, tariffs applicable for all customer types are returned.
   * @param {string} [requestedBeginAt] The requested supply date to retrieve tariffs for.
   * @param {ForecastBasis} [forecastBasis] The forecast basis to retrieve tariffs for.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TariffsApi
   */
  public getTariffs(
    type: TariffType,
    zipCode: string,
    annualConsumption: number,
    partnerId?: string,
    annualConsumptionLowTariff?: number,
    applicableForCustomerType?: CustomerType,
    requestedBeginAt?: string,
    forecastBasis?: ForecastBasis,
    options?: RawAxiosRequestConfig,
  ) {
    return TariffsApiFp(this.configuration)
      .getTariffs(
        type,
        zipCode,
        annualConsumption,
        partnerId,
        annualConsumptionLowTariff,
        applicableForCustomerType,
        requestedBeginAt,
        forecastBasis,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets a list of tariffs based on the provided ids.
   * @summary Get tariffs by id
   * @param {Array<string>} ids The ids of the tariffs to retrieve. Not found tariffs are ignored.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TariffsApi
   */
  public getTariffsById(ids: Array<string>, options?: RawAxiosRequestConfig) {
    return TariffsApiFp(this.configuration)
      .getTariffsById(ids, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TenantPowerApi - axios parameter creator
 * @export
 */
export const TenantPowerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Gets the partial contract for the given tenant power request.
     * @summary Get partial tenant power contract
     * @param {string} zipCode Zip code used to verify access to a protected resource.
     * @param {string} meterSerialNumber Meter serial number used to verify access to a protected resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTenantPowerPartialContract: async (
      zipCode: string,
      meterSerialNumber: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'zipCode' is not null or undefined
      assertParamExists("getTenantPowerPartialContract", "zipCode", zipCode);
      // verify required parameter 'meterSerialNumber' is not null or undefined
      assertParamExists(
        "getTenantPowerPartialContract",
        "meterSerialNumber",
        meterSerialNumber,
      );
      const localVarPath = `/tenant-power/partial-contract`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (zipCode !== undefined) {
        localVarQueryParameter["zipCode"] = zipCode;
      }

      if (meterSerialNumber !== undefined) {
        localVarQueryParameter["meterSerialNumber"] = meterSerialNumber;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Sends an operator request for a tenant power project.
     * @summary Sends an operator request for a tenant power project
     * @param {TenantPowerOperatorMessage} [tenantPowerOperatorMessage] Request to send a tenant power operator message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTenantPowerOperatorRequestMessage: async (
      tenantPowerOperatorMessage?: TenantPowerOperatorMessage,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/messages/tenant-power/operator-request`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tenantPowerOperatorMessage,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Sends a supply request as a customer for a tenant power project.
     * @summary Sends a supply request as a customer for a tenant power project
     * @param {TenantPowerSignUpMessage} [tenantPowerSignUpMessage] Request to send a tenant power sign up message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTenantPowerSignUpMessage: async (
      tenantPowerSignUpMessage?: TenantPowerSignUpMessage,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/messages/tenant-power/sign-up`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tenantPowerSignUpMessage,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TenantPowerApi - functional programming interface
 * @export
 */
export const TenantPowerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TenantPowerApiAxiosParamCreator(configuration);
  return {
    /**
     * Gets the partial contract for the given tenant power request.
     * @summary Get partial tenant power contract
     * @param {string} zipCode Zip code used to verify access to a protected resource.
     * @param {string} meterSerialNumber Meter serial number used to verify access to a protected resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTenantPowerPartialContract(
      zipCode: string,
      meterSerialNumber: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TenantPowerPartialContract>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getTenantPowerPartialContract(
          zipCode,
          meterSerialNumber,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TenantPowerApi.getTenantPowerPartialContract"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Sends an operator request for a tenant power project.
     * @summary Sends an operator request for a tenant power project
     * @param {TenantPowerOperatorMessage} [tenantPowerOperatorMessage] Request to send a tenant power operator message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postTenantPowerOperatorRequestMessage(
      tenantPowerOperatorMessage?: TenantPowerOperatorMessage,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postTenantPowerOperatorRequestMessage(
          tenantPowerOperatorMessage,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TenantPowerApi.postTenantPowerOperatorRequestMessage"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Sends a supply request as a customer for a tenant power project.
     * @summary Sends a supply request as a customer for a tenant power project
     * @param {TenantPowerSignUpMessage} [tenantPowerSignUpMessage] Request to send a tenant power sign up message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postTenantPowerSignUpMessage(
      tenantPowerSignUpMessage?: TenantPowerSignUpMessage,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postTenantPowerSignUpMessage(
          tenantPowerSignUpMessage,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TenantPowerApi.postTenantPowerSignUpMessage"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * TenantPowerApi - factory interface
 * @export
 */
export const TenantPowerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TenantPowerApiFp(configuration);
  return {
    /**
     * Gets the partial contract for the given tenant power request.
     * @summary Get partial tenant power contract
     * @param {string} zipCode Zip code used to verify access to a protected resource.
     * @param {string} meterSerialNumber Meter serial number used to verify access to a protected resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTenantPowerPartialContract(
      zipCode: string,
      meterSerialNumber: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<TenantPowerPartialContract> {
      return localVarFp
        .getTenantPowerPartialContract(zipCode, meterSerialNumber, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Sends an operator request for a tenant power project.
     * @summary Sends an operator request for a tenant power project
     * @param {TenantPowerOperatorMessage} [tenantPowerOperatorMessage] Request to send a tenant power operator message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTenantPowerOperatorRequestMessage(
      tenantPowerOperatorMessage?: TenantPowerOperatorMessage,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .postTenantPowerOperatorRequestMessage(tenantPowerOperatorMessage, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Sends a supply request as a customer for a tenant power project.
     * @summary Sends a supply request as a customer for a tenant power project
     * @param {TenantPowerSignUpMessage} [tenantPowerSignUpMessage] Request to send a tenant power sign up message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTenantPowerSignUpMessage(
      tenantPowerSignUpMessage?: TenantPowerSignUpMessage,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .postTenantPowerSignUpMessage(tenantPowerSignUpMessage, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TenantPowerApi - object-oriented interface
 * @export
 * @class TenantPowerApi
 * @extends {BaseAPI}
 */
export class TenantPowerApi extends BaseAPI {
  /**
   * Gets the partial contract for the given tenant power request.
   * @summary Get partial tenant power contract
   * @param {string} zipCode Zip code used to verify access to a protected resource.
   * @param {string} meterSerialNumber Meter serial number used to verify access to a protected resource.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantPowerApi
   */
  public getTenantPowerPartialContract(
    zipCode: string,
    meterSerialNumber: string,
    options?: RawAxiosRequestConfig,
  ) {
    return TenantPowerApiFp(this.configuration)
      .getTenantPowerPartialContract(zipCode, meterSerialNumber, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Sends an operator request for a tenant power project.
   * @summary Sends an operator request for a tenant power project
   * @param {TenantPowerOperatorMessage} [tenantPowerOperatorMessage] Request to send a tenant power operator message.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantPowerApi
   */
  public postTenantPowerOperatorRequestMessage(
    tenantPowerOperatorMessage?: TenantPowerOperatorMessage,
    options?: RawAxiosRequestConfig,
  ) {
    return TenantPowerApiFp(this.configuration)
      .postTenantPowerOperatorRequestMessage(tenantPowerOperatorMessage, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Sends a supply request as a customer for a tenant power project.
   * @summary Sends a supply request as a customer for a tenant power project
   * @param {TenantPowerSignUpMessage} [tenantPowerSignUpMessage] Request to send a tenant power sign up message.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantPowerApi
   */
  public postTenantPowerSignUpMessage(
    tenantPowerSignUpMessage?: TenantPowerSignUpMessage,
    options?: RawAxiosRequestConfig,
  ) {
    return TenantPowerApiFp(this.configuration)
      .postTenantPowerSignUpMessage(tenantPowerSignUpMessage, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TokenVerificationApi - axios parameter creator
 * @export
 */
export const TokenVerificationApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Verifies the email address linked to this token.
     * @summary Verify token
     * @param {string} xPolarsternVerificationToken Verification Token used to verify access to a protected resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyToken: async (
      xPolarsternVerificationToken: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xPolarsternVerificationToken' is not null or undefined
      assertParamExists(
        "verifyToken",
        "xPolarsternVerificationToken",
        xPolarsternVerificationToken,
      );
      const localVarPath = `/email-address/verification`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xPolarsternVerificationToken != null) {
        localVarHeaderParameter["X-Polarstern-Verification-Token"] = String(
          xPolarsternVerificationToken,
        );
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TokenVerificationApi - functional programming interface
 * @export
 */
export const TokenVerificationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TokenVerificationApiAxiosParamCreator(configuration);
  return {
    /**
     * Verifies the email address linked to this token.
     * @summary Verify token
     * @param {string} xPolarsternVerificationToken Verification Token used to verify access to a protected resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyToken(
      xPolarsternVerificationToken: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.verifyToken(
        xPolarsternVerificationToken,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TokenVerificationApi.verifyToken"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * TokenVerificationApi - factory interface
 * @export
 */
export const TokenVerificationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TokenVerificationApiFp(configuration);
  return {
    /**
     * Verifies the email address linked to this token.
     * @summary Verify token
     * @param {string} xPolarsternVerificationToken Verification Token used to verify access to a protected resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyToken(
      xPolarsternVerificationToken: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .verifyToken(xPolarsternVerificationToken, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TokenVerificationApi - object-oriented interface
 * @export
 * @class TokenVerificationApi
 * @extends {BaseAPI}
 */
export class TokenVerificationApi extends BaseAPI {
  /**
   * Verifies the email address linked to this token.
   * @summary Verify token
   * @param {string} xPolarsternVerificationToken Verification Token used to verify access to a protected resource.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TokenVerificationApi
   */
  public verifyToken(
    xPolarsternVerificationToken: string,
    options?: RawAxiosRequestConfig,
  ) {
    return TokenVerificationApiFp(this.configuration)
      .verifyToken(xPolarsternVerificationToken, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TransfersApi - axios parameter creator
 * @export
 */
export const TransfersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Gets a transfer by its ID and the related zip code.
     * @summary Get transfer
     * @param {string} transferId ID of the transfer to get.
     * @param {string} xPolarsternZipCode Zip code used to verify access.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransfer: async (
      transferId: string,
      xPolarsternZipCode: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'transferId' is not null or undefined
      assertParamExists("getTransfer", "transferId", transferId);
      // verify required parameter 'xPolarsternZipCode' is not null or undefined
      assertParamExists("getTransfer", "xPolarsternZipCode", xPolarsternZipCode);
      const localVarPath = `/transfers/{transferId}`.replace(
        `{${"transferId"}}`,
        encodeURIComponent(String(transferId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xPolarsternZipCode != null) {
        localVarHeaderParameter["X-Polarstern-Zip-Code"] = String(xPolarsternZipCode);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TransfersApi - functional programming interface
 * @export
 */
export const TransfersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TransfersApiAxiosParamCreator(configuration);
  return {
    /**
     * Gets a transfer by its ID and the related zip code.
     * @summary Get transfer
     * @param {string} transferId ID of the transfer to get.
     * @param {string} xPolarsternZipCode Zip code used to verify access.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTransfer(
      transferId: string,
      xPolarsternZipCode: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transfer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTransfer(
        transferId,
        xPolarsternZipCode,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["TransfersApi.getTransfer"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * TransfersApi - factory interface
 * @export
 */
export const TransfersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TransfersApiFp(configuration);
  return {
    /**
     * Gets a transfer by its ID and the related zip code.
     * @summary Get transfer
     * @param {string} transferId ID of the transfer to get.
     * @param {string} xPolarsternZipCode Zip code used to verify access.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransfer(
      transferId: string,
      xPolarsternZipCode: string,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Transfer> {
      return localVarFp
        .getTransfer(transferId, xPolarsternZipCode, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TransfersApi - object-oriented interface
 * @export
 * @class TransfersApi
 * @extends {BaseAPI}
 */
export class TransfersApi extends BaseAPI {
  /**
   * Gets a transfer by its ID and the related zip code.
   * @summary Get transfer
   * @param {string} transferId ID of the transfer to get.
   * @param {string} xPolarsternZipCode Zip code used to verify access.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransfersApi
   */
  public getTransfer(
    transferId: string,
    xPolarsternZipCode: string,
    options?: RawAxiosRequestConfig,
  ) {
    return TransfersApiFp(this.configuration)
      .getTransfer(transferId, xPolarsternZipCode, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
