import { isAxiosError, AxiosError } from "axios";
import { HTTPErrorHandlerBase } from "./httpErrorHandlerBase";
import { ValidationBadRequestHandler } from "./validationBadRequestHandler";
import { ResourceConflictHandler } from "./resourceConflictHandler";
import { ResourceNotFoundHandler } from "./resourceNotFoundHandler";
import { Problem, type HTTPProblemType } from "./httpProblemType";

export function errorHandlerFactory(error: AxiosError | Error | unknown) {
  let errorMessage: {
    [key in HTTPProblemType]?: string;
  } = HTTPErrorHandlerBase.mapGeneralErrors(Problem.general);

  if (!isAxiosError(error)) {
    return errorMessage;
  }

  if (error.code === AxiosError.ERR_NETWORK) {
    errorMessage = HTTPErrorHandlerBase.mapGeneralErrors(Problem.network);
  } else if (error.response?.data.status === 400) {
    errorMessage = new ValidationBadRequestHandler(error.response.data).mapError();
  } else if (error.response?.data.status === 404) {
    errorMessage = new ResourceNotFoundHandler(error.response.data).mapError();
  } else if (error.response?.data.status === 409) {
    /**
     * This is a temporary workaround to differentiate between different 409 errors
     * coming from POST /contacts & POST /customers.
     * This should be refactored to be sent specifically from backend
     * within BRN-374 scope.
     */
    let type = error.response.data.type;
    if (
      error.response?.config.method === "post" &&
      error.response?.config.url?.endsWith("/contacts")
    ) {
      type = "newsletter_already_subscribed";
    }

    errorMessage = new ResourceConflictHandler({
      ...error.response.data,
      type,
    }).mapError();
  }

  return errorMessage;
}

export default errorHandlerFactory;
