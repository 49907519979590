import { differenceInDays, max, min } from "date-fns";
import type { Contract } from "~/src/generated-sources/public";
import type { AlternativeImpactContract } from "~/types/shared/impact-calculator";

/**
 * Calculate the runtime of a contract in years.
 * The runtime is calculated from the start supply date to the termination date or now.
 * @param contract The contract to calculate the runtime for.
 * @returns The runtime in years in decimal form.
 */
const calculateContractRunTimeInYears = (
  contract: Contract | AlternativeImpactContract,
) => {
  const startSupplyDate = contract.supply.confirmedBeginAt;
  if (!startSupplyDate) return 0;

  const diff = differenceInDays(
    // Max date for impact: Termination date or now
    min([new Date(contract.termination?.terminatedAt || new Date()), new Date()]),
    // Min date for impact: Start supply date or Start of Polarstern (as fallback)
    max([new Date(startSupplyDate), new Date("2011-05-11")]),
  );

  if (diff < 0) return 0;

  // Return as percentage of a calendar year
  return diff / 365.25;
};

export default calculateContractRunTimeInYears;
