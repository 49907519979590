import { default as _91_46_46_46slugs_93ocHe8zeptJMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/[...slugs].vue?macro=true";
import { default as _5009PBBqutgVoMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/500.vue?macro=true";
import { default as accept_45transferqmi2TOM2qvMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/accept-transfer.vue?macro=true";
import { default as indexnUqpGTn58zMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/index.vue?macro=true";
import { default as accountubJanOua9QMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/account.vue?macro=true";
import { default as dankevrY9I5ZCDNMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/bestellen/danke.vue?macro=true";
import { default as datenbestaetigungcYgMULJSHzMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/bestellen/datenbestaetigung.vue?macro=true";
import { default as dateneingabece9jBEPMAhMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/bestellen/dateneingabe.vue?macro=true";
import { default as buendelupload6wSBEK0PX8Meta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/buendelupload.vue?macro=true";
import { default as impactp5fEhGwQxVMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/impact.vue?macro=true";
import { default as indexMJ2foojxi7Meta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/index.vue?macro=true";
import { default as abschlagosjiI9e5ZZMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/vertraege/abschlag.vue?macro=true";
import { default as bankverbindungprpSp9bScGMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/vertraege/bankverbindung.vue?macro=true";
import { default as indexAiIq6dpSXoMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/vertraege/index.vue?macro=true";
import { default as rechnungen1FC7Z2AdMIMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/vertraege/rechnungen.vue?macro=true";
import { default as zaehlerstandaOCVGjoX8BMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/vertraege/zaehlerstand.vue?macro=true";
import { default as index5YQ9BNlZvNMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/vertrag/[contractId]/index.vue?macro=true";
import { default as kuendigenWThjL9v6R3Meta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/vertrag/[contractId]/kuendigen.vue?macro=true";
import { default as uebertragenwRB0I2f7GBMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/vertrag/[contractId]/uebertragen.vue?macro=true";
import { default as umziehenvz6h2FewUbMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/vertrag/[contractId]/umziehen.vue?macro=true";
import { default as wechselWkr0Hun5G8Meta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/vertrag/[contractId]/wechsel.vue?macro=true";
import { default as weiterempfehlenF14UBAvQmrMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/weiterempfehlen.vue?macro=true";
import { default as indexWvwWmr7g7dMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/index.vue?macro=true";
import { default as buendeluploadiMsYF7llCJMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/neukunde/buendelupload.vue?macro=true";
import { default as dankeQrk7ajI5dsMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/neukunde/danke.vue?macro=true";
import { default as datenbestaetigung7Q6zMpP855Meta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/neukunde/datenbestaetigung.vue?macro=true";
import { default as dateneingabeCAg0SCOsWKMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/neukunde/dateneingabe.vue?macro=true";
import { default as indexyjKFtNmp4HMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/neukunde/index.vue?macro=true";
import { default as indexFrkOr2fmpXMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/mieterstrom/kundenanlagen/[projectId]/index.vue?macro=true";
import { default as indexlA3Fc539pfMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/mieterstrom/kundenanlagen/[projectId]/messstellen/[measuringPointId]/index.vue?macro=true";
import { default as vorlaeufiger_45vertragDP4o6uN610Meta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/mieterstrom/kundenanlagen/[projectId]/messstellen/[measuringPointId]/vorlaeufiger-vertrag.vue?macro=true";
import { default as hinzufuegenZt6jng4SGSMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/mieterstrom/kundenanlagen/[projectId]/messstellen/hinzufuegen.vue?macro=true";
import { default as indexvyRDxsl7bWMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/mieterstrom/kundenanlagen/index.vue?macro=true";
import { default as projektanlageo9HY12OxOjMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/mieterstrom/projektanlage.vue?macro=true";
import { default as staticsDislvo7XtXMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/admin/mieterstrom/statics.ts?macro=true";
import { default as datenbestaetigungOw0FEClEL8Meta } from "/home/runner/work/polarstern-website/polarstern-website/pages/bestellen/datenbestaetigung.vue?macro=true";
import { default as dateneingabedRk30u7ADnMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/bestellen/dateneingabe.vue?macro=true";
import { default as dankeuAdPUoXQJqMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/danke.vue?macro=true";
import { default as Energieverbrauchsziele_Polarstern_EnergiesparWettbewerb_Privatkunden_46pdfvv6SdFK0W3Meta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Energieverbrauchsziele_Polarstern_EnergiesparWettbewerb_Privatkunden.pdf.vue?macro=true";
import { default as Energiewende_Weltweit_Madagaskar_Team_Copyright_Polarstern_46JPGw3dOenGFZwMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Energiewende_Weltweit_Madagaskar_Team_Copyright_Polarstern.JPG.vue?macro=true";
import { default as Florian_32Henle_Copyright_Polarstern_46jpgy8LaeipV3tMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Florian Henle_Copyright_Polarstern.jpg.vue?macro=true";
import { default as Gassee_46jsoniBA4qus88SMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Gassee.json.vue?macro=true";
import { default as Gruendach_45PV_Pr_228sentation_MAKolleg_WilhelmOswald_vPS_46pdfjaKnQARBZnMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Gruendach-PV_Präsentation_MAKolleg_WilhelmOswald_vPS.pdf.vue?macro=true";
import { default as GWOE_45gemeinwohlbilanz_452020_452022_45polarstern_45gmbh_46pdfob3vKcHXTJMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/GWOE-gemeinwohlbilanz-2020-2022-polarstern-gmbh.pdf.vue?macro=true";
import { default as Installation_Photovoltaik_Olympiastadion_Berlin_1_46jpgzGkc2Tt9ElMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Installation_Photovoltaik_Olympiastadion_Berlin_1.jpg.vue?macro=true";
import { default as Installation_Photovoltaik_Olympiastadion_Berlin_2_46jpg66MKP7OMGeMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Installation_Photovoltaik_Olympiastadion_Berlin_2.jpg.vue?macro=true";
import { default as Installation_Photovoltaik_Olympiastadion_Berlin_3_46jpgg09sAU17cGMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Installation_Photovoltaik_Olympiastadion_Berlin_3.jpg.vue?macro=true";
import { default as Installation_Photovoltaik_Olympiastadion_Berlin_4_46jpgl8af9BPUIkMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Installation_Photovoltaik_Olympiastadion_Berlin_4.jpg.vue?macro=true";
import { default as Installation_Photovoltaik_Olympiastadion_Berlin_5_46jpgO1ukm09TroMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Installation_Photovoltaik_Olympiastadion_Berlin_5.jpg.vue?macro=true";
import { default as Partner_vlnr_ManuelThielmann_TimoRohwedder_FrankNeubauer_LudgerBottermann_JanLoeper_Olympiastadion_Berlin_1_46jpg8PlA5yPsSkMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Partner_vlnr_ManuelThielmann_TimoRohwedder_FrankNeubauer_LudgerBottermann_JanLoeper_Olympiastadion_Berlin_1.jpg.vue?macro=true";
import { default as partner_45businesses_4520240807_46json2xvl18JG9dMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/partner-businesses-20240807.json.vue?macro=true";
import { default as Polarstern_Checkliste_Nachhaltig_Wohnen_46pdfokN8gBPN0HMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Polarstern_Checkliste_Nachhaltig_Wohnen.pdf.vue?macro=true";
import { default as Polarstern_Energie_Webbanner_Mieterstrom_46zipx0GRQgCKTFMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Polarstern_Energie_Webbanner_Mieterstrom.zip.vue?macro=true";
import { default as Polarstern_Flyer_46pdf4N1QawUvyYMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Polarstern_Flyer.pdf.vue?macro=true";
import { default as Polarstern_GWOE_BerichtMagazin_Doppelseiten_46pdf2zaDMtdmApMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Polarstern_GWOE_BerichtMagazin_Doppelseiten.pdf.vue?macro=true";
import { default as Polarstern_GWOE_Gesamtbericht_2017_452019_46pdfXnrkTx0wGdMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Polarstern_GWOE_Gesamtbericht_2017-2019.pdf.vue?macro=true";
import { default as Polarstern_Infoblatt_Kaskadenschaltung_PV_45Elektroauto_46pdf8YLubgCwXbMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Polarstern_Infoblatt_Kaskadenschaltung_PV-Elektroauto.pdf.vue?macro=true";
import { default as Polarstern_Infoblatt_Kaskadenschaltung_PV_45Waermepumpe_46pdfKz8aM6D2wFMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Polarstern_Infoblatt_Kaskadenschaltung_PV-Waermepumpe.pdf.vue?macro=true";
import { default as Polarstern_IsarCleanUp_2023_Karte_PrintAtHome_Farbe_DinA4_220530_46pdfaEKlKqJmphMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Polarstern_IsarCleanUp_2023_Karte_PrintAtHome_Farbe_DinA4_220530.pdf.vue?macro=true";
import { default as Polarstern_Logo_Logotype_positiv_CMYK_46jpghVfflFZuygMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Polarstern_Logo_Logotype_positiv_CMYK.jpg.vue?macro=true";
import { default as Polarstern_Mieterstrom_Ladekarte_Bedienungsanleitung_46pdf4z9PH2TyVnMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Polarstern_Mieterstrom_Ladekarte_Bedienungsanleitung.pdf.vue?macro=true";
import { default as Polarstern_Produktblatt_Wirklich_Oekogas_Privatkunden_low_46pdfx9SmwioPx6Meta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Polarstern_Produktblatt_Wirklich_Oekogas_Privatkunden_low.pdf.vue?macro=true";
import { default as polarstern_45gas_45kennzeichnung_452021_46pdfuFYBFdnQWkMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/polarstern-gas-kennzeichnung-2021.pdf.vue?macro=true";
import { default as polarstern_45infoplakat_45energiesparen_45im_45buero_46pdfjnCYaDVBpuMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/polarstern-infoplakat-energiesparen-im-buero.pdf.vue?macro=true";
import { default as social_45businesses_45list_4520240807_46jsonD16aKpGw8XMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/social-businesses-list-20240807.json.vue?macro=true";
import { default as Sonnengrill_46pdf86pKS6MHXwMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Sonnengrill.pdf.vue?macro=true";
import { default as stromsee_46json3h4dGLz2KbMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/stromsee.json.vue?macro=true";
import { default as Team_45Bild_Copyright_Polarstern_46jpgiiG5W0MDCgMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Team-Bild_Copyright_Polarstern.jpg.vue?macro=true";
import { default as Wasserrad_46pdfxPDDz8lXylMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Wasserrad.pdf.vue?macro=true";
import { default as indexoKQzVNPYgaMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/index.vue?macro=true";
import { default as accountABjXRlMshWMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/account.vue?macro=true";
import { default as datenbestaetigungxgEcxP19dXMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/bestellen/datenbestaetigung.vue?macro=true";
import { default as dateneingabemsvqRJOT7UMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/bestellen/dateneingabe.vue?macro=true";
import { default as dankeAx0976YdArMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/danke.vue?macro=true";
import { default as impacts58tUANN83Meta } from "/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/impact.vue?macro=true";
import { default as index0XL7FRY0ulMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/index.vue?macro=true";
import { default as abschlag10KLGGleTqMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/vertraege/abschlag.vue?macro=true";
import { default as bankverbindungtQfn5Lpy38Meta } from "/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/vertraege/bankverbindung.vue?macro=true";
import { default as indexCRi1W5RZctMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/vertraege/index.vue?macro=true";
import { default as rechnungenV9QHWenFx4Meta } from "/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/vertraege/rechnungen.vue?macro=true";
import { default as zaehlerstandPYKFyUfcX5Meta } from "/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/vertraege/zaehlerstand.vue?macro=true";
import { default as indexdHo2kKYZRXMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/vertrag/[id]/index.vue?macro=true";
import { default as kuendigen0I7KAlaYcyMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/vertrag/[id]/kuendigen.vue?macro=true";
import { default as uebertragenkMzE7QRMTlMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/vertrag/[id]/uebertragen.vue?macro=true";
import { default as umziehenQXxeFaheMFMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/vertrag/[id]/umziehen.vue?macro=true";
import { default as wechselXU4KkEGShzMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/vertrag/[id]/wechsel.vue?macro=true";
import { default as weiterempfehlen7VlLpukOeDMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/weiterempfehlen.vue?macro=true";
import { default as _91slug_93H4XoqdN1OpMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/magazin/artikel/[slug].vue?macro=true";
import { default as indexqFQcMcGVGLMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/magazin/index.vue?macro=true";
import { default as _91number_9371dQshI5nRMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/magazin/pages/[number].vue?macro=true";
import { default as index0TyzKi5v64Meta } from "/home/runner/work/polarstern-website/polarstern-website/pages/magazin/themen/[slug]/index.vue?macro=true";
import { default as _91number_93WcJVh2qZVzMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/magazin/themen/[slug]/pages/[number].vue?macro=true";
import { default as password_45reset_45success2dMosdZbEmMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/password-reset-success.vue?macro=true";
import { default as index41pFKhXmfgMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/presse/index.vue?macro=true";
import { default as _91slug_93BrVfgyrO28Meta } from "/home/runner/work/polarstern-website/polarstern-website/pages/presse/mitteilung/[slug].vue?macro=true";
import { default as _91number_93QmayYJ6Ts5Meta } from "/home/runner/work/polarstern-website/polarstern-website/pages/presse/pages/[number].vue?macro=true";
import { default as _91slug_93y0SnJnTS7jMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/service/fragen-antworten/[slug].vue?macro=true";
import { default as indexScac2L1DJyMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/service/fragen-antworten/index.vue?macro=true";
import { default as unsubscribebQ5kuPdHj3Meta } from "/home/runner/work/polarstern-website/polarstern-website/pages/unsubscribe.vue?macro=true";
import { default as verify_45emailILtxzGKs93Meta } from "/home/runner/work/polarstern-website/polarstern-website/pages/verify-email.vue?macro=true";
import { default as wartungshinweiskbAQyQiZmNMeta } from "/home/runner/work/polarstern-website/polarstern-website/pages/wartungshinweis.vue?macro=true";
import { default as component_45stubRlF0MZSlQ0Meta } from "/home/runner/work/polarstern-website/polarstern-website/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubRlF0MZSlQ0 } from "/home/runner/work/polarstern-website/polarstern-website/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slugs",
    path: "/:slugs(.*)*",
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/[...slugs].vue")
  },
  {
    name: "500",
    path: "/500",
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/500.vue")
  },
  {
    name: "accept-transfer",
    path: "/accept-transfer",
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/accept-transfer.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexnUqpGTn58zMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/index.vue")
  },
  {
    name: "admin-kunden-customerId-account",
    path: "/admin/kunden/:customerId()/account",
    meta: accountubJanOua9QMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/account.vue")
  },
  {
    name: "admin-kunden-customerId-bestellen-danke",
    path: "/admin/kunden/:customerId()/bestellen/danke",
    meta: dankevrY9I5ZCDNMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/bestellen/danke.vue")
  },
  {
    name: "admin-kunden-customerId-bestellen-datenbestaetigung",
    path: "/admin/kunden/:customerId()/bestellen/datenbestaetigung",
    meta: datenbestaetigungcYgMULJSHzMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/bestellen/datenbestaetigung.vue")
  },
  {
    name: "admin-kunden-customerId-bestellen-dateneingabe",
    path: "/admin/kunden/:customerId()/bestellen/dateneingabe",
    meta: dateneingabece9jBEPMAhMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/bestellen/dateneingabe.vue")
  },
  {
    name: "admin-kunden-customerId-buendelupload",
    path: "/admin/kunden/:customerId()/buendelupload",
    meta: buendelupload6wSBEK0PX8Meta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/buendelupload.vue")
  },
  {
    name: "admin-kunden-customerId-impact",
    path: "/admin/kunden/:customerId()/impact",
    meta: impactp5fEhGwQxVMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/impact.vue")
  },
  {
    name: "admin-kunden-customerId",
    path: "/admin/kunden/:customerId()",
    meta: indexMJ2foojxi7Meta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/index.vue")
  },
  {
    name: "admin-kunden-customerId-vertraege-abschlag",
    path: "/admin/kunden/:customerId()/vertraege/abschlag",
    meta: abschlagosjiI9e5ZZMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/vertraege/abschlag.vue")
  },
  {
    name: "admin-kunden-customerId-vertraege-bankverbindung",
    path: "/admin/kunden/:customerId()/vertraege/bankverbindung",
    meta: bankverbindungprpSp9bScGMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/vertraege/bankverbindung.vue")
  },
  {
    name: "admin-kunden-customerId-vertraege",
    path: "/admin/kunden/:customerId()/vertraege",
    meta: indexAiIq6dpSXoMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/vertraege/index.vue")
  },
  {
    name: "admin-kunden-customerId-vertraege-rechnungen",
    path: "/admin/kunden/:customerId()/vertraege/rechnungen",
    meta: rechnungen1FC7Z2AdMIMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/vertraege/rechnungen.vue")
  },
  {
    name: "admin-kunden-customerId-vertraege-zaehlerstand",
    path: "/admin/kunden/:customerId()/vertraege/zaehlerstand",
    meta: zaehlerstandaOCVGjoX8BMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/vertraege/zaehlerstand.vue")
  },
  {
    name: "admin-kunden-customerId-vertrag-contractId",
    path: "/admin/kunden/:customerId()/vertrag/:contractId()",
    meta: index5YQ9BNlZvNMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/vertrag/[contractId]/index.vue")
  },
  {
    name: "admin-kunden-customerId-vertrag-contractId-kuendigen",
    path: "/admin/kunden/:customerId()/vertrag/:contractId()/kuendigen",
    meta: kuendigenWThjL9v6R3Meta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/vertrag/[contractId]/kuendigen.vue")
  },
  {
    name: "admin-kunden-customerId-vertrag-contractId-uebertragen",
    path: "/admin/kunden/:customerId()/vertrag/:contractId()/uebertragen",
    meta: uebertragenwRB0I2f7GBMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/vertrag/[contractId]/uebertragen.vue")
  },
  {
    name: "admin-kunden-customerId-vertrag-contractId-umziehen",
    path: "/admin/kunden/:customerId()/vertrag/:contractId()/umziehen",
    meta: umziehenvz6h2FewUbMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/vertrag/[contractId]/umziehen.vue")
  },
  {
    name: "admin-kunden-customerId-vertrag-contractId-wechsel",
    path: "/admin/kunden/:customerId()/vertrag/:contractId()/wechsel",
    meta: wechselWkr0Hun5G8Meta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/vertrag/[contractId]/wechsel.vue")
  },
  {
    name: "admin-kunden-customerId-weiterempfehlen",
    path: "/admin/kunden/:customerId()/weiterempfehlen",
    meta: weiterempfehlenF14UBAvQmrMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/[customerId]/weiterempfehlen.vue")
  },
  {
    name: "admin-kunden",
    path: "/admin/kunden",
    meta: indexWvwWmr7g7dMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/index.vue")
  },
  {
    name: "admin-kunden-neukunde-buendelupload",
    path: "/admin/kunden/neukunde/buendelupload",
    meta: buendeluploadiMsYF7llCJMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/neukunde/buendelupload.vue")
  },
  {
    name: "admin-kunden-neukunde-danke",
    path: "/admin/kunden/neukunde/danke",
    meta: dankeQrk7ajI5dsMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/neukunde/danke.vue")
  },
  {
    name: "admin-kunden-neukunde-datenbestaetigung",
    path: "/admin/kunden/neukunde/datenbestaetigung",
    meta: datenbestaetigung7Q6zMpP855Meta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/neukunde/datenbestaetigung.vue")
  },
  {
    name: "admin-kunden-neukunde-dateneingabe",
    path: "/admin/kunden/neukunde/dateneingabe",
    meta: dateneingabeCAg0SCOsWKMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/neukunde/dateneingabe.vue")
  },
  {
    name: "admin-kunden-neukunde",
    path: "/admin/kunden/neukunde",
    meta: indexyjKFtNmp4HMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/kunden/neukunde/index.vue")
  },
  {
    name: "admin-mieterstrom-kundenanlagen-projectId",
    path: "/admin/mieterstrom/kundenanlagen/:projectId()",
    meta: indexFrkOr2fmpXMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/mieterstrom/kundenanlagen/[projectId]/index.vue")
  },
  {
    name: "admin-mieterstrom-kundenanlagen-projectId-messstellen-measuringPointId",
    path: "/admin/mieterstrom/kundenanlagen/:projectId()/messstellen/:measuringPointId()",
    meta: indexlA3Fc539pfMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/mieterstrom/kundenanlagen/[projectId]/messstellen/[measuringPointId]/index.vue")
  },
  {
    name: "admin-mieterstrom-kundenanlagen-projectId-messstellen-measuringPointId-vorlaeufiger-vertrag",
    path: "/admin/mieterstrom/kundenanlagen/:projectId()/messstellen/:measuringPointId()/vorlaeufiger-vertrag",
    meta: vorlaeufiger_45vertragDP4o6uN610Meta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/mieterstrom/kundenanlagen/[projectId]/messstellen/[measuringPointId]/vorlaeufiger-vertrag.vue")
  },
  {
    name: "admin-mieterstrom-kundenanlagen-projectId-messstellen-hinzufuegen",
    path: "/admin/mieterstrom/kundenanlagen/:projectId()/messstellen/hinzufuegen",
    meta: hinzufuegenZt6jng4SGSMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/mieterstrom/kundenanlagen/[projectId]/messstellen/hinzufuegen.vue")
  },
  {
    name: "admin-mieterstrom-kundenanlagen",
    path: "/admin/mieterstrom/kundenanlagen",
    meta: indexvyRDxsl7bWMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/mieterstrom/kundenanlagen/index.vue")
  },
  {
    name: "admin-mieterstrom-projektanlage",
    path: "/admin/mieterstrom/projektanlage",
    meta: projektanlageo9HY12OxOjMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/mieterstrom/projektanlage.vue")
  },
  {
    name: "admin-mieterstrom-statics",
    path: "/admin/mieterstrom/statics",
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/admin/mieterstrom/statics.ts")
  },
  {
    name: "bestellen-datenbestaetigung",
    path: "/bestellen/datenbestaetigung",
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/bestellen/datenbestaetigung.vue")
  },
  {
    name: "bestellen-dateneingabe",
    path: "/bestellen/dateneingabe",
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/bestellen/dateneingabe.vue")
  },
  {
    name: "danke",
    path: "/danke",
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/danke.vue")
  },
  {
    name: "downloads-Energieverbrauchsziele_Polarstern_EnergiesparWettbewerb_Privatkunden.pdf",
    path: "/downloads/Energieverbrauchsziele_Polarstern_EnergiesparWettbewerb_Privatkunden.pdf",
    meta: Energieverbrauchsziele_Polarstern_EnergiesparWettbewerb_Privatkunden_46pdfvv6SdFK0W3Meta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Energieverbrauchsziele_Polarstern_EnergiesparWettbewerb_Privatkunden.pdf.vue")
  },
  {
    name: "downloads-Energiewende_Weltweit_Madagaskar_Team_Copyright_Polarstern.JPG",
    path: "/downloads/Energiewende_Weltweit_Madagaskar_Team_Copyright_Polarstern.JPG",
    meta: Energiewende_Weltweit_Madagaskar_Team_Copyright_Polarstern_46JPGw3dOenGFZwMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Energiewende_Weltweit_Madagaskar_Team_Copyright_Polarstern.JPG.vue")
  },
  {
    name: "downloads-Florian Henle_Copyright_Polarstern.jpg",
    path: "/downloads/Florian%20Henle_Copyright_Polarstern.jpg",
    meta: Florian_32Henle_Copyright_Polarstern_46jpgy8LaeipV3tMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Florian Henle_Copyright_Polarstern.jpg.vue")
  },
  {
    name: "downloads-Gassee.json",
    path: "/downloads/Gassee.json",
    meta: Gassee_46jsoniBA4qus88SMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Gassee.json.vue")
  },
  {
    name: "downloads-Gruendach-PV_Präsentation_MAKolleg_WilhelmOswald_vPS.pdf",
    path: "/downloads/Gruendach-PV_Pr%C3%A4sentation_MAKolleg_WilhelmOswald_vPS.pdf",
    meta: Gruendach_45PV_Pr_228sentation_MAKolleg_WilhelmOswald_vPS_46pdfjaKnQARBZnMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Gruendach-PV_Präsentation_MAKolleg_WilhelmOswald_vPS.pdf.vue")
  },
  {
    name: "downloads-GWOE-gemeinwohlbilanz-2020-2022-polarstern-gmbh.pdf",
    path: "/downloads/GWOE-gemeinwohlbilanz-2020-2022-polarstern-gmbh.pdf",
    meta: GWOE_45gemeinwohlbilanz_452020_452022_45polarstern_45gmbh_46pdfob3vKcHXTJMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/GWOE-gemeinwohlbilanz-2020-2022-polarstern-gmbh.pdf.vue")
  },
  {
    name: "downloads-Installation_Photovoltaik_Olympiastadion_Berlin_1.jpg",
    path: "/downloads/Installation_Photovoltaik_Olympiastadion_Berlin_1.jpg",
    meta: Installation_Photovoltaik_Olympiastadion_Berlin_1_46jpgzGkc2Tt9ElMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Installation_Photovoltaik_Olympiastadion_Berlin_1.jpg.vue")
  },
  {
    name: "downloads-Installation_Photovoltaik_Olympiastadion_Berlin_2.jpg",
    path: "/downloads/Installation_Photovoltaik_Olympiastadion_Berlin_2.jpg",
    meta: Installation_Photovoltaik_Olympiastadion_Berlin_2_46jpg66MKP7OMGeMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Installation_Photovoltaik_Olympiastadion_Berlin_2.jpg.vue")
  },
  {
    name: "downloads-Installation_Photovoltaik_Olympiastadion_Berlin_3.jpg",
    path: "/downloads/Installation_Photovoltaik_Olympiastadion_Berlin_3.jpg",
    meta: Installation_Photovoltaik_Olympiastadion_Berlin_3_46jpgg09sAU17cGMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Installation_Photovoltaik_Olympiastadion_Berlin_3.jpg.vue")
  },
  {
    name: "downloads-Installation_Photovoltaik_Olympiastadion_Berlin_4.jpg",
    path: "/downloads/Installation_Photovoltaik_Olympiastadion_Berlin_4.jpg",
    meta: Installation_Photovoltaik_Olympiastadion_Berlin_4_46jpgl8af9BPUIkMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Installation_Photovoltaik_Olympiastadion_Berlin_4.jpg.vue")
  },
  {
    name: "downloads-Installation_Photovoltaik_Olympiastadion_Berlin_5.jpg",
    path: "/downloads/Installation_Photovoltaik_Olympiastadion_Berlin_5.jpg",
    meta: Installation_Photovoltaik_Olympiastadion_Berlin_5_46jpgO1ukm09TroMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Installation_Photovoltaik_Olympiastadion_Berlin_5.jpg.vue")
  },
  {
    name: "downloads-Partner_vlnr_ManuelThielmann_TimoRohwedder_FrankNeubauer_LudgerBottermann_JanLoeper_Olympiastadion_Berlin_1.jpg",
    path: "/downloads/Partner_vlnr_ManuelThielmann_TimoRohwedder_FrankNeubauer_LudgerBottermann_JanLoeper_Olympiastadion_Berlin_1.jpg",
    meta: Partner_vlnr_ManuelThielmann_TimoRohwedder_FrankNeubauer_LudgerBottermann_JanLoeper_Olympiastadion_Berlin_1_46jpg8PlA5yPsSkMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Partner_vlnr_ManuelThielmann_TimoRohwedder_FrankNeubauer_LudgerBottermann_JanLoeper_Olympiastadion_Berlin_1.jpg.vue")
  },
  {
    name: "downloads-partner-businesses-20240807.json",
    path: "/downloads/partner-businesses-20240807.json",
    meta: partner_45businesses_4520240807_46json2xvl18JG9dMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/partner-businesses-20240807.json.vue")
  },
  {
    name: "downloads-Polarstern_Checkliste_Nachhaltig_Wohnen.pdf",
    path: "/downloads/Polarstern_Checkliste_Nachhaltig_Wohnen.pdf",
    meta: Polarstern_Checkliste_Nachhaltig_Wohnen_46pdfokN8gBPN0HMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Polarstern_Checkliste_Nachhaltig_Wohnen.pdf.vue")
  },
  {
    name: "downloads-Polarstern_Energie_Webbanner_Mieterstrom.zip",
    path: "/downloads/Polarstern_Energie_Webbanner_Mieterstrom.zip",
    meta: Polarstern_Energie_Webbanner_Mieterstrom_46zipx0GRQgCKTFMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Polarstern_Energie_Webbanner_Mieterstrom.zip.vue")
  },
  {
    name: "downloads-Polarstern_Flyer.pdf",
    path: "/downloads/Polarstern_Flyer.pdf",
    meta: Polarstern_Flyer_46pdf4N1QawUvyYMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Polarstern_Flyer.pdf.vue")
  },
  {
    name: "downloads-Polarstern_GWOE_BerichtMagazin_Doppelseiten.pdf",
    path: "/downloads/Polarstern_GWOE_BerichtMagazin_Doppelseiten.pdf",
    meta: Polarstern_GWOE_BerichtMagazin_Doppelseiten_46pdf2zaDMtdmApMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Polarstern_GWOE_BerichtMagazin_Doppelseiten.pdf.vue")
  },
  {
    name: "downloads-Polarstern_GWOE_Gesamtbericht_2017-2019.pdf",
    path: "/downloads/Polarstern_GWOE_Gesamtbericht_2017-2019.pdf",
    meta: Polarstern_GWOE_Gesamtbericht_2017_452019_46pdfXnrkTx0wGdMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Polarstern_GWOE_Gesamtbericht_2017-2019.pdf.vue")
  },
  {
    name: "downloads-Polarstern_Infoblatt_Kaskadenschaltung_PV-Elektroauto.pdf",
    path: "/downloads/Polarstern_Infoblatt_Kaskadenschaltung_PV-Elektroauto.pdf",
    meta: Polarstern_Infoblatt_Kaskadenschaltung_PV_45Elektroauto_46pdf8YLubgCwXbMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Polarstern_Infoblatt_Kaskadenschaltung_PV-Elektroauto.pdf.vue")
  },
  {
    name: "downloads-Polarstern_Infoblatt_Kaskadenschaltung_PV-Waermepumpe.pdf",
    path: "/downloads/Polarstern_Infoblatt_Kaskadenschaltung_PV-Waermepumpe.pdf",
    meta: Polarstern_Infoblatt_Kaskadenschaltung_PV_45Waermepumpe_46pdfKz8aM6D2wFMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Polarstern_Infoblatt_Kaskadenschaltung_PV-Waermepumpe.pdf.vue")
  },
  {
    name: "downloads-Polarstern_IsarCleanUp_2023_Karte_PrintAtHome_Farbe_DinA4_220530.pdf",
    path: "/downloads/Polarstern_IsarCleanUp_2023_Karte_PrintAtHome_Farbe_DinA4_220530.pdf",
    meta: Polarstern_IsarCleanUp_2023_Karte_PrintAtHome_Farbe_DinA4_220530_46pdfaEKlKqJmphMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Polarstern_IsarCleanUp_2023_Karte_PrintAtHome_Farbe_DinA4_220530.pdf.vue")
  },
  {
    name: "downloads-Polarstern_Logo_Logotype_positiv_CMYK.jpg",
    path: "/downloads/Polarstern_Logo_Logotype_positiv_CMYK.jpg",
    meta: Polarstern_Logo_Logotype_positiv_CMYK_46jpghVfflFZuygMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Polarstern_Logo_Logotype_positiv_CMYK.jpg.vue")
  },
  {
    name: "downloads-Polarstern_Mieterstrom_Ladekarte_Bedienungsanleitung.pdf",
    path: "/downloads/Polarstern_Mieterstrom_Ladekarte_Bedienungsanleitung.pdf",
    meta: Polarstern_Mieterstrom_Ladekarte_Bedienungsanleitung_46pdf4z9PH2TyVnMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Polarstern_Mieterstrom_Ladekarte_Bedienungsanleitung.pdf.vue")
  },
  {
    name: "downloads-Polarstern_Produktblatt_Wirklich_Oekogas_Privatkunden_low.pdf",
    path: "/downloads/Polarstern_Produktblatt_Wirklich_Oekogas_Privatkunden_low.pdf",
    meta: Polarstern_Produktblatt_Wirklich_Oekogas_Privatkunden_low_46pdfx9SmwioPx6Meta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Polarstern_Produktblatt_Wirklich_Oekogas_Privatkunden_low.pdf.vue")
  },
  {
    name: "downloads-polarstern-gas-kennzeichnung-2021.pdf",
    path: "/downloads/polarstern-gas-kennzeichnung-2021.pdf",
    meta: polarstern_45gas_45kennzeichnung_452021_46pdfuFYBFdnQWkMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/polarstern-gas-kennzeichnung-2021.pdf.vue")
  },
  {
    name: "downloads-polarstern-infoplakat-energiesparen-im-buero.pdf",
    path: "/downloads/polarstern-infoplakat-energiesparen-im-buero.pdf",
    meta: polarstern_45infoplakat_45energiesparen_45im_45buero_46pdfjnCYaDVBpuMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/polarstern-infoplakat-energiesparen-im-buero.pdf.vue")
  },
  {
    name: "downloads-social-businesses-list-20240807.json",
    path: "/downloads/social-businesses-list-20240807.json",
    meta: social_45businesses_45list_4520240807_46jsonD16aKpGw8XMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/social-businesses-list-20240807.json.vue")
  },
  {
    name: "downloads-Sonnengrill.pdf",
    path: "/downloads/Sonnengrill.pdf",
    meta: Sonnengrill_46pdf86pKS6MHXwMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Sonnengrill.pdf.vue")
  },
  {
    name: "downloads-stromsee.json",
    path: "/downloads/stromsee.json",
    meta: stromsee_46json3h4dGLz2KbMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/stromsee.json.vue")
  },
  {
    name: "downloads-Team-Bild_Copyright_Polarstern.jpg",
    path: "/downloads/Team-Bild_Copyright_Polarstern.jpg",
    meta: Team_45Bild_Copyright_Polarstern_46jpgiiG5W0MDCgMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Team-Bild_Copyright_Polarstern.jpg.vue")
  },
  {
    name: "downloads-Wasserrad.pdf",
    path: "/downloads/Wasserrad.pdf",
    meta: Wasserrad_46pdfxPDDz8lXylMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/downloads/Wasserrad.pdf.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/index.vue")
  },
  {
    name: "kundenbereich-account",
    path: "/kundenbereich/account",
    meta: accountABjXRlMshWMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/account.vue")
  },
  {
    name: "kundenbereich-bestellen-datenbestaetigung",
    path: "/kundenbereich/bestellen/datenbestaetigung",
    meta: datenbestaetigungxgEcxP19dXMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/bestellen/datenbestaetigung.vue")
  },
  {
    name: "kundenbereich-bestellen-dateneingabe",
    path: "/kundenbereich/bestellen/dateneingabe",
    meta: dateneingabemsvqRJOT7UMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/bestellen/dateneingabe.vue")
  },
  {
    name: "kundenbereich-danke",
    path: "/kundenbereich/danke",
    meta: dankeAx0976YdArMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/danke.vue")
  },
  {
    name: "kundenbereich-impact",
    path: "/kundenbereich/impact",
    meta: impacts58tUANN83Meta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/impact.vue")
  },
  {
    name: "kundenbereich",
    path: "/kundenbereich",
    meta: index0XL7FRY0ulMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/index.vue")
  },
  {
    name: "kundenbereich-vertraege-abschlag",
    path: "/kundenbereich/vertraege/abschlag",
    meta: abschlag10KLGGleTqMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/vertraege/abschlag.vue")
  },
  {
    name: "kundenbereich-vertraege-bankverbindung",
    path: "/kundenbereich/vertraege/bankverbindung",
    meta: bankverbindungtQfn5Lpy38Meta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/vertraege/bankverbindung.vue")
  },
  {
    name: "kundenbereich-vertraege",
    path: "/kundenbereich/vertraege",
    meta: indexCRi1W5RZctMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/vertraege/index.vue")
  },
  {
    name: "kundenbereich-vertraege-rechnungen",
    path: "/kundenbereich/vertraege/rechnungen",
    meta: rechnungenV9QHWenFx4Meta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/vertraege/rechnungen.vue")
  },
  {
    name: "kundenbereich-vertraege-zaehlerstand",
    path: "/kundenbereich/vertraege/zaehlerstand",
    meta: zaehlerstandPYKFyUfcX5Meta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/vertraege/zaehlerstand.vue")
  },
  {
    name: "kundenbereich-vertrag-id",
    path: "/kundenbereich/vertrag/:id()",
    meta: indexdHo2kKYZRXMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/vertrag/[id]/index.vue")
  },
  {
    name: "kundenbereich-vertrag-id-kuendigen",
    path: "/kundenbereich/vertrag/:id()/kuendigen",
    meta: kuendigen0I7KAlaYcyMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/vertrag/[id]/kuendigen.vue")
  },
  {
    name: "kundenbereich-vertrag-id-uebertragen",
    path: "/kundenbereich/vertrag/:id()/uebertragen",
    meta: uebertragenkMzE7QRMTlMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/vertrag/[id]/uebertragen.vue")
  },
  {
    name: "kundenbereich-vertrag-id-umziehen",
    path: "/kundenbereich/vertrag/:id()/umziehen",
    meta: umziehenQXxeFaheMFMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/vertrag/[id]/umziehen.vue")
  },
  {
    name: "kundenbereich-vertrag-id-wechsel",
    path: "/kundenbereich/vertrag/:id()/wechsel",
    meta: wechselXU4KkEGShzMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/vertrag/[id]/wechsel.vue")
  },
  {
    name: "kundenbereich-weiterempfehlen",
    path: "/kundenbereich/weiterempfehlen",
    meta: weiterempfehlen7VlLpukOeDMeta || {},
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/kundenbereich/weiterempfehlen.vue")
  },
  {
    name: "magazin-artikel-slug",
    path: "/magazin/artikel/:slug()",
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/magazin/artikel/[slug].vue")
  },
  {
    name: "magazin",
    path: "/magazin",
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/magazin/index.vue")
  },
  {
    name: "magazin-pages-number",
    path: "/magazin/pages/:number()",
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/magazin/pages/[number].vue")
  },
  {
    name: "magazin-themen-slug",
    path: "/magazin/themen/:slug()",
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/magazin/themen/[slug]/index.vue")
  },
  {
    name: "magazin-themen-slug-pages-number",
    path: "/magazin/themen/:slug()/pages/:number()",
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/magazin/themen/[slug]/pages/[number].vue")
  },
  {
    name: "password-reset-success",
    path: "/password-reset-success",
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/password-reset-success.vue")
  },
  {
    name: "presse",
    path: "/presse",
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/presse/index.vue")
  },
  {
    name: "presse-mitteilung-slug",
    path: "/presse/mitteilung/:slug()",
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/presse/mitteilung/[slug].vue")
  },
  {
    name: "presse-pages-number",
    path: "/presse/pages/:number()",
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/presse/pages/[number].vue")
  },
  {
    name: "service-fragen-antworten-slug",
    path: "/service/fragen-antworten/:slug()",
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/service/fragen-antworten/[slug].vue")
  },
  {
    name: "service-fragen-antworten",
    path: "/service/fragen-antworten",
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/service/fragen-antworten/index.vue")
  },
  {
    name: "unsubscribe",
    path: "/unsubscribe",
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/unsubscribe.vue")
  },
  {
    name: "verify-email",
    path: "/verify-email",
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/verify-email.vue")
  },
  {
    name: "wartungshinweis",
    path: "/wartungshinweis",
    component: () => import("/home/runner/work/polarstern-website/polarstern-website/pages/wartungshinweis.vue")
  },
  {
    name: component_45stubRlF0MZSlQ0Meta?.name,
    path: "/magazin/artikel",
    component: component_45stubRlF0MZSlQ0
  },
  {
    name: component_45stubRlF0MZSlQ0Meta?.name,
    path: "/magazin/themen",
    component: component_45stubRlF0MZSlQ0
  },
  {
    name: component_45stubRlF0MZSlQ0Meta?.name,
    path: "/presse/mitteilung",
    component: component_45stubRlF0MZSlQ0
  },
  {
    name: component_45stubRlF0MZSlQ0Meta?.name,
    path: "/service",
    component: component_45stubRlF0MZSlQ0
  }
]