<!--
  Our BaseForm that takes care of all the generic stuff:
  - Submit Button
  - Incomplete Messages
  - Loading Spinner
  - Success Message
  - Error Message
-->
<template>
  <div>
    <TheLoadingSpinner is-fullscreen :is-visible="isLoading" />
    <SuccessMessage
      v-if="submittedMessage"
      :title="submittedMessage.title"
      :message="submittedMessage.message"
    />
    <FormKit
      v-else
      :id="formId"
      type="form"
      aria-label="Kontaktformular"
      :actions="false"
      :incomplete-message="false"
      @submit="genericSubmitHandler"
    >
      <slot />
      <SubmitButton
        :label="submitButtonLabel"
        display-in-row-and-centered
        :color="submitButtonColor"
      />
      <PsFormKitSummary />
    </FormKit>
  </div>
</template>

<script lang="ts" setup>
import type { FormKitNode } from "@formkit/core";
import { useEmitFormSubmit } from "~/composables/useEmitGtmEvent";
import PsFormKitSummary from "~/components/01_atoms/form/PsFormKitSummary.vue";
import TheLoadingSpinner from "~/components/01_atoms/TheLoadingSpinner.vue";
import SuccessMessage from "~/components/02_molecules/forms/SuccessMessage.vue";
import SubmitButton from "~/components/02_molecules/forms/SubmitButton.vue";

const {
  formId,
  onSubmit,
  submitButtonLabel = "Senden",
  submitButtonColor = "blue",
  successMessageTexts = {
    title: "Danke!",
    message: "Deine Daten wurden erfolgreich übermittelt.",
  },
} = defineProps<{
  formId: string;
  /**
   * The async function that shall be executed when the form is submitted.
   * Normally, it will execute the request.
   * Note: Just pass pass the function without calling it. The BaseForm will take care of everything.
   *
   * @param data The form data that was entered in the form.
   * @param node The node of the form it was entered in.
   */
  onSubmit: (data: any, node: FormKitNode) => Promise<any>;
  submitButtonLabel?: string;
  submitButtonColor?: "teal" | "red" | "blue" | "neutral";
  successMessageTexts?: { title: string; message: string };
}>();

const submittedMessage = ref<{ title: string; message: string }>();
const isLoading = ref(false);

const genericSubmitHandler = async (data: any, node: FormKitNode) => {
  isLoading.value = true;
  try {
    await onSubmit(data, node);
    useEmitFormSubmit(formId);
    submittedMessage.value = {
      title: successMessageTexts.title,
      message: successMessageTexts.message,
    };
  } catch (error) {
    console.error(error);
    submittedMessage.value = {
      title: "Ups!",
      message: "Da ist leider etwas schiefgegangen. Bitte versuche es später erneut.",
    };
  } finally {
    isLoading.value = false;
  }
};
</script>
