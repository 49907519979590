export const useFaqStore = defineStore(
  "FaqStore",
  () => {
    const faqItemVotes = ref<string[]>([]);

    const addVoteFor = (id: string) => {
      if (hasVotedFor(id)) {
        return;
      }
      unref(faqItemVotes).push(id);
    };

    const hasVotedFor = (id: string) => unref(faqItemVotes).includes(id);

    return { addVoteFor, faqItemVotes, hasVotedFor };
  },
  {
    persist: { storage: persistedState.localStorage },
  },
);
