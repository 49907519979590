import { format } from "date-fns";
import { defineStore } from "pinia";
import { getLatestMeterReading } from "../utils/meterReadings";
import { storage } from "~/stores/persistedState";
import type { Customer, TariffHistoryInner } from "~/src/generated-sources/core";
import { OrderReason } from "~/src/generated-sources/public";
import type {
  TenantPowerContractType,
  BaseContractTransferredFrom,
  CustomerWithContracts,
  EntityType,
  PublicTariff,
  TenantPowerPartialContract,
  TenantPowerPartialContractSupplyAddress,
} from "~/src/generated-sources/public";
import type {
  NewContract,
  ProductGroupValue,
  TrackConsumption,
} from "~/types/shared/calculator";
import getCurrentTariffId from "~/utils/customer-area/getContractCurrentTariff";

type TrackingInfo = {
  consumption: TrackConsumption;
  productGroup?: ProductGroupValue;
  tabTitle?: string;
};

export const useOrderStore = defineStore(
  "OrderStore",
  () => {
    const existingCustomer: Ref<Customer | undefined> = ref();
    const newCustomer: Ref<CustomerWithContracts | undefined> = ref();
    const dataEntryForm = ref<any>({
      meterInfo: {
        contractDetails: [],
      },
      selfPayer: false,
    });

    const trackingInfo: Ref<TrackingInfo | undefined> = ref();

    const transferredFrom: Ref<BaseContractTransferredFrom | undefined> = ref();
    const hiddenRecommendationCode = ref<string | undefined>(undefined);
    const isInTenantPowerRegistrationProcess = ref(false);
    const newCustomerZipCode = ref("");

    const newContracts = ref<NewContract[]>([]);

    const customerType = ref<EntityType | null>(null);

    const cachedPowerTariffs = ref<PublicTariff[]>([]);
    const cachedGasTariffs = ref<PublicTariff[]>([]);

    const setNewContracts = (contracts: NewContract[]) => {
      newContracts.value = contracts;
      dataEntryForm.value.meterInfo.contractDetails = contracts;
    };
    const disableOrderReasonField = ref(false);

    const reset = () => {
      newCustomer.value = undefined;
      // TODO: check if this is okay
      dataEntryForm.value = {
        meterInfo: {
          contractDetails: [],
        },
        bankAccount: {
          selfPayer: false,
        },
      };
      hiddenRecommendationCode.value = undefined;
      newContracts.value = [];
      trackingInfo.value = undefined;
      existingCustomer.value = undefined;
      cachedGasTariffs.value = [];
      cachedPowerTariffs.value = [];
      resetSpecialCheckoutFlows();
    };

    /** Leaving a special checkout flow can break the regular checkout process
     *  so we need to be able to reset them.
     * */
    const resetSpecialCheckoutFlows = () => {
      transferredFrom.value = undefined;
      isInTenantPowerRegistrationProcess.value = false;
      disableOrderReasonField.value = false;
    };

    const injectPreliminaryContract = ({
      firstName,
      lastName,
      requestedBeginAt,
      meterSerialNumber,
      address,
      orderReason = OrderReason.Move,
    }: {
      firstName?: string;
      lastName: string;
      requestedBeginAt?: string;
      meterSerialNumber: string;
      address?: TenantPowerPartialContractSupplyAddress;
      orderReason?: OrderReason;
    }) => {
      isInTenantPowerRegistrationProcess.value = true;
      customerType.value = "private";
      dataEntryForm.value.personalData = {
        firstName,
        lastName,
        entityType: "private",
        dateOfBirth: "1970-01-01",
        newMail: "anmeldung@polarstern-energie.de",
        newMail_confirm: "anmeldung@polarstern-energie.de",
        phoneNumber: "00",
      };

      dataEntryForm.value.bankAccount = {
        selfPayer: true,
      };

      newCustomerZipCode.value = address?.zipCode || "";
      dataEntryForm.value.meterInfo.supplyAddress = address;

      const contractDetails: {
        isMeterNumberKnown: string;
        meterNumber: string;
        dateOfInstallation?: string;
        dateOfMoving?: string;
        requestedBeginAt?: string;
      } = {
        isMeterNumberKnown: "meterNumberIsKnown",
        meterNumber: meterSerialNumber,
      };

      if (requestedBeginAt) {
        const formattedRequestedBeginAt = format(
          new Date(requestedBeginAt),
          "yyyy-MM-dd",
        );
        dataEntryForm.value.meterInfo.contractDetails[0].dateOfMoving =
          formattedRequestedBeginAt;
        dataEntryForm.value.meterInfo.contractDetails[0].requestedBeginAt =
          formattedRequestedBeginAt;
      }
      dataEntryForm.value.meterInfo.orderReason =
        orderReason === OrderReason.NewMeter ? OrderReason.Move : orderReason;
      if (dataEntryForm.value.meterInfo.contractDetails.length) {
        dataEntryForm.value.meterInfo.contractDetails[0] = {
          ...dataEntryForm.value.meterInfo.contractDetails[0],
          ...contractDetails,
        };
      } else {
        dataEntryForm.value.meterInfo.contractDetails.push(contractDetails);
      }
    };

    const injectContractInformation = (contract: TenantPowerPartialContract) => {
      dataEntryForm.value.personalData = {
        ...dataEntryForm.value.personalData,
        entityType: customerType.value,
      };
      newCustomerZipCode.value = contract.supply.address.zipCode;
      dataEntryForm.value.meterInfo.supplyAddress = contract.supply.address;

      if (contract.supply.requestedBeginAt) {
        const dateOfMoving = new Date(contract.supply.requestedBeginAt);

        dataEntryForm.value.meterInfo.contractDetails[0].dateOfMoving = format(
          dateOfMoving,
          "yyyy-MM-dd",
        );
      }

      if (contract.orderReason) {
        disableOrderReasonField.value = true;
        dataEntryForm.value.meterInfo.orderReason =
          contract.orderReason === "newMeter" ? "move" : contract.orderReason;
      } else if (dataEntryForm.value.meterInfo.orderReason === "newMeter") {
        // Fix edge case where customer selected newMeter first but is now in tenant power registration
        dataEntryForm.value.meterInfo.orderReason = "move";
      }

      dataEntryForm.value.meterInfo.contractDetails[0].isMeterNumberKnown =
        "meterNumberIsKnown";
      dataEntryForm.value.meterInfo.contractDetails[0].meterNumber =
        contract.meterSerialNumber;

      if (contract.meterReadings.length > 0) {
        const latestMeterReading = getLatestMeterReading(contract.meterReadings);
        dataEntryForm.value.meterInfo.contractDetails[0].meterNumberValue =
          latestMeterReading.value;
      }
    };

    const injectTrackingInfo = (newTrackingInfo: TrackingInfo) => {
      trackingInfo.value = {
        ...trackingInfo.value,
        ...newTrackingInfo,
      };
    };

    const injectTariffInformation = (
      tariffHistory: TariffHistoryInner[],
      type: TenantPowerContractType,
      annualConsumption: string,
    ) => {
      const { $brain } = useNuxtApp();
      return $brain.public.tariffs
        .getTariffsById([getCurrentTariffId(tariffHistory)])
        .then((tariffResponse) => {
          if (!tariffResponse.data.items) {
            throw new Error("No tariff found");
          }

          const tariff = tariffResponse.data.items[0];
          const tariffType = tariff.type;

          const newContract: NewContract = {
            annualConsumptionValueHigh: annualConsumption,
            annualConsumptionValueLow: undefined,
            tariff,
            tariffHistory,
            tariffName: tariff.name,
            tariffSubType: tariff.subType,
            type,
          };

          // TODO: check if this is still needed
          if (tariffType === "power") {
            cachedPowerTariffs.value = [tariff];
          } else if (tariffType === "gas") {
            cachedGasTariffs.value = [tariff];
          }

          setNewContracts([newContract]);
        });
    };

    return {
      cachedPowerTariffs,
      cachedGasTariffs,
      disableOrderReasonField,
      existingCustomer,
      hiddenRecommendationCode,
      customerType,
      newContracts,
      newCustomer,
      dataEntryForm,
      injectContractInformation,
      injectTrackingInfo,
      injectTariffInformation,
      injectPreliminaryContract,
      isInTenantPowerRegistrationProcess,
      trackingInfo,
      transferredFrom,
      newCustomerZipCode,
      reset,
      setNewContracts,
      resetSpecialCheckoutFlows,
    };
  },
  {
    persist: { storage },
  },
);
