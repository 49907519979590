export const formatNumber = (
  number: number | string,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
) => {
  if (typeof number === "string") number = Number(number);
  maximumFractionDigits = Math.max(minimumFractionDigits, maximumFractionDigits);

  if (Number.isInteger(number)) {
    minimumFractionDigits = 0;
  }

  return number.toLocaleString("de-DE", {
    minimumFractionDigits,
    maximumFractionDigits,
  });
};
