import { useStorage } from "@vueuse/core";
import { useRoute } from "vue-router";
import config from "~/config";

/**
 * This function takes care of persisting and returning the currently valid referral code.
 *
 * The current code can have different sources:
 * - The referral code from the URL
 * - The referral code from the local storage
 *
 * Some code are "more important" than other ones.
 * Therefore there is some business logic in place, that handles priorities.
 *
 * @returns Three functions: persistReferralCode(), getReferralCode() and resetReferralCode()
 */
const useReferralCode = () => {
  const route = useRoute();

  // TODO: useStorage() does always create the storage entry even if not used. Fix.
  const code = useStorage(config.recommendationCode.codeStorageKey, "");
  const codeHidden = useStorage(config.recommendationCode.isHiddenStorageKey, false);

  /**
   * This function takes care of persisting the currently valid referral code.
   */
  const persistReferralCode = () => {
    // For the unlikely case that multiple params are present, recommendationCode has the highest priority.
    const queryParams = [
      config.recommendationCode.queryParam,
      config.partnerCode.queryParam,
      config.cooperationCode.queryParam,
      config.campaignCode.queryParam,
    ];

    const queryValues = queryParams.map((param) => route.query[param]?.toString());

    // If a recommendation code is passed, save it to storage for later usage.
    code.value = queryValues.find((value) => value !== undefined) || "";

    // If it's a partnerCode, don't display it in the checkout later
    // TODO: This does not take cc and ac into account (edge case, but should be adjusted)
    codeHidden.value =
      !Object.keys(route.query).includes(config.recommendationCode.queryParam) &&
      Object.keys(route.query).includes(config.partnerCode.queryParam);
  };

  /**
   * This function returns the currently valid referral code.
   * @returns The currently valid referral code and it's UI-visibility state.
   */
  const getReferralCode = () => {
    return {
      code: code.value,
      isHidden: codeHidden.value,
    };
  };

  /**
   * This function resets the persisted referral code.
   */
  const resetReferralCode = () => {
    code.value = null;
    codeHidden.value = false;
  };

  return {
    persistReferralCode,
    getReferralCode,
    resetReferralCode,
  };
};

export default useReferralCode;
