import { isWithinInterval } from "date-fns";

/**
 * @param startDate Inclusive start date of the interval
 * @param endDate Inclusive end date of the interval
 * @returns If today is between two given dates.
 */
export default (startDate: string | Date, endDate: string | Date) => {
  return isWithinInterval(new Date(), {
    start: new Date(startDate),
    end: new Date(endDate),
  });
};
