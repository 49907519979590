import { AxiosError } from "axios";
import type { EmailAddressSubscriptionsEnum } from "~/src/generated-sources/public";
import { errorHandlerFactory } from "~/utils/error-mapper";

export default (
  newsletter: EmailAddressSubscriptionsEnum,
  fields: any,
  snackbar?: any,
) => {
  const { $brain } = useNuxtApp();

  const showSnackbar = (error: unknown, type: "error" | "info" = "error") => {
    const errorMessage = errorHandlerFactory(error);

    snackbar?.add({
      type,
      text: Object.values(errorMessage)[0],
    });
  };

  type EmailSubscriptionFields = { emailAddress: string } & (
    | { firstName: string; lastName: string; company?: string }
    | { firstName?: string; lastName?: string; company: string }
  );

  const createContact = async (
    newsletter: EmailAddressSubscriptionsEnum,
    fields: EmailSubscriptionFields,
  ) => {
    try {
      const response = await $brain.public.publicContact.createContact({
        id: "1", // TODO: Remove once type has been updated
        ...(fields.firstName && { firstName: fields.firstName }),
        ...(fields.lastName && { lastName: fields.lastName }),
        ...(fields.company && { companyName: fields.company }),
        type: fields.company ? "company" : "private",
        emailAddress: {
          value: fields.emailAddress,
          subscriptions: [newsletter],
        },
      });

      return response;
    } catch (error) {
      const isConflictError =
        error instanceof AxiosError && error?.response?.data.status === 409;
      showSnackbar(error, isConflictError ? "info" : "error");

      throw error;
    }
  };

  return createContact(newsletter, fields);
};
