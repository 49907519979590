import formatDate from "../customer-area/formatDate";
import { HTTPErrorHandlerBase, type BaseErrorPayload } from "./httpErrorHandlerBase";
import { FailedValidationProblem, Problem } from "./httpProblemType";

type InvalidPayload = {
  requestedBeginAt?: string;
  tariffIsValidFrom?: string;
  tariffIsValidTo?: string;
  zipCode?: string;
};

export interface ValidationErrorPayload extends BaseErrorPayload {
  /**
   * Dictionary containing validation errors from backend.
   * */
  validationErrors?: Record<string, string>;
  /**
   * Additional information about the validation error.
   * It can be obtained  for tariff validation problems.
   * Specifically, in case of FailedValidationRequestType.invalidRequestedBeginAt
   * or FailedValidationRequestType.invalidSupplyZipCode.
   *
   * See OpenAPI documentation for more details.
   */
  invalidPayload?: InvalidPayload;
}

/** Class handling 400 error response. */
export class ValidationBadRequestHandler extends HTTPErrorHandlerBase<FailedValidationProblem> {
  public static readonly validationErrors: {
    [key in FailedValidationProblem]?: string;
  } = {
    [FailedValidationProblem.invalidSupplyZipCode]:
      "Der Tarif ist für deine Lieferadresse nicht wählbar. Bitte hinterlege eine andere PLZ, oder wende dich an den Kundenservice.",
    [FailedValidationProblem.invalidForecastBasis]:
      "Der Tarif passt nicht zu dem von dir hinterlegten Zählverfahren. Bitte wähle einen anderen Tarif oder wende dich an den Kundenservice.",
    [FailedValidationProblem.invalidContractType]:
      "Für diesen Vertragstyp ist ein anderer Tarif vorhergesehen. Wähle einen anderen Tarif oder wende dich an den Kundenservice.",
    [FailedValidationProblem.invalidAnnualConsumption]:
      "Der Tarif kann nicht ausgewählt werden, da der angegebene Verbrauch zu hoch oder zu niedrig ist. Bitte ändere die Eingaben oder wende dich an den Kundenservice.",
    [FailedValidationProblem.invalidTariff]:
      "Der Tarif kann aktuell nicht ausgewählt werden. Bitte wähle einen anderen Tarif oder wende dich an den Kundenservice.",
    [FailedValidationProblem.iban]: "IBAN ungültig.",
    [FailedValidationProblem.emailAddress]: "E-mail Adresse ungültig.",
    [FailedValidationProblem.accountHolder]: "Kontoinhaber ungültig.",
    [FailedValidationProblem.hasAcceptedGtc]: "AGB müssen akzeptiert werden.",
    [FailedValidationProblem.password]: "Passwort entspricht nicht den Anforderungen.",
    [FailedValidationProblem.serialNumber]: "Zählernummer ungültig.",
    [FailedValidationProblem.tarrifId]:
      "Dieser Vertrag kann nicht umgezogen werden. Bitte wende dich an unseren Kundenservice..",
    [FailedValidationProblem.firstName]: "Vorname ungültig.",
  };

  public constructor(public override readonly payload: ValidationErrorPayload) {
    super(payload, ValidationBadRequestHandler.validationErrors);
  }

  private mapValidationErrors(validationErrors: Record<string, string>) {
    Object.keys(validationErrors).forEach((error: string) => {
      const errorKey = Object.values(FailedValidationProblem).find((errorType) =>
        error.includes(errorType),
      );

      if (errorKey) {
        this.errors[errorKey as FailedValidationProblem] =
          this.messages[errorKey as FailedValidationProblem];
      } else {
        console.info(`No error message found for key ${error}`); // TODO: remove & add to sentry - [BRN-371]
        this.errors = HTTPErrorHandlerBase.mapGeneralErrors(Problem.general);
      }
    });
  }

  private mapInvalidPayload({
    requestedBeginAt,
    tariffIsValidFrom,
    tariffIsValidTo,
  }: {
    requestedBeginAt: string;
    tariffIsValidFrom: string;
    tariffIsValidTo?: string;
  }) {
    const formattedRequestedBeginAt = formatDate(requestedBeginAt);
    const formattedTariffIsValidFrom = formatDate(tariffIsValidFrom);

    if (tariffIsValidTo) {
      this.errors[FailedValidationProblem.invalidRequestedBeginAt] =
        `Der Tarif ist für den gewünschten Lieferbeginn ${formattedRequestedBeginAt} nicht verfügbar, sondern nur von ${formattedTariffIsValidFrom} bis ${formatDate(
          tariffIsValidTo,
        )}. Bitte wende dich an den Kundenservice.`;
    } else {
      this.errors[FailedValidationProblem.invalidRequestedBeginAt] =
        `Der Tarif ist für den gewünschten Lieferbeginn ${formattedRequestedBeginAt} nicht verfügbar, sondern nur ab ${formattedTariffIsValidFrom}. Bitte wende dich an den Kundenservice.`;
    }
  }

  public mapError() {
    const problemType = Object.values(FailedValidationProblem).find((problem) =>
      this.type.includes(problem),
    );

    if (
      problemType === FailedValidationProblem.invalidRequestedBeginAt &&
      this.payload?.invalidPayload?.requestedBeginAt &&
      this.payload.invalidPayload.tariffIsValidFrom
    ) {
      const { requestedBeginAt, tariffIsValidFrom, tariffIsValidTo } =
        this.payload.invalidPayload;

      this.mapInvalidPayload({ requestedBeginAt, tariffIsValidFrom, tariffIsValidTo });
    } else if (problemType) {
      const reason = this.messages[problemType as FailedValidationProblem];
      if (reason) this.errors[problemType as FailedValidationProblem] = reason;
    } else if (this.payload.validationErrors) {
      // key in validationErrors might have `type.subtype` format
      this.mapValidationErrors(this.payload.validationErrors);
    }

    if (!Object.keys(this.errors).length) {
      this.errors = HTTPErrorHandlerBase.mapGeneralErrors(Problem.general);
    }

    return this.errors;
  }
}

export default ValidationBadRequestHandler;
