import { Problem } from "./httpProblemType";

export interface BaseErrorPayload {
  /**
   * HTTP status code of the error.
   *
   * For now only 400, 404 and 409 errors are handled by this class.
   * */
  status: 400 | 404 | 409;
  /**
   * The type of the problem as a (relative) URI.
   * E.g. /errors/request_validation_failed/invalid_requested_begin_at
   *
   * @type {string}
   */
  type: string;
  /**
   * A summary of the problem.
   * @type {string}
   */
  title: string;
  /**
   * A human-readable description of the problem.
   * @type {string}
   */
  details?: string;
}

/**
 *
 * Creates base class for handling errors from backend.
 *
 * @param payload - error response data from HTTP error.
 *
 * @param messages - records of human readable-error messages.
 *
 */
export class HTTPErrorHandlerBase<T extends string> {
  public errors: {
    [key in Problem | T]?: string;
  } = {};

  public static readonly generalErrors = {
    [Problem.general]:
      "Ein unbekannter Fehler ist aufgetreten. Bitte wende dich an den Kundenservice.",
    [Problem.network]:
      "Es ist ein Netzwerkfehler aufgetreten. Bitte versuche es später erneut.",
  };

  public readonly type: string;
  public readonly status: number;
  public readonly details: string | undefined;
  public messages: {
    [key in Problem | T]?: string;
  } = {};

  public constructor(
    public readonly payload: BaseErrorPayload,
    public readonly message?: { [key in T]?: string },
  ) {
    this.type = payload.type;
    this.status = payload.status;
    this.details = payload.details;
    this.messages = { ...message, ...HTTPErrorHandlerBase.generalErrors };
  }

  public static mapGeneralErrors(type: Problem): {
    [key in Problem]?: string;
  } {
    return { [type]: HTTPErrorHandlerBase.generalErrors[type] };
  }
}

export default HTTPErrorHandlerBase;
