<!-- This is a fallback component when component imports fail to resolve properly -->

<template>
  <div class="unknown-card" />
</template>

<style lang="scss">
.unknown-card {
  border: 3px solid black;
  background-color: white;
}
</style>
