<template>
  <div class="swiper-container" aria-label="Blätter-Navigation">
    <button
      v-if="active && displayArrows"
      ref="swiperButtonPrevElement"
      class="swiper-arrow swiper-button-prev"
      aria-label="Zurück blättern"
    ></button>
    <div
      ref="swiperElement"
      :class="['swiper', { 'swiper--active': active, 'swiper--inactive': !active }]"
    >
      <div class="swiper-wrapper" role="list">
        <slot />
      </div>
    </div>
    <button
      v-if="active && displayArrows"
      ref="swiperButtonNextElement"
      class="swiper-arrow swiper-button-next"
      aria-label="Weiter blättern"
    ></button>
    <div
      v-if="active"
      ref="swiperPaginationElement"
      class="swiper-pagination"
      aria-label="Seitennavigation"
      role="navigation"
    ></div>
  </div>
</template>

<script setup lang="ts">
import { Swiper } from "swiper";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css/bundle";
import config from "~/config";

const props = withDefaults(
  defineProps<{
    active?: boolean;
    delay?: number;
    displayArrows?: boolean;
    itemsAmount: number;
    loop?: boolean;
    slidesPerView?: number;
    fullWidthOnMobile?: boolean;
  }>(),
  {
    active: true,
    delay: config.swiper.autoChangeDelayDefault,
    displayArrows: true,
    loop: true,
    slidesPerView: 3,
    fullWidthOnMobile: true,
  },
);

const swiperElement = ref(null);
const swiperPaginationElement = ref(null);
const swiperButtonNextElement = ref(null);
const swiperButtonPrevElement = ref(null);

let swiperInstance: Swiper | null = null;

onMounted(() => {
  if (props.active && swiperElement.value) {
    swiperInstance = new Swiper(swiperElement.value, {
      spaceBetween: 10,
      loop: props.loop && props.itemsAmount > props.slidesPerView,
      nested: true,
      observer: true,
      effect: "cards",
      grabCursor: true,
      modules: [Navigation, Pagination, Autoplay],
      watchOverflow: true,
      autoplay: props.itemsAmount > 3 && { delay: props.delay },
      slidesPerView: props.fullWidthOnMobile ? 1 : props.slidesPerView,
      watchSlidesProgress: true,
      pagination: {
        el: swiperPaginationElement.value,
        type: "custom",
        clickable: true,
        // https://github.com/nolimits4web/swiper/issues/2915
        renderCustom: function (_, current, total) {
          const paginationBullets = Array.from({ length: total }, (_, i) => {
            const paginationIndex = i + 1;
            const isCurrent = paginationIndex === current;
            const classes = isCurrent
              ? "swiper-pagination-bullet swiper-pagination-bullet-active"
              : "swiper-pagination-bullet";

            return `<button
          class="${classes}"
          aria-current="${isCurrent}"
          aria-label="Zu Seite ${paginationIndex}"
        ></button>`;
          });

          return paginationBullets.join(" ");
        },
      },
      navigation: {
        nextEl: swiperButtonNextElement.value,
        prevEl: swiperButtonPrevElement.value,
      },
      breakpoints: {
        [config.breakpoints.sm]: {
          slidesPerView: props.slidesPerView,
          spaceBetween: 16,
        },
      },
    });
  }
});

onUnmounted(() => {
  if (swiperInstance) {
    swiperInstance.destroy();
  }
});
</script>

<style lang="scss">
$arrow-spacing: -0.75rem;

.swiper-container {
  position: relative;

  .swiper-arrow {
    background: var(--background);
    border: none;
    color: var(--foreground);
    &::after {
      font-size: 1.5rem;
    }

    &.swiper-button-prev {
      left: $arrow-spacing;
    }

    &.swiper-button-next {
      right: $arrow-spacing;
    }

    &:focus-visible {
      // Make sure the focus stylings don't crash the special arrow styling
      position: absolute;
      z-index: 10;
    }

    @include breakpoint-from(small) {
      $arrow-spacing: -2rem;

      &.swiper-button-prev {
        left: $arrow-spacing;
      }

      &.swiper-button-next {
        right: $arrow-spacing;
      }
    }
  }
}

.swiper--inactive {
  .swiper-wrapper {
    flex-direction: column;
    @include breakpoint-from(small) {
      flex-direction: row;
    }
  }
}

.swiper-slide {
  display: flex;
  justify-content: center;
  visibility: visible;
  transition: visibility var(--transition-slow) linear;

  &:not(.swiper-slide-visible) {
    // Hide non-visible slide from screen reader
    visibility: hidden;
    // Add transition to avoid flickering
    transition: visibility var(--transition-slow) linear;
  }
}

.swiper-pagination {
  $bullet-height: 0.3rem;
  $bullet-width: 2.75rem;
  $bullet-narrow: 1.5rem;
  $bullet-square: 1rem;

  margin-top: 10px;
  position: initial;

  .swiper-pagination-bullet {
    border-radius: 0;
    margin-right: calc(var(--gap) / 2);
    height: $bullet-square;
    width: $bullet-square;
    opacity: 1;

    @include breakpoint-from(small) {
      height: $bullet-height;
      width: $bullet-narrow;
    }

    @include breakpoint-from(large) {
      width: $bullet-width;
    }

    &.swiper-pagination-bullet-active {
      background-color: var(--ps-grey-medium_deprecated);
    }
  }
}
</style>
