import { PublicClientApplication } from "@azure/msal-browser";
import AzureB2cClient from "~/auth/azure-b2c-client";
import { getRolesFromJwt } from "~/utils/rolesHelper";

/**
 * Our plugin for taking care of admin tool logins:
 * - Create AzureB2cClient with proper credentials
 * - Request user from client
 * - Save user to store
 */
export default defineNuxtPlugin(async (nuxtApp) => {
  const {
    baseUrl,
    adminAuth: { clientId, authority, scopes },
  } = nuxtApp.$config.public;
  const msalInstance = await PublicClientApplication.createPublicClientApplication({
    auth: {
      clientId,
      authority,
      knownAuthorities: [], // TODO: Needed?
      navigateToLoginRequestUrl: false,
      redirectUri: baseUrl,
      postLogoutRedirectUri: "/",
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
  });
  const router = useRouter();
  const adminAuthStore = useAdminAuthStore();
  const adminAuthClient = new AzureB2cClient("admin", msalInstance, clientId, [scopes]);

  const removeHook = router.beforeEach(async () => {
    removeHook();
    await adminAuthClient.handleRedirect();

    const account = adminAuthClient.getUserAccount();
    if (account) {
      adminAuthStore.setUserAccount(account);
      adminAuthStore.setEmail(account);
      const token = await adminAuthClient.getToken();
      if (token) {
        adminAuthStore.setRoles(getRolesFromJwt(token));
      }
    }
  });

  const account = adminAuthClient.getUserAccount();
  if (account) {
    adminAuthStore.setUserAccount(account);
    adminAuthStore.setEmail(account);
    const token = await adminAuthClient.getToken();
    if (token) {
      adminAuthStore.setRoles(getRolesFromJwt(token));
    }
  }

  return {
    provide: {
      adminAuth: adminAuthClient,
    },
  };
});
