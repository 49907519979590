import type { AxiosResponse } from "axios";
import type {
  CustomerType,
  GetTariffsById200Response,
  TariffType,
} from "~/src/generated-sources/public";
import type { FetchTariffsCallback } from "~/types/shared/fetch-tariffs-callback";

export const getTariffsById = async (ids: string[]) => {
  const { $brain } = useNuxtApp();
  // As a uri header must not be longer than 2000 character, we need to split the ids into chunks
  const uniqueIds = Array.from(new Set(ids));
  const chunkSize = 50;
  const chunkedIds = [];

  for (let i = 0; i < uniqueIds.length; i += chunkSize) {
    chunkedIds.push(uniqueIds.slice(i, i + chunkSize));
  }

  const promises: Promise<AxiosResponse<GetTariffsById200Response, any>>[] =
    chunkedIds.map((chunk) => $brain.public.tariffs.getTariffsById(chunk));

  const tariffs: AxiosResponse<GetTariffsById200Response, any>[] = await Promise.all(
    promises.map((promise) => promise.catch((error) => error)),
  );
  return tariffs.filter((t) => t.status <= 299).flatMap((t) => t.data.items);
};

/**
 * Helper composable that wraps the fetchTariffsCallback in a useAsyncData call
 * and adds caching functionality.
 *
 * @param fetchTariffsCallback The callback to fetch the tariffs.
 * @param type The type of the tariffs to fetch.
 * @param zipCode The zip code to fetch the tariffs for.
 * @param annualConsumption The annual consumption to fetch the tariffs for.
 * @param partnerId The partner id to fetch the tariffs for.
 * @param annualConsumptionLowTariff The low annual consumption to fetch the tariffs for.
 * @param applicableForCustomerType The customer type to fetch the tariffs for.
 * @returns The cached or freshly requested data.
 */
export const useCachedAsyncTariffData = async (
  fetchTariffsCallback: FetchTariffsCallback,
  type: TariffType,
  zipCode: string,
  annualConsumption: number,
  partnerId?: string,
  annualConsumptionLowTariff?: number,
  applicableForCustomerType?: CustomerType,
) => {
  const nuxtApp = useNuxtApp();
  const result = await useAsyncData(
    type,
    async () =>
      await fetchTariffsCallback(
        type,
        zipCode,
        annualConsumption,
        partnerId,
        annualConsumptionLowTariff,
        applicableForCustomerType,
      ),
    {
      transform: (input) => {
        return {
          ...input,
          queryParams: {
            type,
            zipCode,
            annualConsumption,
            partnerId,
            annualConsumptionLowTariff,
            applicableForCustomerType,
          },
        };
      },
      getCachedData: (key): any => {
        // Hint: We might need to implement custom TTL logic if we switch to SSR some day.
        const cachedData = nuxtApp.payload.data[key] || nuxtApp.static.data[key];
        if (!cachedData) return;

        // Compare if the cached data used the same query params:
        if (
          cachedData.queryParams.type === type &&
          cachedData.queryParams.zipCode === zipCode &&
          cachedData.queryParams.annualConsumption === annualConsumption &&
          cachedData.queryParams.partnerId === partnerId &&
          cachedData.queryParams.annualConsumptionLowTariff ===
            annualConsumptionLowTariff &&
          cachedData.queryParams.applicableForCustomerType === applicableForCustomerType
        ) {
          return cachedData;
        }
      },
    },
  );

  return {
    ...result,
    data: result.data.value?.data.items || [],
  };
};
