import routeTo from "./routeTo";
import type { LinkTarget } from "~/types/cms/link-target";

export default (linkTarget: LinkTarget): string => {
  const { $cmsFileUrl } = useNuxtApp();

  switch (linkTarget.cmsType) {
    case "linkTargetInternal": {
      // TODO: Handle anchor scrolls
      const refPageType = linkTarget.pageReference.cmsType;

      if (
        ["faqOverview", "magazineOverview", "landingPage", "pressOverview"].includes(
          refPageType,
        ) ||
        !linkTarget.pageReference.slug
      ) {
        return routeTo(refPageType);
      }

      const slug = linkTarget.pageReference.slug.current;

      return `${routeTo(refPageType, slug)}${
        linkTarget.anchor ? `#${linkTarget.anchor}` : ""
      }`;
    }
    case "linkTargetExternal":
      return linkTarget.url;

    case "download":
      return $cmsFileUrl(linkTarget.file.asset);

    default:
      return "/";
  }
};
