<template>
  <ConsumptionBidirectionalCalculationInput
    label-aria="Stromverbrauchsauswahl nach Mitarbeiteranzahl oder Kilowattstunden"
    :option-in-k-wh="{ label: 'Jahresverbrauch in kWh', id: 'targetConsumptionInput' }"
    :other-option="{
      label: 'Anzahl Mitarbeiter:innen',
      id: 'employeeCountInput',
    }"
    :initial-power-consumption="powerConsumption"
    :show-vertically="props.showVertically"
    :factor="factor"
    @update-power-consumption="$emit('updatePowerConsumption', $event)"
  />
</template>

<script setup lang="ts">
import ConsumptionBidirectionalCalculationInput from "~/components/02_molecules/tariff-calculator/ConsumptionBidirectionalCalculationInput.vue";

defineEmits<{
  (eventName: "updatePowerConsumption", powerConsumption: number): void;
}>();

const props = defineProps<{
  powerConsumption: number;
  factor: number;
  showVertically?: boolean;
}>();
</script>

<style lang="scss"></style>
