/**
 * Add trailing slash to the path if it doesn't have one.
 *
 * @param path The path to add trailing slash to.
 * @returns The path with trailing slash.
 */
const addTrailingSlash = (path: string): string => {
  const hasTrailingSlash = path.endsWith("/");
  const hasQueryOrHashProperty = path.includes("#") || path.includes("?");

  return hasTrailingSlash || hasQueryOrHashProperty ? path : `${path}/`;
};

export default addTrailingSlash;
