import type { ProductGroupValue } from "~/types/shared/calculator";
import type { CustomerType } from "~/src/generated-sources/public";

export interface TariffCalculatorShareLinkAttributes {
  finalPostalCode: string;
  pickedGasConsumption: number;
  pickedPowerHighConsumption: number;
  pickedPowerLowConsumption: number | null;
  activeTariffChoice: ProductGroupValue | undefined;
  activeTabIndex: number;
  doubleMeter: boolean;
  additionalMeter: boolean;
  customerType: CustomerType;
}

type ShortenedKeys = {
  [key in keyof TariffCalculatorShareLinkAttributes]: string;
};

const shortenedKeys: ShortenedKeys = {
  finalPostalCode: "zip",
  pickedGasConsumption: "gc",
  pickedPowerHighConsumption: "phc",
  pickedPowerLowConsumption: "plc",
  activeTariffChoice: "atc",
  activeTabIndex: "tab",
  doubleMeter: "dm",
  additionalMeter: "am",
  customerType: "ct",
} as const;

/**
 * This function takes the attributes of the tariff calculator and returns a shortened URL query string.
 * @param attributes The attributes of the tariff calculator state.
 * @returns A shortened URL query string.
 */
export const shareLinkShortener = (
  attributes: TariffCalculatorShareLinkAttributes,
): URLSearchParams => {
  const shortenedAttributes: Record<string, any> = {};
  Object.keys(attributes).forEach((key) => {
    const shortenedKey = shortenedKeys[key as keyof TariffCalculatorShareLinkAttributes];
    if (shortenedKey) {
      shortenedAttributes[shortenedKey] =
        attributes[key as keyof TariffCalculatorShareLinkAttributes];
    }
  });

  const params = new URLSearchParams();
  for (const key in shortenedAttributes) params.append(key, shortenedAttributes[key]);
  return params;
};

/**
 * This function takes a URL query string and returns the attributes of the tariff calculator state.
 * @param queryParams The URL query string.
 * @returns The attributes of the tariff calculator state.
 */
export const shareLinkDecryptor = (
  queryParams: URLSearchParams,
): Partial<TariffCalculatorShareLinkAttributes> => {
  const decryptedAttributes: Record<string, any> = {};
  queryParams.forEach((value, key) => {
    const decryptedKey = Object.keys(shortenedKeys).find(
      (shortenedKey) => shortenedKeys[shortenedKey as keyof ShortenedKeys] === key,
    );
    if (decryptedKey) {
      let res;
      try {
        res = JSON.parse(value, (_k, value) => {
          // Special case zip code: It's a string!
          if (decryptedKey === "finalPostalCode") return value.toString();
          return value;
        });
      } catch {
        res = value;
      }
      decryptedAttributes[decryptedKey] = res;
    }
  });
  return decryptedAttributes as Partial<TariffCalculatorShareLinkAttributes>;
};
