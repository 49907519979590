import { jwtDecode } from "jwt-decode";

/**
 * The roles that can be assigned to a user in the AdminTool.
 */
export enum AdminToolRoles {
  ReadWriteAll = "Admin.ReadWrite.All",
  ReadWriteCustomerContracts = "Admin.ReadWrite.CustomerContracts",
  WriteTenantPower = "Admin.Write.TenantPower",
  ReadTenantPower = "Admin.Read.TenantPower",
}

export const AdminToolRoleTranslations = {
  [AdminToolRoles.ReadWriteAll]: "AdminTool-Lese- und Schreibrechte für alles 💪",
  [AdminToolRoles.ReadWriteCustomerContracts]:
    "AdminTool-Lese- und Schreibrechte für Kundenverträge",
  [AdminToolRoles.WriteTenantPower]: "AdminTool-Schreibrechte für Mieterstrom",
  [AdminToolRoles.ReadTenantPower]: "AdminTool-Leserechte für Mieterstrom",
};

/**
 * Extracts the AdminToolRoles from a JWT token.
 * @param jwt The JWT token to extract the roles from.
 * @returns An array of AdminToolRoles the user has assigned.
 */
export const getRolesFromJwt = (jwt: string): AdminToolRoles[] => {
  const roles: AdminToolRoles[] = [];
  jwtDecode<{ roles: string[] }>(jwt).roles.forEach((role) => {
    const newRole = enumFromStringValue(AdminToolRoles, role);
    if (newRole) roles.push(newRole);
  });
  return roles;
};

/**
 * A helper that converts a string to an enum value.
 * @param enm The enum to convert the string to.
 * @param value The string to convert to an enum value.
 * @returns The enum value if it exists, otherwise undefined.
 */
const enumFromStringValue = <T>(
  enm: { [s: string]: T },
  value: string,
): T | undefined => {
  return (Object.values(enm) as unknown as string[]).includes(value)
    ? (value as unknown as T)
    : undefined;
};
