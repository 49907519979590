<template>
  <div class="tariff--calculations" :class="[classes]">
    <TariffDetailCalculationsAdvancePayment
      :is-business-calculation="isBusinessCalculation"
      :is-price-cap-calculated="isPriceCapCalculated"
      :tariff="tariff"
      :target-gas-consumption="targetGasConsumption"
      :target-power-high-consumption="targetPowerHighConsumption"
      :target-power-low-consumption="targetPowerLowConsumption"
      :price-override="priceOverride"
    />
    <div>
      <div>
        <table class="price-details-table">
          <tbody>
            <tr>
              <td>Grundpreis pro Monat</td>
              <td>{{ formatPrice(basePricePerMonth) }} €</td>
            </tr>

            <!-- Price Cap active -->
            <template v-if="advancePaymentWithoutPriceCap > advancePayment">
              <tr>
                <td>
                  {{ workingPricePerMonth }}
                </td>
                <td></td>
              </tr>
              <tr>
                <td>- Für 80% des Verbrauchs</td>
                <td>
                  {{ priceCapPrice }}
                  Cent
                </td>
              </tr>
              <tr>
                <td>- Für 20% des Verbrauchs</td>
                <td>
                  {{ pricePerUnitHighConsumptionText }}
                  Cent
                </td>
              </tr>
            </template>
            <!-- HT/NT Tariff -->
            <template
              v-else-if="!!targetPowerLowConsumption && activeLowTariffPriceRange"
            >
              <tr>
                <td>HT-Arbeitspreis pro kWh</td>
                <td>
                  {{ pricePerUnitHighConsumptionText }}
                  Cent
                </td>
              </tr>
              <tr>
                <td>NT-Arbeitspreis pro kWh</td>
                <td>
                  {{ pricePerUnitLowConsumptionText }}
                  Cent
                </td>
              </tr>
            </template>
            <!-- "Normal Tariff" -->
            <tr v-else>
              <td>
                {{ workingPricePerMonth }}
              </td>
              <td>
                {{ pricePerUnitHighConsumptionText }}
                Cent
              </td>
            </tr>
            <tr v-if="isOwnPowerTariff(tariff)">
              <td>Eigenstrom-Bonus</td>
              <td>2,5 Cent/kWh</td>
            </tr>

            <tr class="no-bold">
              <td>
                Mindestvertragslaufzeit
                <InfoTooltip
                  title="Mindestvertragslaufzeit"
                  text="Der Vertrag läuft mindestens die genannte Anzahl an Monaten. Du kannst ihn stets mit einer Frist von 4 Wochen vor Ablauf dieser Zeit kündigen. Nach Ende der Mindestvertragslaufzeit läuft der Vertrag auf unbestimmte Zeit weiter und ist dann jederzeit mit einer Frist von vier Wochen kündbar."
                  horizontal-offset="105"
                />
              </td>
              <td>{{ getMinimumContractTermText(tariff) }}</td>
            </tr>
            <tr class="no-bold">
              <td>
                Preisgarantie
                <InfoTooltip
                  title="Preisgarantie"
                  text="Die Preise sind ab Lieferbeginn bis zum Ende des genannten Zeitraums garantiert.
Ausgenommen von der Preisgarantie sind etwaige Änderungen der Umsatz- und/oder Stromsteuer sowie sonstige gesetzliche Abgaben, die Polarstern während der Garantiezeit zur Preisanpassung berechtigen. Preisänderungen der durch Polarstern beeinflussbaren Kostenbestandteile werden mit einer Frist von mindestens einem Monat vor Inkrafttreten mitgeteilt."
                  horizontal-offset="105"
                />
              </td>

              <td>{{ getPriceGuaranteeText(tariff) }}</td>
            </tr>
            <tr>
              <td colspan="2">
                {{
                  isBusinessCalculation
                    ? "Alle Preise zzgl. Umsatzsteuer."
                    : "inkl. aller Steuern und Abgaben."
                }}
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>

        <details class="price-details--calculation mt-3">
          <summary class="mb-2">Mehr Tarif-Details</summary>
          <div>
            <span class="d-block fw-bold">Monatliche Kosten:</span>

            <template v-if="isClassicPowerFlexPlus">
              Dein Verbrauch wird monatlich auf Basis des dynamischen Arbeitspreises
              abgerechnet.
            </template>
            <template v-else-if="isClassicPowerFlex || isClassicGasFlex">
              Dein Abschlag wird monatlich auf Basis des variablen Arbeitspreises neu
              errechnet und kann nicht geändert werden.
            </template>
            <template v-else>
              Basierend auf deinen Daten ergibt sich ein monatlicher Abschlag von
              <span class="fw-bold">{{ formatPrice(advancePayment) }} €</span>.
            </template>

            <span class="d-block fw-bold my-2"
              >So errechnen sich deine Jahreskosten:
            </span>

            <div class="mb-2">
              <span class="d-block">Grundpreis</span>
              {{ formatPrice(basePricePerMonth) }} €/Monat x 12 Monate
              <span class="d-block fw-bold"
                >= {{ formatPrice(basePricePerYear) }} €
              </span>
            </div>

            <!-- Double meter-->
            <div v-if="targetPowerLowConsumption" class="mb-2">
              <span class="d-block">Arbeitspreis</span>
              HT:
              {{ pricePerUnitHighConsumptionText }} Cent/kWh x
              {{ annualHighConsumption }} kWh =
              {{ formatPrice(pricePerUnitHighConsumptionAnnualPrice) }} €
              <br />
              NT:
              {{ pricePerUnitLowConsumptionText }} Cent/kWh x
              {{ annualLowConsumption }} kWh =
              {{ formatPrice(pricePerUnitLowConsumptionAnnualPrice) }} €
              <br />
              =
              {{ formatPrice(pricePerUnitHighConsumptionAnnualPrice) }} € +
              {{ formatPrice(pricePerUnitLowConsumptionAnnualPrice) }} €
              <span class="d-block fw-bold"
                >=
                {{
                  formatPrice(
                    pricePerUnitHighConsumptionAnnualPrice +
                      pricePerUnitLowConsumptionAnnualPrice,
                  )
                }}
                €
              </span>
            </div>

            <!-- Single meter-->
            <div v-else class="mb-2">
              <span class="d-block">Arbeitspreis</span>
              {{ pricePerUnitHighConsumptionText }}
              Cent/kWh x
              {{ annualHighConsumption }} kWh
              <span class="d-block fw-bold"
                >=
                {{ formatPrice(pricePerUnitHighConsumptionAnnualPrice) }}
                €
              </span>
            </div>

            <div v-if="isOwnPowerTariff(tariff)" class="mb-2">
              <span class="d-block">Eigenstrom-Bonus (in Jahresrechnung)</span>
              ({{ annualHighConsumption }} kWh / 0,7) x 0,3 x 2,5 Cent =
              <span class="d-block">
                {{ formatPrice(ownPowerConsumption) }} kWh x 2,5 Cent
              </span>
              <span class="d-block fw-bold"> = {{ formatPrice(ownPowerBonus) }} € </span>
            </div>

            <div class="mb-2">
              <span class="d-block">Jahreskosten</span>
              {{ formatPrice(basePricePerYear) }} €
              <span v-if="targetPowerLowConsumption">
                +
                {{
                  formatPrice(
                    pricePerUnitHighConsumptionAnnualPrice +
                      pricePerUnitLowConsumptionAnnualPrice,
                  )
                }}
                €
              </span>
              <span v-else>
                + {{ formatPrice(pricePerUnitHighConsumptionAnnualPrice) }} €
              </span>
              <span v-if="tariff.subType === 'own'">
                - {{ formatPrice(ownPowerBonus) }} €
              </span>

              <span class="d-block fw-bold"
                >=
                {{
                  formatPrice(
                    basePricePerYear +
                      pricePerUnitHighConsumptionAnnualPrice +
                      pricePerUnitLowConsumptionAnnualPrice -
                      ownPowerBonus,
                  )
                }}
                €
              </span>
            </div>

            <!-- Special tariff descriptions. -->
            <div v-if="isClassicPowerFlex || isClassicGasFlex" class="mb-2">
              <span class="d-block mt-2">{{
                isClassicPowerFlexPlus
                  ? powerClassicFlexPlusPricePerUnitHint
                  : isClassicPowerFlex
                    ? powerClassicFlexPricePerUnitHint
                    : gasClassicFlexPricePerUnitHint
              }}</span>
            </div>

            <div
              v-else-if="
                tariff.subType === 'carPower' || tariff.subType === 'carPowerPlus'
              "
              class="mb-2"
            >
              Geschenkt: 25-Euro-Gutschein für Ladezubehör von unserem Partner The
              Mobility House.
              <br />
              Senke deine Gesamtkosten mit dem Verkauf deiner THG-Quote.
              <TheLink :to="config.routes.thgPage"> Klicke hier für mehr Infos. </TheLink>
            </div>
          </div>
        </details>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TariffDetailCalculationsAdvancePayment from "./TariffDetailCalculationsAdvancePayment.vue";
import InfoTooltip from "~/components/01_atoms/InfoTooltip.vue";
import TheLink from "~/components/01_atoms/TheLink.vue";
import config from "~/config";
import type {
  PublicTariffWithSummarizedPriceRanges as PublicTariff,
  TariffPriceRange,
} from "~/src/generated-sources/public";
import type { CssClasses } from "~/types/shared/css-classes";
import calculateLastMonth from "~/utils/calculateLastMonth";
import {
  isFlexPlusPowerTariff,
  isFlexTariff,
  isOwnPowerTariff,
} from "~/utils/customer-area/getTariffForContract";
import getMinimumContractTermInMonths from "~/utils/getMinimumContractTermInMonths";
import {
  calculateOwnPowerBonusForConsumption,
  calculateOwnPowerConsumption,
} from "~/utils/tariff-calculator/calculateOwnPowerBonus";
import {
  CAPPED_GAS_PRICE_PER_UNIT_NETTO,
  CAPPED_POWER_PRICE_PER_UNIT_NETTO,
  calculateAdvancePayment,
  formatPrice,
  getPriceRange,
  roundByTwo,
} from "~/utils/tariff-calculator/priceCalculation";

const props = defineProps<{
  isBusinessCalculation?: boolean;
  isPriceCapCalculated?: boolean;
  tariff: PublicTariff;
  targetGasConsumption: number;
  targetPowerHighConsumption: number;
  targetPowerLowConsumption: number | null; // TODO
  priceOverride?: number;
  classes?: CssClasses;
}>();

const annualHighConsumption = computed(() =>
  props.tariff.type === "power"
    ? props.targetPowerHighConsumption
    : props.targetGasConsumption,
);

const annualLowConsumption = computed(() => props.targetPowerLowConsumption || 0);

const advancePayment = computed(() =>
  calculateAdvancePayment(
    annualHighConsumption.value,
    props.tariff,
    !props.isPriceCapCalculated,
    !props.isBusinessCalculation,
    props.targetPowerLowConsumption || undefined,
  ),
);
const advancePaymentWithoutPriceCap = computed(() =>
  calculateAdvancePayment(
    annualHighConsumption.value,
    props.tariff,
    true,
    !props.isBusinessCalculation,
    props.targetPowerLowConsumption || undefined,
  ),
);

const activePriceRange = computed(() =>
  getPriceRange(props.tariff.priceRanges, annualHighConsumption.value),
);

const activeLowTariffPriceRange = computed(() =>
  props.targetPowerLowConsumption && props.tariff.lowTariffPriceRanges
    ? getPriceRange(props.tariff.lowTariffPriceRanges, props.targetPowerLowConsumption)
    : undefined,
);

const calculatePricePerUnit = (priceRange: ComputedRef<TariffPriceRange | undefined>) => {
  if (priceRange.value) {
    const pricePerUnit =
      parseFloat(priceRange.value.pricePerUnit.value) * taxFactor.value;
    return roundByTwo(pricePerUnit * 100) / 100; // TODO: Maybe create a util function for that
  }
};

const ownPowerConsumption = computed(() => {
  if (isOwnPowerTariff(props.tariff)) {
    return calculateOwnPowerConsumption(annualHighConsumption.value);
  }

  return 0;
});

const ownPowerBonus = computed(() => {
  return calculateOwnPowerBonusForConsumption(ownPowerConsumption.value);
});

const getFormattedPrice = (
  value: ComputedRef<number | undefined> | number | undefined,
  inCents = true,
) => {
  const number = unref(value);
  if (!number) {
    return "Bei der Preisberechnung ist ein Fehler aufgetreten.";
  }

  return formatPrice(number, true, inCents);
};

const pricePerUnitHighConsumption = computed(() =>
  calculatePricePerUnit(activePriceRange),
);

const pricePerUnitLowConsumption = computed(() =>
  calculatePricePerUnit(activeLowTariffPriceRange),
);

const pricePerUnitHighConsumptionText = computed(() =>
  getFormattedPrice(pricePerUnitHighConsumption),
);

const pricePerUnitLowConsumptionText = computed(() =>
  getFormattedPrice(pricePerUnitLowConsumption),
);

const pricePerUnitHighConsumptionAnnualPrice = computed(() => {
  if (pricePerUnitHighConsumption.value) {
    return pricePerUnitHighConsumption.value * annualHighConsumption.value;
  }

  return 0;
});

const pricePerUnitLowConsumptionAnnualPrice = computed(() => {
  if (pricePerUnitLowConsumption.value) {
    return pricePerUnitLowConsumption.value * annualLowConsumption.value;
  }

  return 0;
});

const basePricePerMonth = computed(() => {
  const basePrice =
    parseFloat(activePriceRange.value?.basePrice.value || "0") * taxFactor.value;

  return roundByTwo(basePrice / 12);
});

/* To prevent rounding errors during division, we determine the rounded, monthly value
 * of the base price first and then multiply it by 12.
 */
const basePricePerYear = computed(() => basePricePerMonth.value * 12);

const taxFactor = computed(() =>
  props.isBusinessCalculation ? 1 : (props.tariff.valueAddedTax || 0) + 1,
);

const isClassicPowerFlex = computed(
  () => props.tariff.type === "power" && isFlexTariff(props.tariff),
);
const isClassicPowerFlexPlus = computed(
  () => props.tariff.type === "power" && isFlexPlusPowerTariff(props.tariff),
);
const isClassicGasFlex = computed(
  () => props.tariff.type === "gas" && isFlexTariff(props.tariff),
);

const workingPricePerMonth = computed(
  () =>
    `Arbeitspreis pro kWh${
      isFlexTariff(props.tariff) ? ` (${calculateLastMonth()})` : ""
    }`,
);

const priceCapPrice = computed(() => {
  return formatPrice(
    props.tariff.type === "power"
      ? CAPPED_POWER_PRICE_PER_UNIT_NETTO * taxFactor.value
      : CAPPED_GAS_PRICE_PER_UNIT_NETTO * taxFactor.value,
    true,
    true,
  );
});

const formatMonthsText = (months: number) => {
  return months <= 1 ? "1 Monat" : `${months} Monate`;
};

const getMinimumContractTermText = (tariff: PublicTariff) => {
  const minimumContractTerm = getMinimumContractTermInMonths(tariff);

  return minimumContractTerm <= 1 ? "keine" : formatMonthsText(minimumContractTerm);
};

const getPriceGuaranteeText = (tariff: PublicTariff) => {
  if (isFlexTariff(tariff) && (tariff.priceGuaranteeDurationInMonths || 1) <= 1) {
    return "keine";
  }
  return formatMonthsText(tariff.priceGuaranteeDurationInMonths || 1);
};

const powerClassicFlexPricePerUnitHint =
  "Im Arbeitspreis enthalten sind die aktuellen Preise am Spotmarkt der Strombörse EPEX Spot SE, Netzentgelte, Umlagen, Abgaben und Steuern sowie unsere Servicegebühr (3,00 Cent/kWh) für Stromhandel, Herkunftsnachweise und Neuanlagenförderung. Der angegebene Arbeitspreis basiert auf den gewichteten Spotmarktpreisen des letzten Kalendermonats und unseren Informationen seitens des zuständigen Netzbetreiber zum Lieferstandort.";
const powerClassicFlexPlusPricePerUnitHint =
  "Im Arbeitspreis enthalten sind die aktuellen Preise am Spotmarkt der Strombörse EPEX Spot SE, Netzentgelte, Umlagen, Abgaben und Steuern sowie unsere Servicegebühr (3,00 Cent/kWh) für Stromhandel, Herkunftsnachweise und Neuanlagenförderung. Der angegebene Arbeitspreis basiert auf den durchschnittlichen Spotmarktpreisen des letzten Kalendermonats und unseren Informationen seitens des zuständigen Netzbetreiber zum Lieferstandort.";
const gasClassicFlexPricePerUnitHint =
  "Im Arbeitspreis enthalten sind die am Spotmarkt der Energiebörse EEX im Einkauf erzielten Tagespreise sowie Netzentgelte, Umlagen, Abgaben, Steuern und unsere Servicegebühr (3,50 Cent/kWh) für Gashandel, Herkunftsnachweise und Neuanlagenförderung. Der angegebene Arbeitspreis basiert auf dem temperaturabhängigen Gaseinkauf und den gewichteten Spotmarktpreisen des letzten Kalendermonats sowie unseren Informationen seitens des zuständigen Netzbetreiber zum Lieferstandort.";
</script>

<style lang="scss">
.price-details-table {
  width: 100%;
  max-width: 400px; // Relevant for the data confirmation step

  tr:not(.no-bold) td:last-child {
    font-weight: bold;
    white-space: nowrap;
  }
}

.price-details--calculation {
  & > summary {
    list-style: none;
  }
  summary::-webkit-details-marker {
    display: none;
  }

  summary::after {
    content: " ▼";
  }
  &[open] summary:after {
    content: " ▲";
  }
}
</style>
