import axios from "axios";
import {
  Configuration,
  ContractsApi,
  CustomersApi,
  DocumentsApi,
  TransfersApi,
} from "~/src/generated-sources/core";
import {
  ContactsApi as PublicContactsApi,
  CustomersApi as PublicCustomersApi,
  MessagesApi,
  OrdersApi,
  SuppliersApi,
  RecommendationsApi,
  TariffsApi,
  TenantPowerApi,
  TokenVerificationApi,
  TransfersApi as PublicTransfersApi,
  NewsletterApi,
  CodesApi,
} from "~/src/generated-sources/public";
import {
  ContractsApi as AdminContractsApi,
  CustomersApi as AdminCustomersApi,
  DocumentsApi as AdminDocumentsApi,
  TariffsApi as AdminTariffsApi,
  LabelsApi as AdminLabelsApi,
  TokenVerificationApi as AdminTokenVerificationApi,
  ContractErrorsApi as AdminContractErrorsApi,
  TenantPowerApi as AdminTenantPowerApi,
  MeasuringPointsApi as AdminMeasuringPointsApi,
} from "~/src/generated-sources/admin";

export default defineNuxtPlugin((nuxtApp) => {
  const { brainApiBaseUrl } = nuxtApp.$config.public;

  const { $auth, $adminAuth } = useNuxtApp();

  const coreConfiguration = new Configuration({
    basePath: `${brainApiBaseUrl}/core/v1`,
  });

  const publicConfiguration = new Configuration({
    basePath: `${brainApiBaseUrl}/public/v1`,
  });

  const adminConfiguration = new Configuration({
    basePath: `${brainApiBaseUrl}/admin/v1`,
  });

  const axiosApiInstance = axios.create();

  axiosApiInstance.interceptors.request.use(
    async (config) => {
      config.headers.Authorization = `Bearer ${await $auth.getToken()}`;
      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  const adminAxiosApiInstance = axios.create();

  adminAxiosApiInstance.interceptors.request.use(
    async (config) => {
      config.headers.Authorization = `Bearer ${await $adminAuth.getToken()}`;
      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  /**
   * TODO: add client side error logger - [BRN-371]
   *
   * Example:
   * axiosApiInstance.interceptors.response.use((response)=>response, (error) => Sentry.captureException(error));
   */

  // Core APIs
  const contracts = new ContractsApi(coreConfiguration, undefined, axiosApiInstance);
  const transfers = new TransfersApi(coreConfiguration, undefined, axiosApiInstance);
  const customer = new CustomersApi(coreConfiguration, undefined, axiosApiInstance);
  const documents = new DocumentsApi(coreConfiguration, undefined, axiosApiInstance);

  // Public APIs
  const messages = new MessagesApi(publicConfiguration);
  const orders = new OrdersApi(publicConfiguration);
  const publicContact = new PublicContactsApi(publicConfiguration);
  const publicCustomer = new PublicCustomersApi(publicConfiguration);
  const recommendations = new RecommendationsApi(publicConfiguration);
  const suppliers = new SuppliersApi(publicConfiguration);
  const tariffs = new TariffsApi(publicConfiguration);
  const tenantPower = new TenantPowerApi(publicConfiguration);
  const tokenVerification = new TokenVerificationApi(publicConfiguration);
  const publicTransfers = new PublicTransfersApi(publicConfiguration);
  const newsletter = new NewsletterApi(publicConfiguration);
  const codes = new CodesApi(publicConfiguration);

  // Admin APIs
  const adminContracts = new AdminContractsApi(
    adminConfiguration,
    undefined,
    adminAxiosApiInstance,
  );
  const adminCustomers = new AdminCustomersApi(
    adminConfiguration,
    undefined,
    adminAxiosApiInstance,
  );
  const adminDocuments = new AdminDocumentsApi(
    adminConfiguration,
    undefined,
    adminAxiosApiInstance,
  );
  const adminTariffs = new AdminTariffsApi(
    adminConfiguration,
    undefined,
    adminAxiosApiInstance,
  );
  const adminLabels = new AdminLabelsApi(
    adminConfiguration,
    undefined,
    adminAxiosApiInstance,
  );
  const adminTokenVerification = new AdminTokenVerificationApi(
    adminConfiguration,
    undefined,
    adminAxiosApiInstance,
  );
  const adminContractErrors = new AdminContractErrorsApi(
    adminConfiguration,
    undefined,
    adminAxiosApiInstance,
  );

  const adminTenantPower = new AdminTenantPowerApi(
    adminConfiguration,
    undefined,
    adminAxiosApiInstance,
  );

  const adminMeasuringPointsApi = new AdminMeasuringPointsApi(
    adminConfiguration,
    undefined,
    adminAxiosApiInstance,
  );

  const brainInstance = {
    customer: {
      contracts,
      transfers,
      customer,
      documents,
    },
    public: {
      messages,
      orders,
      publicContact,
      publicCustomer,
      recommendations,
      suppliers,
      tariffs,
      tenantPower,
      tokenVerification,
      publicTransfers,
      newsletter,
      codes,
    },
    admin: {
      contracts: adminContracts,
      customers: adminCustomers,
      documents: adminDocuments,
      tariffs: adminTariffs,
      labels: adminLabels,
      tokenVerification: adminTokenVerification,
      contractErrors: adminContractErrors,
      tenantPower: adminTenantPower,
      measuringPoints: adminMeasuringPointsApi,
    },
  };

  return {
    provide: {
      brain: brainInstance,
    },
  };
});
