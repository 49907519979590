import { HTTPErrorHandlerBase, type BaseErrorPayload } from "./httpErrorHandlerBase";
import { Problem, ResourceNotFoundProblem } from "./httpProblemType";

/** Class handling 404 error response. */
export class ResourceNotFoundHandler extends HTTPErrorHandlerBase<ResourceNotFoundProblem> {
  public static readonly resourceNotFoundErrors: {
    [key in ResourceNotFoundProblem]?: string;
  } = {
    [ResourceNotFoundProblem.document]:
      "Das Dokument konnte nicht heruntergeladen werden. Bitte versuche es später erneut.",
    [ResourceNotFoundProblem.customer]:
      "Der Kunde konnte nicht gefunden werden. Bitte wende dich an den Kundenservice.",
    [ResourceNotFoundProblem.contract]:
      "Der Vertrag konnte nicht gefunden werden. Bitte wende dich an den Kundenservice.",
    [ResourceNotFoundProblem.labels]:
      "Bei dem Laden der Labels ist ein Fehler aufgetreten.",
    [ResourceNotFoundProblem.measuringPoint]:
      "Bei dem Laden der Messstelle ist ein Fehler aufgetreten.",
    [ResourceNotFoundProblem.tariff]:
      "Bei dem Laden des Tarifs ist ein Fehler aufgetreten.",
    [ResourceNotFoundProblem.partialContract]:
      "Bei dem Laden der Mieterstromdaten für die angegebene Zählernummer ist ein Fehler aufgetreten.",
    [ResourceNotFoundProblem.meter]:
      "Der Zähler konnte nicht gefunden werden. Bitte wende dich an das DEV-Team.",
    // This list is not complete. Add more cases from backend if needed.
  };

  public constructor(public override readonly payload: BaseErrorPayload) {
    super(payload, ResourceNotFoundHandler.resourceNotFoundErrors);
  }

  // All 404 errors are sent with generic type /errors/resource_not_found/
  public mapError() {
    const details = this.details?.toLocaleLowerCase();

    if (this.type === "/errors/measuring_point_not_found") {
      return {
        [ResourceNotFoundProblem.measuringPoint]:
          this.messages[ResourceNotFoundProblem.measuringPoint],
      };
    }

    if (["document", "contract", "not exist"].every((word) => details?.includes(word)))
      return {
        [ResourceNotFoundProblem.document]:
          this.messages[ResourceNotFoundProblem.document],
      };
    else if (["contract", "not exist"].every((word) => details?.includes(word)))
      return {
        [ResourceNotFoundProblem.contract]:
          this.messages[ResourceNotFoundProblem.contract],
      };
    else if (["customer", "not exist"].every((word) => details?.includes(word)))
      return {
        [ResourceNotFoundProblem.customer]:
          this.messages[ResourceNotFoundProblem.customer],
      };
    else if (["labels", "not", "updated"].every((word) => details?.includes(word)))
      return {
        [ResourceNotFoundProblem.labels]: this.messages[ResourceNotFoundProblem.labels],
      };
    else if (["Measuring point", "not", "found"].every((word) => details?.includes(word)))
      return {
        [ResourceNotFoundProblem.measuringPoint]:
          this.messages[ResourceNotFoundProblem.measuringPoint],
      };
    else if (["tariff", "not", "exist"].every((word) => details?.includes(word)))
      return {
        [ResourceNotFoundProblem.measuringPoint]:
          this.messages[ResourceNotFoundProblem.measuringPoint],
      };
    else if (["partial contract", "found"].every((word) => details?.includes(word)))
      return {
        [ResourceNotFoundProblem.partialContract]:
          this.messages[ResourceNotFoundProblem.partialContract],
      };
    else if (["meter", "not", "found"].every((word) => details?.includes(word)))
      return {
        [ResourceNotFoundProblem.meter]: this.messages[ResourceNotFoundProblem.meter],
      };

    return HTTPErrorHandlerBase.mapGeneralErrors(Problem.general);
  }
}

export default ResourceNotFoundHandler;
