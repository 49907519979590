import type { DefaultConfigOptions } from "@formkit/vue";
import { de } from "@formkit/i18n";
import { createFloatingLabelsPlugin } from "@formkit/addons";
import { search } from "@formkit/icons";
import { createProPlugin, inputs } from "@formkit/pro";
import { convertEmptyStringToUndefinedPlugin } from "./utils/formkit";
import config from "~/config";

const formkitConfig: DefaultConfigOptions = {
  plugins: [
    createFloatingLabelsPlugin({ useAsDefault: true }),
    createProPlugin(config.formkit.proId, inputs),
    convertEmptyStringToUndefinedPlugin,
  ],
  locales: { de },
  locale: "de",
  messages: {
    de: {
      ui: {
        incomplete: "Es wurden nicht alle Felder korrekt ausgefüllt.",
        summaryHeader: "Es wurden nicht alle Felder korrekt ausgefüllt.",
      },
    },
  },
  icons: { search },
};

export default formkitConfig;
