import type { UseHeadInput } from "@unhead/vue";
import addTrailingSlash from "./addTrailingSlash";
import type { PageMetaData, PageSettings } from "~/types/shared/page-info";
import { useNuxtApp, useRuntimeConfig } from "#imports";

export default function (
  pageMetaData: PageMetaData,
  pageSettings: PageSettings,
  pageSlug?: string,
): UseHeadInput {
  const baseUrl = useRuntimeConfig().public.baseUrl;

  const pageUrl = pageSlug ? `${baseUrl}${addTrailingSlash(pageSlug)}` : undefined;

  // Make sure to add proper slashes for canonical URLs
  const canonicalStartsWithSlash = pageSettings.canonicalUrl?.current.startsWith("/");
  const canonicalUrl = pageSettings.canonicalUrl?.current
    ? `${baseUrl}${!canonicalStartsWithSlash ? "/" : ""}${addTrailingSlash(
        pageSettings.canonicalUrl.current,
      )}`
    : undefined;

  const lang = (pageSettings.language || "de").toLowerCase();
  const description =
    pageMetaData.pageDescription ||
    "Strom & Gas aus nachhaltiger Erzeugung. Für die weltweite Energiewende. Zusammen verändern wir mit Energie die Welt. Wirklich.";

  const { $cmsImageUrl } = useNuxtApp();

  const socialImage = pageMetaData.socialImage?.asset
    ? $cmsImageUrl(pageMetaData.socialImage.asset, { original: true })
    : `${baseUrl}/fileadmin/files/team/teambilder/Polarstern_TeamWirklich_TeamBild_1920x1200_221110.jpg`;

  const title = pageMetaData.pageTitle || "Polarstern: 100 % saubere Energie. Wirklich.";

  return {
    htmlAttrs: {
      lang,
    },
    meta: [
      { name: "robots", content: pageSettings.noIndex ? "noindex" : "all" },
      { name: "description", content: description },
      { property: "og:title", content: title },
      { property: "og:description", content: description },
      { property: "og:image", content: socialImage },
      { property: "og:type", content: "article" },
      pageUrl || canonicalUrl
        ? {
            property: "og:url",
            content: canonicalUrl ?? pageUrl,
          }
        : {},
      {
        property: "og:site_name",
        content: "Polarstern - 100% Ökostrom und 100% Ökogas",
      },
    ],
    title,
    link: [
      canonicalUrl
        ? {
            rel: "canonical",
            href: canonicalUrl,
          }
        : {},
    ],
  };
}
