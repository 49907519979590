import { createGtm } from "@gtm-support/vue-gtm";
import { useGetCurrentCustomerProperties } from "~/composables/useCustomerTracking";
import config from "~/config";

let initialPage = true;

const getRouteType = () => {
  const routeType = initialPage ? "initial" : "pageChange";
  initialPage = false;

  return routeType;
};

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(
    createGtm({
      id: config.googleAnalytics.gtmId,
      defer: false,
      enabled:
        window.localStorage.getItem(config.googleAnalytics.disableGaLocalStorageKey) !==
        "true",
      debug: false,
      loadScript: false,
      vueRouter: useRouter(), // emits a content-view event on route change
      vueRouterAdditionalEventData: () => ({
        spa_type: getRouteType(),
        ...useGetCurrentCustomerProperties(),
      }),
      trackOnNextTick: false,
    }),
  );
});
