export const calculateOwnPowerConsumption = (consumption: number): number => {
  const ownPowerShare = 0.7;
  return (consumption / ownPowerShare) * (1 - ownPowerShare);
};

export const calculateOwnPowerBonusForConsumption = (
  ownPowerConsumption: number,
): number => {
  const bonusInCents = 25;

  return ownPowerConsumption * (bonusInCents / 1000);
};

export const calculateOwnPowerBonus = (consumption: number): number => {
  return calculateOwnPowerBonusForConsumption(calculateOwnPowerConsumption(consumption));
};
