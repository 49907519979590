import config from "~/config";

export default function (event: Event & { currentTarget: Element }) {
  const { width } = useWindowSize();

  if (width.value > config.breakpoints.sm) return;

  const hasActiveClass = event.currentTarget.classList.contains("active-card");

  // Close all other active cards within the same component first
  if (event.currentTarget.parentNode?.parentNode?.children) {
    for (const card of event.currentTarget.parentNode?.parentNode?.children) {
      card.children[0].classList.remove("active-card");
    }
  }
  if (hasActiveClass) {
    event.currentTarget.classList.remove("active-card");
  } else {
    event.currentTarget.classList.add("active-card");
  }
}
