<template v-if="active">
  <TheLinkButton
    v-if="
      active &&
      linkTarget &&
      linkTarget.cmsType === 'linkTargetInternal' &&
      linkTarget.pageReference === null
    "
    :appearance="appearance"
    :color="color"
    :chevron="chevron"
    :position="position"
    :to="linkTarget.anchor ? `#${linkTarget.anchor}` : ''"
    class="psc-callToAction"
    :type="type"
  >
    <slot></slot>
    {{ label }}
  </TheLinkButton>
  <TheButton
    v-else-if="active && linkTarget && linkTarget.cmsType === 'clientAction'"
    :appearance="appearance"
    :color="color"
    :chevron="chevron"
    :position="position"
    class="psc-callToAction"
    :type="type"
    @click="triggerAction(linkTarget.action)"
  >
    <slot></slot>
    {{ label }}
  </TheButton>

  <TheLinkButton
    v-else-if="active && linkTarget"
    :appearance="appearance"
    :color="color"
    :chevron="chevron"
    :position="position"
    :to="resolveLinkTarget(linkTarget)"
    :target="externalTarget"
    :download="isDownload"
    class="psc-callToAction"
    :type="type"
  >
    <slot></slot>
    {{ label }}
  </TheLinkButton>
</template>

<script setup lang="ts">
import config from "~/config";
import type { CallToAction } from "~/types/cms/call-to-action";
import TheLinkButton from "~/components/01_atoms/TheLinkButton.vue";
import TheButton from "~/components/01_atoms/TheButton.vue";

import type {
  ButtonAppearance,
  ButtonChevron,
  ButtonColor,
  ButtonPosition,
} from "~/types/shared/button-like";

const lookAppearanceMapping: Record<string, ButtonAppearance> = {
  filled: "filled",
  outline: "outline",
  chevron: "inline",
  inline: "inline",
};

const lookColorMapping: Record<string, ButtonColor> = {
  blue: "blue",
  neutral: "neutral",
  teal: "teal",
  red: "red",
  black: "black",
  white: "white",
};

const props = defineProps<CallToAction>();

// We can get invalid CTA buttons non-"active" CTA butons are used in PortableText
// Although this is checked in the template, we have to be defensive here and manually
// check for props.active

const appearance = props.active ? lookAppearanceMapping[props.look] : undefined;
const color = props.active ? lookColorMapping[props.color] : undefined;
const type = props.type;
const externalTarget =
  props.active && props.linkTarget.cmsType === "linkTargetExternal"
    ? props.linkTarget.target || "_blank"
    : undefined;
const isDownload = props.active && props.linkTarget.cmsType === "download";
const position: ButtonPosition = props.fullWidth ? "full-width" : "block";
const chevron: ButtonChevron | undefined = props.look === "chevron" ? "right" : undefined;

const triggerAction = (action: string) => {
  if (action === config.interactions.openChat) openChat();
  if (action === config.interactions.openCookieBox) openCookieConsent();
};
</script>
