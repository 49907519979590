import initializeConsentEventListener from "~/utils/cookieConsentChange";

export function openCookieConsent() {
  if (process.client) {
    Cookiebot.renew?.();
    initializeConsentEventListener();
  }
}

/**
 * If e.g. an ad blocker is active, Cookiebot might not be available.
 * This function checks if Cookiebot is active.
 *
 * @returns {boolean} - True if Cookiebot is active, false otherwise.
 */
export const isCookieBotActive = () => {
  if (process.server) return false;
  return Boolean(Cookiebot?.loaded);
};
