import { isPast, isToday } from "date-fns";
import type { Contract, TariffHistoryInner } from "~/src/generated-sources/core";

/*
 * The current tariff history entry of a contract is the most recent entry with a validFrom date
 * in the past or today. Tariff history entries are sorted by validFrom date in ascending order.
 * @params contractOrTariffHistory - a contract object or an array of tariff history entries
 */
export default function getCurrentTariffHistoryEntry(
  contractOrTariffHistory: Pick<Contract, "tariffHistory"> | TariffHistoryInner[],
): TariffHistoryInner {
  const tariffHistory =
    contractOrTariffHistory instanceof Array
      ? contractOrTariffHistory
      : contractOrTariffHistory.tariffHistory;
  return (
    // latest entry valid now (validFrom is today or past or not existent)
    tariffHistory
      .slice()
      .reverse()
      .find(({ validFrom }) => {
        const validFromDate = validFrom && new Date(validFrom);
        return !validFromDate || isToday(validFromDate) || isPast(validFromDate);
      }) ??
    // because entries are sorted this is the entry with the earliest validFrom in the future (because it is not valid now)
    tariffHistory![0]
  );
}
