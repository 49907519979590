<template>
  <FormKit
    :id="id"
    :name="name"
    :label="label"
    :validation="
      (required ? 'required|' : '') + 'length:3,35|preventSpecialCharactersRule'
    "
    :validation-rules="{ preventSpecialCharactersRule }"
    :validation-messages="{
      preventSpecialCharactersRule: preventSpecialCharactersRuleMessage,
    }"
    :disabled="disabled"
    :help="help"
    :outer-class="outerClass"
    @input="(value: string | undefined) => emits('input', value || '')"
    @paste="(e: ClipboardEvent) => emits('paste', e)"
  />
</template>

<script lang="ts" setup>
import getMeterSerialNumberValidation from "~/utils/getMeterSerialNumberValidation";

withDefaults(
  defineProps<{
    label?: string;
    id?: string;
    name?: string;
    disabled?: boolean;
    help?: string;
    required?: boolean;
    outerClass?: string;
  }>(),
  {
    label: "Zählernummer*",
    id: "meter.serialNumber",
    name: "meterNumber",
    disabled: false,
    required: true,
    help: undefined,
    outerClass: undefined,
  },
);

const emits = defineEmits<{
  (e: "input", value: string): void;
  (e: "paste", value: ClipboardEvent): void;
}>();

const { preventSpecialCharactersRule, preventSpecialCharactersRuleMessage } =
  getMeterSerialNumberValidation();
</script>
