export const recommendationSetup = {
  power: {
    years: 2.5,
    consumption: 2000,
    contracts: 0.92,
  },
  gas: {
    years: 1.5,
    consumption: 10300,
    contracts: 0.29,
  },
};

export const gasRecommendationConsumption =
  recommendationSetup.gas.consumption *
  recommendationSetup.gas.contracts *
  recommendationSetup.gas.years;

export const powerRecommendationConsumption =
  recommendationSetup.power.consumption *
  recommendationSetup.power.contracts *
  recommendationSetup.power.years;

export const calculateTotalContractDurationOfRecommendations = (count: number) => {
  return count === 0
    ? 0
    : count *
        (recommendationSetup.power.years * recommendationSetup.power.contracts +
          recommendationSetup.gas.years * recommendationSetup.gas.contracts);
};
