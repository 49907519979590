import config from "../config";
import addTrailingSlash from "./addTrailingSlash";
import type { LinkablePageType } from "~/config";

/**
 * Constructs the proper URL for the given page type and list of placeholders.
 */
const routeTo = (pageType: LinkablePageType, ...replacements: string[]): string => {
  const routeFragments = config.routes[pageType].split("%s");

  // Fix some weird cases, where the CMS link resolution logic returns links that are the
  // same as the LinkablePageType's route. In this case we can just return the route.
  // Hacky workaround to avoid breaking other links.
  // TODO: Research on why this happens on e.g. links to the faq overview page and fix the root cause.
  if (
    replacements.length === 1 &&
    routeFragments.length === 1 &&
    config.routes[pageType] === "/" + replacements[0] + "/"
  ) {
    return config.routes[pageType];
  }

  if (routeFragments.length - 1 !== replacements.length) {
    throw new Error(
      `Route argument error: Expected ${routeFragments.length - 1}, got ${
        replacements.length
      } (${replacements.toString()})`,
    );
  }

  const route = routeFragments
    .map((fragment, index) => `${fragment}${replacements[index] || ""}`)
    .join("");

  return addTrailingSlash(route);
};

export default routeTo;
