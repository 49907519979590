import type { AccountInfo } from "@azure/msal-browser";
import { defineStore } from "pinia";
import {
  mapAccountInfoToLoggedInCustomer,
  type LoggedInCustomer,
  type LoggedOutCustomer,
} from "~/utils/customer-area/clientAuthentication";

export const useAuthStore = defineStore("auth-store", () => {
  const customer: Ref<LoggedInCustomer | LoggedOutCustomer> = ref({ loggedIn: false });

  const setCustomer = (user: AccountInfo) => {
    customer.value = mapAccountInfoToLoggedInCustomer(user);
  };

  return {
    setCustomer,
    customer,
  };
});
