import type { Contract, TariffHistoryInner } from "~/src/generated-sources/core";
import getCurrentTariffHistoryEntry from "~/utils/customer-area/getContractCurrentTariffHistoryEntry";

/*
 * The current tariff ID of a contract is the tariff ID of the most recent tariff history entry with a validFrom date
 * in the past or today. Tariff history entries are sorted by validFrom date in ascending order.
 * @params contractOrTariffHistory - a contract object or an array of tariff history entries
 */
export default function getCurrentTariffId(
  contractOrTariffHistory: Pick<Contract, "tariffHistory"> | TariffHistoryInner[],
) {
  return getCurrentTariffHistoryEntry(contractOrTariffHistory).tariffId;
}
