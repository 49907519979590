/** This function constructs the anchor for an FAQ item
 *  so the website can scroll to it.
 */
export default (id: string, includeHash: boolean = true) => {
  const prefix = "faq-"; // we need to prefix the id, otherwise document.querySelector will throw an error

  if (includeHash) {
    return "#" + prefix + id;
  }
  return prefix + id;
};
