import imageUrlBuilder from "@sanity/image-url";
import type { SanityAsset, SanityImageObject } from "@sanity/image-url/lib/types/types";
import config from "~/config";

export default defineNuxtPlugin((nuxtApp) => {
  const { projectId, dataset } = useSanity().config;
  const assetMirrorConfig = nuxtApp.$config.public.assetMirror;

  if (!projectId) {
    throw new Error("No Sanity projectId available");
  }

  // Set image builder defaults
  const builder = imageUrlBuilder({
    projectId,
    dataset: dataset || "production",
    // see https://github.com/sanity-io/image-url/issues/55
    // @ts-ignore:next-line
    baseUrl: assetMirrorConfig.sanityCdnHost,
  });

  /**
   * Returns URL of image or image variant
   *
   * Will return Asset Mirror URL or Sanity URL depending on
   * runtime config's `assetMirror.enabled`
   *
   * Be sure to pass in asset object that include
   * .url and .originalFilename, no ._ref objects
   *
   * WEBP format is the default, except for GIFs
   * If original ist not explicitly requested,
   * return best variant in webp format

   * @param source Asset source object (not ref!)
   * @param options
   * @returns URL of asset
   */
  const cmsImageUrl = (
    source: SanityAsset,
    options?: { width?: number; original?: boolean },
  ) => {
    return assetMirrorConfig.enabled
      ? assetMirrorImageUrl(source, options || {})
      : sanityImageUrl(source, options || {});
  };

  /**
   * Generate image URLs (Sanity)
   *
   * @param source Sanity source object (including .url property)
   * @returns URL of asset
   */
  const sanityImageUrl = (
    source: SanityAsset | SanityImageObject,
    { width, original }: { width?: number; original?: boolean },
  ) => {
    const imageBuilder = builder.image(source);
    if (original || imageBuilder.url().endsWith(".gif")) {
      return imageBuilder.url();
    }

    return imageBuilder
      .format("webp")
      .width(width || config.imageVariants.maxHighRes)
      .fit("max")
      .url();
  };

  /**
   * Generate image URLs (Asset Mirror)
   *
   * @param source Asset source object (including .originalFilename property)
   * @returns URL of asset
   */
  const assetMirrorImageUrl = (
    source: SanityAsset,
    { width, original }: { width?: number; original?: boolean },
  ) => {
    if (!source.originalFilename) {
      console.error(
        "unable to continue since originalFilename is missing (forgot to include image in groq query?)",
        source,
      );
      return "";
    }
    const filename = source.originalFilename;
    const encodedFilename = encodeURI(filename);
    const imageFormat = "webp";
    const suffix =
      original || filename.endsWith(".gif")
        ? ""
        : `.w-${width || config.imageVariants.maxHighRes}.${imageFormat}`;

    return `${assetMirrorConfig.assetHost}${assetMirrorConfig.mediaFolder}/${encodedFilename}${suffix}`;
  };

  return {
    provide: {
      cmsImageUrl,
      sanityImageUrl,
    },
  };
});
