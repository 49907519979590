<template>
  <BlockTemplate
    :info-text="text"
    :link-subject="linkSubject"
    :link-body="linkBody"
    inside-classes="background-light-teal"
  />
</template>

<script lang="ts" setup>
import BlockTemplate from "./BlockTemplate.vue";

const text =
  "Durch ein IT-Update klappt's gerade nicht, Tarife zu berechnen. Schau später einfach vorbei oder schreib uns ne kurze Mail, wenn wir dich erinnern sollen.";

const linkSubject = "Update Tarifrechner";
const linkBody =
  "Liebes Polarstern-Team,\nbitte informiert mich, wenn ich wieder den Tarifrechner bei euch nutzen kann.\nVielen lieben Dank";
</script>
