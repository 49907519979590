import { defineStore } from "pinia";
import type {
  ContractAsAdmin,
  CustomerWithLabels,
  Label,
  MeterReading,
  PublicTariffWithSummarizedPriceRanges as PublicTariff,
  Recommendation,
} from "~/src/generated-sources/admin";
import getCurrentTariffId from "~/utils/customer-area/getContractCurrentTariff";
import { useOrderStore } from "~/stores/order";

/**
 * The store that holds the user as which the admin wants to view data and perform actions for.
 * The admin can then view the whole customer area as if she was the impersonificated customer.
 */
export const useAdminImpersonificationStore = defineStore(
  "admin-impersonification-store",
  () => {
    const orderStore = useOrderStore();

    /**
     * The customer for which the admin is currently acting upon.
     */
    const customer = ref<CustomerWithLabels | null>(null);
    const contracts = ref<ContractAsAdmin[] | null>(null);
    const tariffs = ref<PublicTariff[] | null>(null);
    const labels = ref<Label[] | null>(null);
    const recommendations = ref<Recommendation[] | null>(null);
    const isFullWidth = ref(false);
    /**
     * Whether the admin features are enabled, useful to emulate user view of pages within admin tool.
     */
    const isAdminFeaturesEnabled = ref(true);

    const stopImpersonification = () => {
      customer.value = null;
      contracts.value = null;
      tariffs.value = null;
      labels.value = null;
      recommendations.value = null;
      orderStore.reset();
    };

    const findContractById = (contractId: string) => {
      if (contracts.value) {
        return contracts.value.find(({ id }) => contractId === id);
      }
    };

    const updateContract = (contract: ContractAsAdmin) => {
      if (contracts.value) {
        const index = contracts.value.findIndex((c) => c.id === contract.id);
        if (index !== -1) {
          contracts.value[index] = contract;
        } else {
          contracts.value.push(contract);
        }
      }
    };

    const pushMeterReadingToContract = (
      contractId: string,
      meterReading: MeterReading,
    ) => {
      if (contracts.value) {
        const contract = contracts.value.find((c) => c.id === contractId);
        if (contract) {
          if (contract.meterReadings) {
            contract.meterReadings.push(meterReading);
          } else {
            contract.meterReadings = [meterReading];
          }
        }
      }
    };

    const addTariffs = async (tariffIds: string[]) => {
      if (tariffs.value === null) {
        tariffs.value = [];
      }

      if (tariffIds.length === 0 || !tariffIds.some(Boolean)) {
        return;
      }

      const retrievedTariffs = await getTariffsById(tariffIds.filter(Boolean));

      for (const tariff of retrievedTariffs) {
        if (!tariffs.value.includes(tariff)) {
          tariffs.value.push(tariff);
        }
      }
    };

    watch(
      () => contracts.value,
      async (cs) => {
        if (cs) {
          // Get tariffs for contracts, once they are updated
          const tariffIds = cs.map(getCurrentTariffId);
          await addTariffs(tariffIds);
        }
      },
    );

    return {
      /**
       * The customer for which the admin is currently acting upon.
       */
      customer,
      /**
       * The contracts of the customer for which the admin is currently acting upon.
       */
      contracts,
      /**
       * The tariffs for the customer. Get automatically fetched once the contracts are set.
       */
      tariffs,
      /**
       * Update a single contract in the store or add it if not existing yet.
       */
      recommendations,
      isFullWidth,
      isAdminFeaturesEnabled,
      labels,
      updateContract,
      pushMeterReadingToContract,
      stopImpersonification,
      findContractById,
      addTariffs,
    };
  },
);
