<!-- TODO: Social Media Boxen -->
<template>
  <BaseForm
    :form-id="formId"
    :on-submit="submitHandler"
    :success-message-texts="newsletterSuccessMessage"
    submit-button-label="News von Polarstern erhalten"
    submit-button-color="teal"
  >
    <div class="text-color-red">
      <TheHeadline type="h2" text="Newsletter abonnieren." />
    </div>
    <div class="form-main-content">
      <div class="input--wrapper">
        <FormKit
          name="emailAddress"
          label="E-Mail-Adresse*"
          validation="required|email"
        />
      </div>
    </div>
    <NewsletterConfirm
      text="Hiermit bestätige ich, dass mich Polarstern über die oben eingegebene E-Mail-Adresse mit Infos rund um Polarstern versorgen darf: Energie-News, Strom- und Gasspartipps, Hinweise auf Events und vieles mehr. Der Newsletter kommt ca. 1 x im Monat und kann jederzeit wieder abbestellt werden, eh klar."
    />
  </BaseForm>
</template>

<script setup lang="ts">
import { EmailAddressSubscriptionsEnum } from "~/src/generated-sources/public";
import newsletterSuccessMessage from "~/components/03_organisms/forms/newsletter/newsletterSuccessMessage";
import NewsletterConfirm from "~/components/02_molecules/forms/NewsletterConfirm.vue";
import TheHeadline from "~/components/01_atoms/TheHeadline.vue";
import BaseForm from "~/components/03_organisms/forms/contact/BaseForm.vue";

defineProps<{ formId: string }>();
const snackbar = useSnackbar();

const submitHandler = (fields: any) =>
  useSubscribeToNewsletter(
    EmailAddressSubscriptionsEnum.InterestedNewsletter,
    fields,
    snackbar,
  );
</script>

<style lang="scss">
.form-main-content {
  display: flex;
  justify-content: space-between;
}

.input--wrapper {
  width: 100%;
}

.button--wrapper {
  display: flex;
  justify-content: center;
}

@container (max-width: #{$screen-md}) {
  .button--wrapper {
    margin-top: 2rem;

    button {
      width: 75%;
    }
  }
}
</style>
