import type { ContractType } from "./contract-type";
import type {
  PublicTariffRuntimeTypeEnum,
  PublicTariffSubTypeEnum,
  PublicTariffWithSummarizedPriceRanges as PublicTariff,
  TariffHistoryInner,
  TariffType,
} from "~/src/generated-sources/public";
import type { Tariff as AdminTariff } from "~/src/generated-sources/admin";

/** Defined as 'tariffProduct' in the CMS. */
export interface CalculatorTariffType {
  runTimeType: PublicTariffRuntimeTypeEnum;
  tariffType: TariffType;
  tariffSubType: PublicTariffSubTypeEnum;
  uniqueSellingPoints: string[];
  description: string;
  /** A tariff switch is a reference to another tariff, that can be toggled during the checkout process. */
  tariffSwitch?: {
    showAsCheckbox: boolean;
    tariff: CalculatorTariffType;
  };
}

export interface CalculatorTab {
  title: string;
  icon: string;
  tariffs: CalculatorTariffType[];
  isAdminOnly?: boolean;
}

export interface Calculator {
  businessCustomerPreSelected?: boolean;
  canSwitchBusinessCustomer?: boolean;
  partnerTariffId?: string;
  tabs: CalculatorTab[];
}

export interface NewContract {
  // TODO: Refactor all code to have a number here
  annualConsumptionValueHigh: string;
  annualConsumptionValueLow?: string;
  tariff: PublicTariff | AdminTariff;
  tariffHistory: Array<TariffHistoryInner>;
  tariffName: string;
  tariffSubType: PublicTariffSubTypeEnum;
  type: ContractType;
}

export interface CompletedContract extends NewContract {
  id: string;
}

// TODO: Improve naming
// Needs a little mapping but saves type conflicts with the generated API client
export interface NewlyCreatedCustomer {
  firstName: string;
  email: string;
  universalCode: string;
}

export interface NewlyCreatedContracts {
  contracts: {
    contractId: string;
    tariffTitle: string;
  }[];
  deliveryAddressAsString: string;
}

export enum TriggerId {
  None = "none",
  Thg = "1",
  CarPowerOrCarPowerPlus = "2",
  PrivateHeatPump = "3",
  PrivatePowerClassic = "4",
  PrivateGas = "5",
  PrivateDualBooking = "6",
  CompanyHeatPump = "7",
  CompanyPowerClassic = "8",
  CompanyGas = "9",
  CompanyDualBooking = "10",
  GewerbeSolar = "11",
  OwnPower = "14",
  GasFlex = "15",
}

export type BookingType =
  | "erstvertrag"
  | "zubuchung"
  | "umzug"
  | "uebertragung"
  | "tarifwechsel"
  | "tariferneuerung";

export type BookingOrderReason =
  | "umzug"
  | "anbieterwechsel"
  | "neueinzug"
  | "unbekannt"
  | "tarifwechsel"
  | "tariferneuerung";

export interface TrackConsumption {
  powerHigh?: number;
  powerLow?: number | null;
  gas?: number;
}

export type ProductGroupValue =
  | "classicPower"
  | "classicGas"
  | "classicPower+classicGas"
  | "heatPump"
  | "nightStorageHeating"
  | "double"
  | "carPower"
  | "carPowerPlus"
  | "own"
  | "tenantPower"
  | "tenantPowerShadow"
  | "unknown";

export const simplePowerProductGroups: ProductGroupValue[] = [
  "classicPower",
  "classicPower+classicGas",
  "carPower",
  "own",
  "tenantPower",
];

export const gasProductGroups: ProductGroupValue[] = [
  "classicGas",
  "classicPower+classicGas",
];

/**
 * A ProductGroup is some kind of grouping of tariffs. It could e.g.
 * be "Ökostrom + Ökogas".
 * One ProductGroup will be displayed as one radio within a tab in the calculator.
 * The tariffs that are allowed to be displayed in the ProductGroup are
 * listed in the allowedCalculatorTariffTypes property.
 */
export interface ProductGroup {
  label: string;
  value: ProductGroupValue;
  /**
   * The tariff types that are allowed for this product group.
   * Used for filtering the tariffs.
   */
  allowedCalculatorTariffTypes: CalculatorTariffType[];
}
