import type { PublicTariffWithSummarizedPriceRanges as PublicTariff } from "~/src/generated-sources/public/api";
import type { CalculatorTariffType } from "~/types/shared/calculator";

export default (tariffs: PublicTariff[], tariffTypes: CalculatorTariffType[]) => {
  const sortOrder = tariffTypes.map((t) => ({
    tariffType: t.tariffType,
    subType: t.tariffSubType,
    runtimeType: t.runTimeType,
  }));

  return tariffs.sort((a, b) => {
    const getIndex = (tariff: PublicTariff) => {
      return sortOrder.findIndex((t) => {
        return (
          t.tariffType === tariff.type &&
          t.subType === tariff.subType &&
          t.runtimeType === tariff.runtimeType
        );
      });
    };

    return getIndex(a) - getIndex(b);
  });
};
