export const useCookiesStore = defineStore(
  "CookiesStore",
  () => {
    const functionalCookiesApproved = ref(false);
    const marketingCookiesApproved = ref(false);
    const statisticCookiesApproved = ref(false);
    const preferenceCookiesApproved = ref(false);

    const setFunctionalCookiesApproved = (isFunctionalCookiesApproved: boolean) => {
      functionalCookiesApproved.value = isFunctionalCookiesApproved;
    };

    const setMarketingCookiesApproved = (isMarketingCookiesApproved: boolean) => {
      marketingCookiesApproved.value = isMarketingCookiesApproved;
    };

    const setStatisticCookiesApproved = (isStatisticCookiesApproved: boolean) => {
      statisticCookiesApproved.value = isStatisticCookiesApproved;
    };

    const setPreferenceCookiesApproved = (arePreferenceCookiesApproved: boolean) => {
      preferenceCookiesApproved.value = arePreferenceCookiesApproved;
    };

    return {
      setFunctionalCookiesApproved,
      setMarketingCookiesApproved,
      setPreferenceCookiesApproved,
      setStatisticCookiesApproved,

      marketingCookiesApproved,
      statisticCookiesApproved,
      preferenceCookiesApproved,
    };
  },
  {
    persist: { storage: persistedState.localStorage },
  },
);
