import calculateContractRunTimeInYears from "./calculateContractRunTimeInYears";
import type { Contract } from "~/src/generated-sources/public";
import type { AlternativeImpactContract } from "~/types/shared/impact-calculator";

export default (contracts: (Contract | AlternativeImpactContract)[]) => {
  return contracts
    .map((contract) => {
      return contract.annualConsumption.value * calculateContractRunTimeInYears(contract);
    })
    .reduce((acc, consumption) => acc + consumption, 0);
};
