export default function (mail: string, subject?: string, body?: string) {
  const params = new URLSearchParams({});
  if (subject) params.append("subject", subject);
  if (body) params.append("body", body);

  // A little bit of magic to make it work with mailto links:
  // Decode special characters and replace new lines with %0D%0A
  return `mailto:${mail}${params.toString() && "?"}${decodeURIComponent(
    params.toString().replace(/\+/g, " "),
  ).replace(/(?:\r\n|\r|\n)/g, "%0D%0A")}`;
}
